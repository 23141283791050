import Link from "next/link";
import { isValidElement, ReactNode, useEffect, useState } from "react";
import { Logomark } from "@/assets/vectors/logo";
import { cva, cx, VariantProps } from "class-variance-authority";
import { ArrowLeftUnfilled, CloseUnfilled } from "@/components/Icons";

const headerVariants = cva(
    "sticky top-0 z-10 grid min-h-[80px] content-center items-start justify-between gap-2 bg-white px-4 py-5 data-[scrolled='1']:shadow-md lg:hidden",
    {
        variants: {
            extrasSize: {
                md: "grid-cols-[20px_1fr_20px]",
                lg: "grid-cols-[36px_1fr_36px]",
            },
            align: { start: "items-start", center: "items-center" },
        },
        defaultVariants: { extrasSize: "md", align: "start" },
    },
);

type MobileHeaderProps = {
    className?: string;
    left?: ExtraProps | ReactNode;
    right?: ExtraProps | ReactNode;
    children?: ReactNode;
} & VariantProps<typeof headerVariants>;
export const MobileHeader = ({
    className,
    left,
    right,
    children,
    extrasSize,
    align,
}: MobileHeaderProps): JSX.Element => {
    const [scrolled, setScrolled] = useState(0);
    useEffect(() => {
        const hnd = (): void => setScrolled(window.scrollY > 0 ? 1 : 0);
        window.addEventListener("scroll", hnd);
        return () => {
            window.removeEventListener("scroll", hnd);
        };
    }, []);

    const leftChild = isExtraProps(left) ? (
        <Extra {...left} />
    ) : (
        (left ?? <div />)
    );
    const rightChild = isExtraProps(right) ? (
        <Extra {...right} />
    ) : (
        (right ?? <div />)
    );

    return (
        <header
            className={cx(headerVariants({ extrasSize, align }), className)}
            data-scrolled={scrolled}
        >
            {leftChild}
            {children}
            {rightChild}
        </header>
    );
};

const isExtraProps = (p: ExtraProps | ReactNode): p is ExtraProps =>
    !!p && !isValidElement(p);

type ExtraProps = {
    className?: string;
    icon?: "logo" | "back" | "close" | ReactNode;
    href?: string;
    onClick?: () => void;
};
const Extra = ({ className, icon, href, onClick }: ExtraProps): JSX.Element => {
    let child: ReactNode;
    switch (icon) {
        case "logo":
            child = (
                <Logomark
                    style={{ height: 36 }}
                    fill="linear-gradient(#000, #fff)"
                />
            );
            break;
        case "back":
            child = (
                <ArrowLeftUnfilled
                    className={cx("size-5 text-blue-grey-900", className)}
                />
            );
            break;
        case "close":
            child = (
                <span className="flex flex-col items-end">
                    <CloseUnfilled
                        className={cx(
                            "relative right-0 size-5 text-blue-grey-900",
                            className,
                        )}
                    />
                </span>
            );
            break;
        default:
            return <span className="pt-1">{icon}</span>;
    }

    if (href)
        return (
            <Link href={href}>
                <a className="flex items-center pt-1">{child}</a>
            </Link>
        );
    return (
        <span onClick={onClick} className="pt-1">
            {child}
        </span>
    );
};
