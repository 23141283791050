import { cx } from "class-variance-authority";
import type { SVGAttributes } from "react";

type SVGProps = SVGAttributes<SVGElement>;
export const ArrowRightUnfilled = (props: SVGProps): JSX.Element => (
    <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M11.5556 4.55554L17 9.99999M17 9.99999L11.5556 15.4444M17 9.99999L3 9.99999"
            stroke="currentColor"
            strokeWidth="1.67"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

export const ArrowLeftUnfilled = (props: SVGProps): JSX.Element => (
    <ArrowRightUnfilled
        {...props}
        className={cx("rotate-180", props.className)}
    />
);
export const ArrowUpUnfilled = (props: SVGProps): JSX.Element => (
    <ArrowRightUnfilled
        {...props}
        className={cx("-rotate-90", props.className)}
    />
);
export const ArrowDownUnfilled = (props: SVGProps): JSX.Element => (
    <ArrowRightUnfilled
        {...props}
        className={cx("rotate-90", props.className)}
    />
);
