import { ReactNode } from "react";
import { cx, cva, type VariantProps } from "class-variance-authority";

type BadgeVariantProps = VariantProps<typeof badgeVariants>;
const badgeVariants = cva(
    "typography-micro h-fit rounded border-0.5 border-solid border-transparent px-1 py-0.5 font-bold uppercase",
    {
        variants: {
            color: {
                dark: "bg-primary text-white",
                light: "bg-primary-50 text-blue-grey-900",
                red: "bg-destructive-600 text-white",
                success: "bg-success text-white",
                pink: "bg-pink text-white",
            },
        },
        defaultVariants: {
            color: "dark",
        },
    },
);
export type BadgeProps = {
    className?: string;
    children: ReactNode;
} & BadgeVariantProps;
export const Badge = ({
    color,
    className,
    children,
}: BadgeProps): JSX.Element => (
    <div className={cx(badgeVariants({ color }), className)}>{children}</div>
);

type State = BookingStatusBadgeVariantProps["status"];
export type BookingState = Exclude<State, null | undefined>;

type BookingStatusBadgeVariantProps = VariantProps<
    typeof bookingStatusBadgeVariants
>;
const bookingStatusBadgeVariants = cva(
    "typography-micro rounded border-0.5 border-solid px-1 py-0.5 font-bold uppercase",
    {
        variants: {
            status: {
                confirmed:
                    "border-success-400 bg-success-100 text-blue-grey-900",
                pending: "border-destructive-600 bg-destructive-600 text-white",
                paymentDue: "border-warning bg-warning-100 text-blue-grey-900",
                cancelled:
                    "border-blue-grey-400 bg-blue-grey-50 text-blue-grey-900",
            },
        },
        defaultVariants: { status: "confirmed" },
    },
);
type BookingStatusBadgeProps = {
    wrapperClassName?: string;
    className?: string;
    children: ReactNode;
} & BookingStatusBadgeVariantProps;
export const BookingStatusBadge = ({
    status,
    wrapperClassName,
    className,
    children,
}: BookingStatusBadgeProps): JSX.Element => {
    return (
        <div className={wrapperClassName}>
            <div
                className={cx(
                    bookingStatusBadgeVariants({ status }),
                    className,
                )}
            >
                {children}
            </div>
        </div>
    );
};
