import { Image } from "@/lib/imgproxy";
import { cva, cx, type VariantProps } from "class-variance-authority";
import { HTMLAttributes } from "react";

const avatarVariants = cva("relative rounded-full outline outline-white", {
    variants: {
        size: {
            "24.5": "size-[24.5px] text-micro",
            "32": "typography-tiny size-8",
            "40": "typography-tiny size-10",
            "96": "text-9 size-24",
            "148": "size-[148px] text-[52px]",
        },
        shadow: {
            default: "",
            lg: "shadow-lg",
        },
        outline: {
            default: "outline-2",
            thick: "outline-4",
        },
    },
    defaultVariants: {
        size: "96",
        shadow: "default",
        outline: "default",
    },
});

export interface AvatarProps
    extends HTMLAttributes<HTMLDivElement>,
        VariantProps<typeof avatarVariants> {
    src?: string | null;
    index?: number;
    text?: string | number;
    className?: string;
}

export const Avatar = ({
    src,
    index = 0,
    text,
    className,
    size,
    shadow,
    outline,
}: AvatarProps): JSX.Element => {
    let imageSize = "110px"; // default size 96
    if (size === "24.5") imageSize = "30px";
    else if (size === "32") imageSize = "40px";
    else if (size === "40") imageSize = "50px";
    else if (size === "148") imageSize = "160px";

    const child = src ? (
        <div
            className={cx(
                "bg-contain",
                avatarVariants({ size, outline }),
                className,
            )}
        >
            <Image
                src={src}
                layout="fill"
                className="rounded-full object-cover"
                alt="Profile picture"
                sizes={imageSize}
            />
        </div>
    ) : (
        <div
            className={cx(
                index == 3 ? "bg-primary-50" : "bg-primary-100",
                "flex h-full w-full items-center justify-center uppercase text-primary-500 outline-offset-[-0.5px]",
                avatarVariants({ size, outline }),
                className,
            )}
        >
            {text}
        </div>
    );

    return (
        <div
            key={index}
            className={cx(
                "rounded-full bg-white",
                avatarVariants({ size, shadow }),
            )}
        >
            {child}
        </div>
    );
};
