import type { SVGProps } from "./types";

export const WarningFilled = (props: SVGProps): JSX.Element => (
    <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8.03116 3.8122C7.59295 2.98447 6.4072 2.98447 5.96899 3.81221L2.65671 10.0687C2.24536 10.8457 2.80863 11.7813 3.6878 11.7813H10.3124C11.1915 11.7813 11.7548 10.8457 11.3434 10.0687L8.03116 3.8122ZM7.00008 8.75021C6.67808 8.75021 6.41674 8.48887 6.41674 8.16687V5.25021C6.41674 4.92821 6.67808 4.66687 7.00008 4.66687C7.32208 4.66687 7.58341 4.92821 7.58341 5.25021V8.16687C7.58341 8.48887 7.32208 8.75021 7.00008 8.75021ZM6.41674 9.91687C6.41674 10.2389 6.67808 10.5002 7.00008 10.5002C7.32208 10.5002 7.58341 10.2389 7.58341 9.91687C7.58341 9.59487 7.32208 9.33354 7.00008 9.33354C6.67808 9.33354 6.41674 9.59487 6.41674 9.91687Z"
            fill="currentColor"
        />
    </svg>
);
