import type { SVGProps } from "./types";

export const AdjustmentsFilled = (props: SVGProps): JSX.Element => (
    <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M5.625 4.75C5.625 4.26675 5.23325 3.875 4.75 3.875C4.26675 3.875 3.875 4.26675 3.875 4.75V11.1091C3.35192 11.4117 3 11.9773 3 12.625C3 13.2727 3.35192 13.8383 3.875 14.1409V15.25C3.875 15.7333 4.26675 16.125 4.75 16.125C5.23325 16.125 5.625 15.7333 5.625 15.25V14.1409C6.14808 13.8383 6.5 13.2727 6.5 12.625C6.5 11.9773 6.14808 11.4117 5.625 11.1091V4.75Z"
            fill="currentColor"
        />
        <path
            d="M10.875 4.75C10.875 4.26675 10.4832 3.875 10 3.875C9.51675 3.875 9.125 4.26675 9.125 4.75V5.85912C8.60192 6.1617 8.25 6.72725 8.25 7.375C8.25 8.02275 8.60192 8.5883 9.125 8.89088V15.25C9.125 15.7333 9.51675 16.125 10 16.125C10.4832 16.125 10.875 15.7333 10.875 15.25V8.89088C11.3981 8.5883 11.75 8.02275 11.75 7.375C11.75 6.72725 11.3981 6.1617 10.875 5.85912V4.75Z"
            fill="currentColor"
        />
        <path
            d="M15.25 3.875C15.7332 3.875 16.125 4.26675 16.125 4.75V11.1091C16.6481 11.4117 17 11.9773 17 12.625C17 13.2727 16.6481 13.8383 16.125 14.1409V15.25C16.125 15.7333 15.7332 16.125 15.25 16.125C14.7668 16.125 14.375 15.7333 14.375 15.25V14.1409C13.8519 13.8383 13.5 13.2727 13.5 12.625C13.5 11.9773 13.8519 11.4117 14.375 11.1091V4.75C14.375 4.26675 14.7668 3.875 15.25 3.875Z"
            fill="currentColor"
        />
    </svg>
);
