import { HTMLAttributes } from "react";
import Link from "next/link";
import { cx } from "class-variance-authority";
import { useTranslation } from "react-i18next";

import {
    HomeUnfilled,
    SearchUnfilled,
    CalendarFilled,
    CalendarUnfilled,
    HomeFilled,
    UserUnfilled,
    UserFilled,
    SearchFilled,
    SmViewGridAddUnfilled,
    SmViewGridAddFilled,
    ExclamationCircleFilled,
} from "@/components/Icons";

import { useAuth } from "@/lib/firebase/hooks";
import { useQuery } from "@apollo/client";
import { graphql } from "@/lib/gql";

const bottomBarProfile = graphql(`
    query bottomBarProfile {
        myProfile {
            firebaseId
            name
            phoneNumber
            email
        }
    }
`);

type Tab = "home" | "explore" | "book" | "upcoming" | "profile" | undefined;
type IconProps = { activeTab: boolean };
type BottomTabBarProps = { activeTab?: Tab };

export const BottomBar = ({
    className,
    children,
    ...props
}: HTMLAttributes<HTMLDivElement>): JSX.Element => (
    <div
        className={cx(
            "p-safe-inset-b fixed bottom-0 left-0 z-[3] w-full bg-white shadow lg:hidden",
            className,
        )}
        {...props}
    >
        {children}
    </div>
);

const BottomTabBarNew = ({ activeTab }: BottomTabBarProps): JSX.Element => {
    const { user } = useAuth();
    const { t, ready } = useTranslation("common");
    const { data, loading } = useQuery(bottomBarProfile, {
        fetchPolicy: "cache-and-network",
        skip: !user,
    });
    const profile = data?.myProfile;
    const hasIncompleteProfile =
        user && (!profile?.name || !profile.phoneNumber || !profile.email);
    if (!ready || loading) return <div />;

    const bottomBarRoutes = [
        {
            name: t("home", "Home"),
            key: "home",
            pathname: "/",
            icon: <HomeTabIcon activeTab={activeTab === "home"} />,
        },
        {
            name: t("explore", "Explore"),
            key: "explore",
            pathname: "/explore/category",
            icon: <ExploreTabIcon activeTab={activeTab === "explore"} />,
        },
        {
            name: t("book", "Book"),
            key: "book",
            pathname: "/search",
            icon: <BookTabIcon activeTab={activeTab === "book"} />,
        },
        {
            name: t("upcoming", { defaultValue: "Upcoming" }),
            key: "upcoming",
            pathname: "/user/upcoming",
            icon: <UpcomingTabIcon activeTab={activeTab === "upcoming"} />,
        },
        {
            name: t("profile", { defaultValue: "Profile" }),
            key: "profile",
            pathname: user ? "/user" : "/login",
            icon: (
                <div className="relative size-7">
                    {hasIncompleteProfile && (
                        <ExclamationCircleFilled className="absolute right-[-3px] top-[-7px] size-5 text-destructive-600" />
                    )}
                    <ProfileTabIcon activeTab={activeTab === "profile"} />
                </div>
            ),
        },
    ];

    return (
        <BottomBar>
            <div className="flex w-full justify-between px-4 py-2">
                {bottomBarRoutes.map((opt) => {
                    const isTabActive = activeTab === opt.key;
                    return (
                        <Link key={opt.key} href={opt.pathname} passHref>
                            <div
                                className={cx(
                                    "flex w-[52px] flex-col items-center text-center",
                                    isTabActive
                                        ? "text-primary"
                                        : "text-blue-grey",
                                )}
                            >
                                {opt.icon}
                                <span className="typography-micro font-medium">
                                    {opt.name}
                                </span>
                            </div>
                        </Link>
                    );
                })}
            </div>
        </BottomBar>
    );
};
export default BottomTabBarNew;

const HomeTabIcon = ({ activeTab }: IconProps): JSX.Element => {
    if (!activeTab)
        return <HomeUnfilled className="size-7 text-blue-grey-400" />;
    return <HomeFilled className="size-7 text-primary" />;
};
const ExploreTabIcon = ({ activeTab }: IconProps): JSX.Element => {
    if (!activeTab)
        return <SearchUnfilled className="size-7 text-blue-grey-400" />;
    return <SearchFilled className="size-7 text-primary" />;
};
const BookTabIcon = ({ activeTab }: IconProps): JSX.Element => {
    if (!activeTab)
        return <SmViewGridAddUnfilled className="size-7 text-blue-grey-400" />;
    return <SmViewGridAddFilled className="size-7 text-primary" />;
};
const UpcomingTabIcon = ({ activeTab }: IconProps): JSX.Element => {
    if (!activeTab)
        return <CalendarUnfilled className="size-7 text-blue-grey-400" />;
    return <CalendarFilled className="size-7 text-primary" />;
};
const ProfileTabIcon = ({ activeTab }: IconProps): JSX.Element => {
    if (!activeTab)
        return <UserUnfilled className="size-7 text-blue-grey-400" />;
    return <UserFilled className="size-7 text-primary" />;
};
