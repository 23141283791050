import { css } from "@emotion/react";

import { ReattemptBookingCard } from "@/components/BookingCard";
import BookingList from "@/components/BookingList/BookingList";

const styles = {
    topRightAbsolute: css`
        position: fixed;
        top: 90;
        right: 0;
        z-index: 9;
        padding: 16px;
    `,
    fullWidthCard: css`
        width: calc(100vw - 24px);
        margin-right: 16px;
    `,
    bottomFixed: css`
        width: 100%;
        position: fixed;
        bottom: 80px;
        z-index: 9;
    `,
    bookingScrollContainer: css`
        width: 100% !important;
        display: flex;
        padding: 16px 8px;
    `,
};
type BookingUnconfirmedHomePageProps = {
    unconfirmedBookings: {
        uid: string;
        tenantId: string;
        referenceId: string;
        created: string;
        startDt: string;
        endDt: string;
        confirmed?: string | null;
        cancelled?: string | null;
        resources: {
            uid: string;
            resource: { uid: string; name: string; archived?: string | null };
        }[];
        service?: {
            uid: string;
            serviceMode: string;
            category?: { uid: string; name: string } | null;
        } | null;
        metadata: { price?: string; workflowId?: string; invoiceId?: string };
    }[];
    isDesktop: boolean;
};
const BookingUnconfirmedHomePage = ({
    unconfirmedBookings,
    isDesktop = true,
}: BookingUnconfirmedHomePageProps): JSX.Element => {
    const groupedUnconfirmedBookings =
        BookingList.groupBookings(unconfirmedBookings);

    if (isDesktop) {
        return (
            <div css={styles.topRightAbsolute}>
                {groupedUnconfirmedBookings.map((booking) => (
                    <ReattemptBookingCard
                        key={booking.referenceId}
                        booking={booking}
                        href={getHref(booking)}
                    />
                ))}
            </div>
        );
    }

    return (
        <div css={styles.bottomFixed}>
            <div
                css={styles.bookingScrollContainer}
                style={{
                    justifyContent:
                        groupedUnconfirmedBookings.length < 3
                            ? "center"
                            : undefined,
                }}
            >
                {groupedUnconfirmedBookings.map((booking) => (
                    <ReattemptBookingCard
                        key={booking.referenceId}
                        booking={booking}
                        href={getHref(booking)}
                    />
                ))}
            </div>
        </div>
    );
};

export default BookingUnconfirmedHomePage;

type GroupedBookings = ReturnType<typeof BookingList.groupBookings>[0];
const getHref = (b: GroupedBookings): string => {
    const { workflowType } = b.metadata;
    const checkoutHref =
        workflowType === "CourtsiteRescheduleWorkflow"
            ? `reschedule/checkout/${b.metadata.workflowId}`
            : `/checkout/${b.metadata.workflowId}/payment`;
    return b.metadata.invoiceId
        ? `/invoice/${b.tenantId}/${b.metadata.invoiceId}`
        : checkoutHref;
};
