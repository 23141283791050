import {
    type ComponentPropsWithoutRef,
    type ElementRef,
    forwardRef,
} from "react";
import { cx } from "class-variance-authority";
import * as ScrollAreaPrimitive from "@radix-ui/react-scroll-area";

export interface ScrollAreaProps
    extends ComponentPropsWithoutRef<typeof ScrollAreaPrimitive.Root> {
    orientation?: "vertical" | "horizontal";
}

const ScrollArea = forwardRef<
    ElementRef<typeof ScrollAreaPrimitive.Root>,
    ScrollAreaProps
>(({ className, orientation = "vertical", children, ...props }, ref) => (
    <ScrollAreaPrimitive.Root
        ref={ref}
        className={cx("relative overflow-hidden pb-2.5", className)}
        {...props}
    >
        <ScrollAreaPrimitive.Viewport className="flex h-full w-full [&>div]:!flex [&>div]:w-full [&>div]:flex-1">
            {children}
        </ScrollAreaPrimitive.Viewport>
        <ScrollBar orientation={orientation} />
        <ScrollAreaPrimitive.Corner />
    </ScrollAreaPrimitive.Root>
));
ScrollArea.displayName = ScrollAreaPrimitive.Root.displayName;

const ScrollAreaDropdown = forwardRef<
    React.ElementRef<typeof ScrollAreaPrimitive.Root>,
    React.ComponentPropsWithoutRef<typeof ScrollAreaPrimitive.Root>
>(({ className, children, ...props }, ref) => (
    <ScrollAreaPrimitive.Root
        ref={ref}
        className={cx("relative w-full overflow-hidden", className)}
        {...props}
    >
        <ScrollAreaPrimitive.Viewport className="h-full w-full rounded-[inherit]">
            {children}
        </ScrollAreaPrimitive.Viewport>
        <ScrollBar />
        <ScrollAreaPrimitive.Corner />
    </ScrollAreaPrimitive.Root>
));
ScrollAreaDropdown.displayName = ScrollAreaPrimitive.Root.displayName;

const ScrollAreaCombobox = forwardRef<
    ElementRef<typeof ScrollAreaPrimitive.Root>,
    ComponentPropsWithoutRef<typeof ScrollAreaPrimitive.Root>
>(({ className, children, ...props }, ref) => (
    <ScrollAreaPrimitive.Root
        ref={ref}
        className={cx("relative overflow-hidden", className)}
        {...props}
    >
        <ScrollAreaPrimitive.Viewport className="flex h-full max-w-full rounded-[inherit] [&>div]:!flex [&>div]:max-w-full [&>div]:flex-1 [&>div]:flex-col">
            {children}
        </ScrollAreaPrimitive.Viewport>
        <ScrollBar />
        <ScrollAreaPrimitive.Corner />
    </ScrollAreaPrimitive.Root>
));
ScrollAreaCombobox.displayName = ScrollAreaPrimitive.Root.displayName;

const ScrollBar = forwardRef<
    ElementRef<typeof ScrollAreaPrimitive.ScrollAreaScrollbar>,
    ComponentPropsWithoutRef<typeof ScrollAreaPrimitive.ScrollAreaScrollbar>
>(({ className, orientation = "vertical", ...props }, ref) => (
    <ScrollAreaPrimitive.ScrollAreaScrollbar
        ref={ref}
        orientation={orientation}
        className={cx(
            "flex touch-none select-none transition-colors",
            orientation === "vertical" &&
                "h-full w-2 border-l border-l-transparent p-[1px]",
            orientation === "horizontal" &&
                "h-2 flex-col border-t border-t-transparent p-[1px]",
            className,
        )}
        {...props}
    >
        <ScrollAreaPrimitive.ScrollAreaThumb className="relative h-10 flex-1 rounded-full bg-blue-grey-100" />
    </ScrollAreaPrimitive.ScrollAreaScrollbar>
));
ScrollBar.displayName = ScrollAreaPrimitive.ScrollAreaScrollbar.displayName;

export { ScrollArea, ScrollAreaCombobox, ScrollAreaDropdown };
