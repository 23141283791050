/* eslint-disable */
import * as types from "./graphql";
import { TypedDocumentNode as DocumentNode } from "@graphql-typed-document-node/core";

/**
 * Map of all GraphQL operations in the project.
 *
 * This map has several performance disadvantages:
 * 1. It is not tree-shakeable, so it will include all operations in the project.
 * 2. It is not minifiable, so the string of a GraphQL query will be multiple times inside the bundle.
 * 3. It does not support dead code elimination, so it will add unused operations.
 *
 * Therefore it is highly recommended to use the babel or swc plugin for production.
 * Learn more about it here: https://the-guild.dev/graphql/codegen/plugins/presets/preset-client#reducing-bundle-size
 */
const documents = {
    "\n    query unavailableSlotsService(\n        $categoryId: ID!\n        $dtRange: DateRangeFilter!\n        $tenantId: ID!\n        $isLoggedIn: Boolean!\n    ) {\n        unavailableResourceSlotsBetween(\n            categoryId: $categoryId\n            dtRange: $dtRange\n            tenantId: $tenantId\n        ) {\n            resourceId\n            startDt\n            endDt\n        }\n        onlineServices(\n            tenantId: $tenantId\n            filter: { byCategoryId: $categoryId }\n        ) {\n            uid\n            name\n            startTime\n            resources {\n                uid\n                archived\n                resourceId\n            }\n        }\n        serviceTags(tenantId: $tenantId) {\n            serviceId\n        }\n        myMemberServiceIds(tenantId: $tenantId) @include(if: $isLoggedIn)\n    }\n":
        types.UnavailableSlotsServiceDocument,
    "\n    query unavailableSlots(\n        $categoryId: ID!\n        $dtRange: DateRangeFilter!\n        $tenantId: ID!\n    ) {\n        unavailableResourceSlotsBetween(\n            categoryId: $categoryId\n            dtRange: $dtRange\n            tenantId: $tenantId\n        ) {\n            tenantId\n            resourceId\n            startDt\n            endDt\n        }\n        onlineServices(\n            tenantId: $tenantId\n            filter: { byCategoryId: $categoryId }\n        ) {\n            uid\n            resources {\n                uid\n                archived\n                resource {\n                    uid\n                    name\n                }\n            }\n        }\n    }\n":
        types.UnavailableSlotsDocument,
    "\n    query pelangganAvailabilityView($tenantId: ID!, $isLoggedIn: Boolean!) {\n        categories {\n            uid\n            name\n        }\n        onlineServices(tenantId: $tenantId) {\n            uid\n            name\n            serviceMode\n            bookingWindow\n            metadata\n            resources {\n                uid\n                archived\n                resource {\n                    uid\n                    name\n                }\n            }\n            serviceCategory {\n                uid\n                categoryId\n            }\n        }\n        serviceTags(tenantId: $tenantId) {\n            serviceId\n        }\n        myMemberServiceIds(tenantId: $tenantId) @include(if: $isLoggedIn)\n    }\n":
        types.PelangganAvailabilityViewDocument,
    "\n    query pelangganAvailabilityViewUnavailabilities(\n        $tenantId: ID!\n        $categoryId: ID!\n        $dtRange: DateRangeFilter!\n    ) {\n        unavailableResourceSlotsBetween(\n            categoryId: $categoryId\n            dtRange: $dtRange\n            tenantId: $tenantId\n        ) {\n            resourceId\n            startDt\n            endDt\n        }\n    }\n":
        types.PelangganAvailabilityViewUnavailabilitiesDocument,
    "\n    query pelangganAvailabilityViewDurationForm(\n        $req: [ServiceAvailabilityRequest!]!\n        $quotReq: [QuotationRequest!]!\n    ) {\n        serviceAvailabilities(request: $req) {\n            isAvailable\n        }\n        quotations(request: $quotReq) {\n            deposit\n            remainder\n            price\n            satisfiesMinimumDuration\n            timeFullyAccounted\n        }\n    }\n":
        types.PelangganAvailabilityViewDurationFormDocument,
    "\n    query bookingCard($tenantId: ID!) {\n        organisation(uid: $tenantId) {\n            uid\n            name\n        }\n    }\n":
        types.BookingCardDocument,
    "\n    query reattemptBookingCard($tenantId: ID!) {\n        organisation(uid: $tenantId) {\n            uid\n            name\n        }\n    }\n":
        types.ReattemptBookingCardDocument,
    "\n    mutation activateLightEvents($request: ActivateLightEventsRequest!) {\n        activateLightEvents(request: $request) {\n            __typename\n            ... on LightEventsActivated {\n                groupActivations {\n                    archived\n                    created\n                    groupId\n                    tenant {\n                        name\n                        uid\n                        metadata\n                        isCourtsitePartner\n                        services\n                    }\n                    tenantId\n                    uid\n                }\n            }\n            ... on AccessError {\n                message\n            }\n            ... on NotFoundError {\n                message\n            }\n        }\n    }\n":
        types.ActivateLightEventsDocument,
    "\n    query pelangganUserBookingsCardWebCheckout($workflowId: ID!) {\n        courtsiteCheckoutStateById(checkoutId: $workflowId) {\n            created\n            hasDeposit\n            state\n        }\n    }\n":
        types.PelangganUserBookingsCardWebCheckoutDocument,
    "\n    query pelangganUserBookingsCardWebCheckoutReschedule($workflowId: ID!) {\n        courtsiteRescheduleStateById(workflowId: $workflowId) {\n            created\n            state\n        }\n    }\n":
        types.PelangganUserBookingsCardWebCheckoutRescheduleDocument,
    "\n    query bottomBarProfile {\n        myProfile {\n            firebaseId\n            name\n            phoneNumber\n            email\n        }\n    }\n":
        types.BottomBarProfileDocument,
    "\n    mutation joinGame(\n        $gameID: ID!\n        $displayPicture: String\n        $email: EmailAddress\n        $name: String!\n        $phoneNumber: PhoneNumber\n    ) {\n        joinGame(\n            request: {\n                uid: $gameID\n                playerDetails: {\n                    displayPicture: $displayPicture\n                    email: $email\n                    name: $name\n                    phoneNumber: $phoneNumber\n                }\n            }\n        ) {\n            __typename\n            ... on AccessError {\n                message\n            }\n            ... on NoPlayerSlotError {\n                message\n            }\n            ... on NotFoundError {\n                message\n            }\n        }\n    }\n":
        types.JoinGameDocument,
    "\n    mutation leaveGame($gameID: ID!) {\n        leaveGame(request: { uid: $gameID }) {\n            __typename\n            ... on AccessError {\n                message\n            }\n            ... on NotFoundError {\n                message\n            }\n        }\n    }\n":
        types.LeaveGameDocument,
    "\n    fragment CategoryListCategories on Category {\n        uid\n        name\n    }\n":
        types.CategoryListCategoriesFragmentDoc,
    "\n    fragment CategoryListVenues on Organisation {\n        uid\n        services\n    }\n":
        types.CategoryListVenuesFragmentDoc,
    "\n    query desktopNavProfile {\n        myProfile {\n            firebaseId\n            name\n            email\n        }\n    }\n":
        types.DesktopNavProfileDocument,
    "\n    query downloadInvoice($invoiceId: ID!) {\n        myInvoice(invoiceId: $invoiceId) {\n            uid\n            invoiceeName\n            outstandingBalance\n            total\n            printNumber\n            created\n            isCourtsiteOwned\n            tenant {\n                uid\n                name\n                metadata\n            }\n            payments {\n                uid\n                paymentType\n                paymentMethod {\n                    uid\n                    name\n                }\n                created\n            }\n            items {\n                uid\n                description\n                quantity\n                subtotal\n                metadata\n                relatedEntity {\n                    uid\n                    entityId\n                    entityType\n                }\n            }\n        }\n    }\n":
        types.DownloadInvoiceDocument,
    "\n    query downloadInvoiceBookings($bookingIds: [ID!]!) {\n        myBookingsByIds(bookingIds: $bookingIds) {\n            uid\n            startDt\n            endDt\n            service {\n                uid\n                name\n                serviceMode\n            }\n            resources {\n                uid\n                archived\n                resource {\n                    uid\n                    name\n                }\n            }\n        }\n    }\n":
        types.DownloadInvoiceBookingsDocument,
    "\n    fragment FeatureVenueCategories on Category {\n        uid\n        name\n    }\n":
        types.FeatureVenueCategoriesFragmentDoc,
    "\n    query featureVenueOrganisations(\n        $filter: ApprovedOrganisationsFilter\n        $cursor: String\n    ) {\n        approvedOrganisations(\n            sortOrder: BY_WEIGHTAGE_DESC\n            limit: 6\n            after: $cursor\n            filter: $filter\n        ) {\n            edges {\n                node {\n                    uid\n                    name\n                    metadata\n                    services\n                }\n            }\n        }\n    }\n":
        types.FeatureVenueOrganisationsDocument,
    "\n    mutation createUserProfile(\n        $name: String!\n        $email: EmailAddress\n        $phoneNumber: PhoneNumber\n    ) {\n        createUserProfile(\n            request: { name: $name, email: $email, phoneNumber: $phoneNumber }\n        ) {\n            __typename\n            ... on AccessError {\n                message\n            }\n            ... on InvalidValue {\n                message\n            }\n        }\n    }\n":
        types.CreateUserProfileDocument,
    "\n    query addOnSelect($tenantId: ID!) {\n        addOns(tenantId: $tenantId) {\n            uid\n            name\n            price\n            isInStock\n        }\n    }\n":
        types.AddOnSelectDocument,
    "\n    query bookingCategorySelect($tenantId: ID!) {\n        onlineServices(tenantId: $tenantId) {\n            uid\n            name\n            minBookingWindowMinutes\n            bookingWindow\n            disallowBookingGap\n            metadata\n            serviceMode\n            category {\n                uid\n                name\n                metadata\n            }\n            resources {\n                uid\n                resourceId\n                archived\n            }\n            customDurationOptions\n        }\n        addOns(tenantId: $tenantId) {\n            uid\n            name\n            price\n            isInStock\n        }\n        myMemberServiceIds(tenantId: $tenantId)\n        serviceTags(tenantId: $tenantId) {\n            serviceId\n        }\n    }\n":
        types.BookingCategorySelectDocument,
    "\n    query pelangganBookingFormOnlineServices($tenantId: ID!, $categoryId: ID!) {\n        onlineServices(\n            tenantId: $tenantId\n            filter: { byCategoryId: $categoryId }\n        ) {\n            uid\n            name\n            serviceMode\n            category {\n                uid\n            }\n            resources {\n                uid\n                archived\n                resource {\n                    uid\n                    name\n                    linkedResources {\n                        uid\n                    }\n                }\n            }\n            minBookingWindowMinutes\n            bookingWindow\n            disallowBookingGap\n            customDurationOptions\n            startTime\n            endTime\n        }\n        organisation(uid: $tenantId) {\n            uid\n            metadata\n        }\n    }\n":
        types.PelangganBookingFormOnlineServicesDocument,
    "\n    query bookingSelectStartDurationSelect(\n        $tenantId: ID!\n        $serviceIds: [ID!]!\n        $startDt: DateTime!\n        $endDt: DateTime!\n        $categoryId: ID!\n    ) {\n        onlineServices(tenantId: $tenantId) {\n            uid\n            minBookingWindowMinutes\n            disallowBookingGap\n            metadata\n            resources {\n                uid\n                archived\n                resourceId\n            }\n            customDurationOptions\n        }\n        fixedDurationOptionsStartingBetween(\n            tenantId: $tenantId\n            serviceIds: $serviceIds\n            start: $startDt\n            end: $endDt\n        ) {\n            serviceId\n            options {\n                start\n                end\n            }\n        }\n        unavailableResourceSlotsBetween(\n            categoryId: $categoryId\n            dtRange: { startDt: $startDt, endDt: $endDt }\n            tenantId: $tenantId\n        ) {\n            endDt\n            resourceId\n            startDt\n        }\n    }\n":
        types.BookingSelectStartDurationSelectDocument,
    "\n    query cartCentreDetails($tenantId: ID!) {\n        organisation(uid: $tenantId) {\n            uid\n            name\n            services\n            metadata\n        }\n        categories {\n            uid\n            name\n        }\n    }\n":
        types.CartCentreDetailsDocument,
    "\n    query checkoutDiscount($items: [CheckoutDiscountItem!]!, $promocode: ID!) {\n        checkoutDiscount(items: $items, promocodeId: $promocode) {\n            discountedAmount\n        }\n    }\n":
        types.CheckoutDiscountDocument,
    "\n    query dailyBookingSteps($tenantId: ID!) {\n        onlineServices(tenantId: $tenantId) {\n            uid\n            name\n            serviceMode\n            category {\n                uid\n            }\n            resources {\n                uid\n                archived\n                resource {\n                    uid\n                    name\n                    linkedResources {\n                        uid\n                    }\n                }\n            }\n            minBookingWindowMinutes\n            bookingWindow\n            disallowBookingGap\n            startTime\n            endTime\n        }\n    }\n":
        types.DailyBookingStepsDocument,
    "\n    query bookingSelectAvailability(\n        $availabilitiesRequest: [ServiceAvailabilityRequest!]!\n        $quotationsRequest: [QuotationRequest!]!\n        $tenantId: ID!\n        $startDt: DateTime!\n        $endDt: DateTime!\n        $categoryId: ID!\n    ) {\n        serviceAvailabilities(request: $availabilitiesRequest) {\n            availableSelectableResourceIds\n            serviceId\n        }\n        quotations(request: $quotationsRequest) {\n            deposit\n            price\n            quotationFor\n            remainder\n            timeFullyAccounted\n        }\n        unavailableResourceSlotsBetween(\n            categoryId: $categoryId\n            dtRange: { startDt: $startDt, endDt: $endDt }\n            tenantId: $tenantId\n        ) {\n            endDt\n            resourceId\n            startDt\n        }\n        onlineServices(tenantId: $tenantId) {\n            uid\n            category {\n                uid\n            }\n        }\n    }\n":
        types.BookingSelectAvailabilityDocument,
    "\n    query pelangganGameFormGameCreateFromInvoiceForm(\n        $tenantId: ID!\n        $uid: ID!\n    ) {\n        invoice(uid: $uid, tenantId: $tenantId) {\n            uid\n            items {\n                uid\n                relatedEntity {\n                    uid\n                    entityId\n                    entityType\n                }\n            }\n        }\n        organisation(uid: $tenantId) {\n            uid\n            name\n            metadata\n        }\n    }\n":
        types.PelangganGameFormGameCreateFromInvoiceFormDocument,
    "\n    query pelangganGameFormDateTimeForm($tenantId: ID!, $uid: ID!) {\n        invoice(uid: $uid, tenantId: $tenantId) {\n            uid\n            items {\n                uid\n                relatedEntity {\n                    uid\n                    entityId\n                    entityType\n                }\n            }\n        }\n        organisation(uid: $tenantId) {\n            uid\n            name\n            metadata\n        }\n    }\n":
        types.PelangganGameFormDateTimeFormDocument,
    "\n    query pelangganGameFormDateTimeFormBookings($tenantId: ID!, $uids: [ID!]!) {\n        bookingsByIds(tenantId: $tenantId, bookingIds: $uids) {\n            uid\n            startDt\n            endDt\n        }\n    }\n":
        types.PelangganGameFormDateTimeFormBookingsDocument,
    "\n    mutation pelangganUserDataUseUpdateUserProfile(\n        $request: SetUserProfileRequest!\n    ) {\n        setUserProfile(request: $request) {\n            __typename\n            ... on UserProfileSet {\n                user {\n                    firebaseId\n                    name\n                    email\n                    phoneNumber\n                }\n            }\n            ... on AccessError {\n                message\n            }\n\n            ... on InvalidValue {\n                message\n            }\n        }\n    }\n":
        types.PelangganUserDataUseUpdateUserProfileDocument,
    "\n    query userDataCollectionForm {\n        myProfile {\n            firebaseId\n            name\n            email\n            phoneNumber\n            gender\n            dateOfBirth\n            postCode\n            preferredSports\n            otherPreferredSports\n            sportsFrequency\n        }\n        categories {\n            uid\n            name\n        }\n    }\n":
        types.UserDataCollectionFormDocument,
    "\n    query pendingPayments($startAfter: DateTime) {\n        myInvoices(filter: { byStatus: { unpaid: true } }) {\n            uid\n            created\n            items {\n                uid\n                metadata\n                relatedEntity {\n                    uid\n                    entityType\n                    entityId\n                }\n            }\n        }\n        myBookings(\n            limit: 30\n            filter: {\n                byStatus: { unconfirmed: true }\n                byStartDateAfter: $startAfter\n            }\n        ) {\n            edges {\n                node {\n                    uid\n                    tenantId\n                    referenceId\n                    created\n                    startDt\n                    endDt\n                    confirmed\n                    cancelled\n                    metadata\n                    resources {\n                        uid\n                        resource {\n                            uid\n                            name\n                            archived\n                        }\n                    }\n                    service {\n                        uid\n                        serviceMode\n                        category {\n                            uid\n                            name\n                        }\n                    }\n                }\n            }\n        }\n    }\n":
        types.PendingPaymentsDocument,
    "\n    query homepageUpcomingCardWebCheckout($workflowId: ID!) {\n        courtsiteCheckoutStateById(checkoutId: $workflowId) {\n            id\n            cancelled\n            expired\n            created\n            hasDeposit\n            state\n        }\n    }\n":
        types.HomepageUpcomingCardWebCheckoutDocument,
    "\n    query homepageUpcomingCardWebCheckoutReschedule($workflowId: ID!) {\n        courtsiteRescheduleStateById(workflowId: $workflowId) {\n            id\n            cancelled\n            expired\n            created\n            state\n        }\n    }\n":
        types.HomepageUpcomingCardWebCheckoutRescheduleDocument,
    "\n    fragment LocationAccordionOption on SearchLocation {\n        uid\n        name\n        coordinates {\n            latitude\n            longitude\n        }\n        city\n    }\n":
        types.LocationAccordionOptionFragmentDoc,
    "\n    mutation pelangganUserDetailsModalSetUserProfile(\n        $request: SetUserProfileRequest!\n    ) {\n        setUserProfile(request: $request) {\n            __typename\n            ... on UserProfileSet {\n                user {\n                    firebaseId\n                }\n            }\n\n            ... on AccessError {\n                message\n            }\n\n            ... on InvalidValue {\n                message\n            }\n        }\n    }\n":
        types.PelangganUserDetailsModalSetUserProfileDocument,
    "\n    query navigationMenuProfile {\n        myProfile {\n            firebaseId\n            name\n            phoneNumber\n            email\n        }\n    }\n":
        types.NavigationMenuProfileDocument,
    "\n    query confirmCheckoutAvailabilities(\n        $serviceAvailabilitiesRequest: [ServiceAvailabilityRequest!]!\n    ) {\n        serviceAvailabilities(request: $serviceAvailabilitiesRequest) {\n            isAvailable\n        }\n    }\n":
        types.ConfirmCheckoutAvailabilitiesDocument,
    "\n    mutation startRescheduleCheckout(\n        $tenantId: ID!\n        $bookingId: ID!\n        $newSlot: CourtsiteBookingSlotInput!\n    ) {\n        startCourtsiteReschedule(\n            request: {\n                tenantId: $tenantId\n                bookingId: $bookingId\n                newSlot: $newSlot\n            }\n        ) {\n            __typename\n            ... on CourtsiteRescheduleStarted {\n                state {\n                    id\n                    runId\n                    state\n                    completed\n                    expired\n                    cancelled\n                    created\n                    priceDifference\n                    newBookingId\n                    newInvoiceId\n                    redirectUrl\n                    capturePaymentWorkflowId\n                    capturePaymentState {\n                        id\n                        tenantId\n                        invoiceId\n                        state\n                        amountToCapture\n                        paymentSessionId\n                        completed\n                        expired\n                        cancelled\n                        created\n                    }\n                }\n            }\n\n            ... on CourtsiteRescheduleBookingsUnavailable {\n                message\n            }\n\n            ... on WorkflowCancelled {\n                message\n            }\n\n            ... on WorkflowNotReady {\n                message\n            }\n        }\n    }\n":
        types.StartRescheduleCheckoutDocument,
    "\n    query searchCentreCardQuery {\n        categories {\n            uid\n            name\n        }\n    }\n":
        types.SearchCentreCardQueryDocument,
    "\n    fragment SearchBarCategories on Category {\n        uid\n        name\n        ...CategoryListCategories\n    }\n":
        types.SearchBarCategoriesFragmentDoc,
    "\n    fragment SearchBarLocations on SearchLocation {\n        uid\n        name\n        city\n        coordinates {\n            latitude\n            longitude\n        }\n    }\n":
        types.SearchBarLocationsFragmentDoc,
    "\n    fragment SearchBarVenues on Organisation {\n        uid\n        name\n        ...CategoryListVenues\n    }\n":
        types.SearchBarVenuesFragmentDoc,
    "\n    fragment SearchMobileCategories on Category {\n        uid\n        name\n        ...CategoryListCategories\n    }\n":
        types.SearchMobileCategoriesFragmentDoc,
    "\n    fragment SearchMobileLocations on SearchLocation {\n        uid\n        name\n        city\n        coordinates {\n            latitude\n            longitude\n        }\n    }\n":
        types.SearchMobileLocationsFragmentDoc,
    "\n    fragment SearchMobileVenues on Organisation {\n        uid\n        name\n        ...CategoryListVenues\n    }\n":
        types.SearchMobileVenuesFragmentDoc,
    "\n    query pelangganSearchResults($request: CourtsiteSearchRequest!) {\n        courtsiteSearch(request: $request) {\n            uid\n            name\n            services\n            metadata\n        }\n    }\n":
        types.PelangganSearchResultsDocument,
    "\n    query pelangganSearchResultLocation {\n        locations {\n            uid\n            name\n            coordinates {\n                latitude\n                longitude\n            }\n            city\n        }\n    }\n":
        types.PelangganSearchResultLocationDocument,
    "\n    query pelangganSearchResultService($categoryId: ID!) {\n        onlineServicesByCategory(categoryId: $categoryId) {\n            uid\n            tenantId\n        }\n    }\n":
        types.PelangganSearchResultServiceDocument,
    "\n    query pelangganCentreSearchUseAvailabilities(\n        $request: [ServiceAvailabilityRequest!]!\n        $quotationsRequest: [QuotationRequest!]!\n    ) {\n        serviceAvailabilities(request: $request) {\n            isAvailable\n            tenantId\n            serviceId\n        }\n        quotations(request: $quotationsRequest) {\n            quotationFor\n            timeFullyAccounted\n        }\n    }\n":
        types.PelangganCentreSearchUseAvailabilitiesDocument,
    "\n    query viewLiveAvailability($tenantId: ID!) {\n        onlineServices(tenantId: $tenantId) {\n            uid\n            name\n            minBookingWindowMinutes\n            bookingWindow\n            disallowBookingGap\n            metadata\n            serviceMode\n            category {\n                uid\n                name\n                metadata\n            }\n            resources {\n                uid\n                resourceId\n                archived\n            }\n            customDurationOptions\n        }\n        addOns(tenantId: $tenantId) {\n            uid\n            name\n            price\n            isInStock\n        }\n        myMemberServiceIds(tenantId: $tenantId)\n        serviceTags(tenantId: $tenantId) {\n            serviceId\n        }\n    }\n":
        types.ViewLiveAvailabilityDocument,
    "\n    query pelangganUseBookingsUnconfirmed(\n        $after: String\n        $filter: MyBookingsFilter\n        $limit: Int\n    ) {\n        myBookings(after: $after, filter: $filter, limit: $limit) {\n            edges {\n                node {\n                    uid\n                    created\n                    tenantId\n                    referenceId\n                    startDt\n                    endDt\n                    confirmed\n                    cancelled\n                    service {\n                        category {\n                            uid\n                        }\n                    }\n                    resources {\n                        resource {\n                            uid\n                            name\n                        }\n                        archived\n                    }\n                    metadata\n                }\n            }\n            pageInfo {\n                startCursor\n                endCursor\n                hasNextPage\n            }\n        }\n    }\n":
        types.PelangganUseBookingsUnconfirmedDocument,
    "\n    query pelangganUseBookings($tenantId: ID!, $bookingIds: [ID!]!) {\n        bookingsByIds(tenantId: $tenantId, bookingIds: $bookingIds) {\n            uid\n            startDt\n            endDt\n            metadata\n            cancelled\n            service {\n                uid\n                name\n                serviceMode\n                minRescheduleNotice\n                serviceCategory {\n                    uid\n                    categoryId\n                }\n            }\n            guests {\n                uid\n                userId\n            }\n            resources {\n                uid\n                archived\n                resource {\n                    uid\n                    name\n                }\n            }\n            referenceId\n        }\n    }\n":
        types.PelangganUseBookingsDocument,
    "\n    query centreByUid($uid: ID!, $isLoggedIn: Boolean!) {\n        organisation(uid: $uid) {\n            uid\n            name\n            location {\n                latitude\n                longitude\n            }\n            approved\n            metadata\n            services\n            isCourtsitePartner\n        }\n        onlineServices(tenantId: $uid) {\n            uid\n            name\n            serviceCategory {\n                uid\n                categoryId\n            }\n            resources {\n                uid\n                created\n                resourceId\n                optionKey\n                archived\n                resource {\n                    name\n                }\n            }\n            metadata\n            bookingWindow\n            minBookingWindowMinutes\n            disallowBookingGap\n            serviceMode\n            startTime\n            endTime\n            customDurationOptions\n        }\n        myMemberServiceIds(tenantId: $uid) @include(if: $isLoggedIn)\n        serviceTags(tenantId: $uid) {\n            serviceId\n        }\n        categories {\n            uid\n            name\n            metadata\n        }\n        addOns(tenantId: $uid) {\n            uid\n            name\n            price\n            isInStock\n        }\n    }\n":
        types.CentreByUidDocument,
    "\n    query courtsiteCheckoutStateById($checkoutId: ID!) {\n        courtsiteCheckoutStateById(checkoutId: $checkoutId) {\n            id\n            runId\n            tenantId\n            userId\n            state\n            completed\n            expired\n            cancelled\n            created\n            referenceId\n            bookingIds\n            invoiceIds\n            promocode\n            discountedAmount\n            addOns {\n                addOnId\n                tenantId\n                name\n                price\n                claimDt\n                quantity\n            }\n            capturePaymentState {\n                id\n                tenantId\n                state\n                paymentSessionId\n                completed\n                expired\n                cancelled\n                created\n                ...PaymentHandlerData\n            }\n        }\n    }\n":
        types.CourtsiteCheckoutStateByIdDocument,
    "\n    query courtsiteCheckoutWorkflowBookings(\n        $tenantId: ID!\n        $bookingIds: [ID!]!\n    ) {\n        bookingsByIds(tenantId: $tenantId, bookingIds: $bookingIds) {\n            uid\n            startDt\n            endDt\n            referenceId\n            metadata\n            cancelled\n            confirmed\n            service {\n                uid\n                name\n                serviceMode\n                minRescheduleNotice\n                serviceCategory {\n                    uid\n                    categoryId\n                }\n            }\n            resources {\n                uid\n                archived\n                resource {\n                    uid\n                    name\n                }\n            }\n        }\n    }\n":
        types.CourtsiteCheckoutWorkflowBookingsDocument,
    "\n    query fixedDurationOptionsStartingBetween(\n        $tenantId: ID!\n        $serviceIds: [ID!]!\n        $start: DateTime!\n        $end: DateTime!\n    ) {\n        fixedDurationOptionsStartingBetween(\n            tenantId: $tenantId\n            serviceIds: $serviceIds\n            start: $start\n            end: $end\n        ) {\n            serviceId\n            options {\n                start\n                end\n            }\n        }\n    }\n":
        types.FixedDurationOptionsStartingBetweenDocument,
    "\n    query getGame($gameId: ID!) {\n        game(uid: $gameId) {\n            categoryId\n            description\n            endDt\n            location {\n                tenant {\n                    uid\n                    name\n                    metadata\n                }\n            }\n            maxPlayers\n            metadata\n            name\n            organizer {\n                displayPicture\n                name\n                organizerId\n            }\n            players {\n                displayPicture\n                name\n                playerId\n            }\n            startDt\n            uid\n            isPrivate\n            reservedPlayers\n        }\n        categories {\n            uid\n            name\n        }\n    }\n":
        types.GetGameDocument,
    '\n    mutation startCheckout(\n        $tenantId: ID!\n        $slots: [CourtsiteCheckoutSlotInput!]!\n        $name: String!\n        $phoneNumber: String\n        $email: String!\n        $addOns: [CourtsiteCheckoutAddOnInput!]\n        $promocode: String\n    ) {\n        startCourtsiteCheckout(\n            request: {\n                tenantId: $tenantId\n                slots: $slots\n                guest: { name: $name, phoneNumber: $phoneNumber, email: $email }\n                metadata: "{}"\n                addOns: $addOns\n                promocode: $promocode\n            }\n        ) {\n            __typename\n\n            ... on CourtsiteCheckoutStarted {\n                state {\n                    id\n                    runId\n                    state\n                }\n            }\n\n            ... on CourtsiteCheckoutBookingsUnavailable {\n                message\n            }\n\n            ... on WorkflowCancelled {\n                message\n            }\n\n            ... on WorkflowNotReady {\n                message\n            }\n        }\n    }\n':
        types.StartCheckoutDocument,
    "\n    query publicGames($filter: PublicGamesFilter, $page: PageInput) {\n        publicGames(filter: $filter, page: $page) {\n            edges {\n                node {\n                    uid\n                    name\n                    categoryId\n                    startDt\n                    endDt\n                    maxPlayers\n                    location {\n                        tenant {\n                            uid\n                            name\n                        }\n                    }\n                    organizer {\n                        name\n                    }\n                    players {\n                        displayPicture\n                    }\n                    metadata\n                    reservedPlayers\n                }\n            }\n            pageInfo {\n                startCursor\n                endCursor\n                hasNextPage\n            }\n        }\n        categories {\n            uid\n            name\n        }\n    }\n":
        types.PublicGamesDocument,
    "\n    fragment bookingFields on Booking {\n        uid\n        startDt\n        endDt\n        referenceId\n        tenantId\n        metadata\n        service {\n            uid\n            name\n            serviceMode\n            minRescheduleNotice\n            category {\n                uid\n                name\n                metadata\n            }\n        }\n        resources {\n            uid\n            archived\n            resource {\n                uid\n                name\n            }\n        }\n    }\n":
        types.BookingFieldsFragmentDoc,
    "\n    query CourtsiteRescheduleStateById($workflowId: ID!) {\n        courtsiteRescheduleStateById(workflowId: $workflowId) {\n            id\n            runId\n            state\n            completed\n            expired\n            cancelled\n            created\n            priceDifference\n            oldBookingId\n            newBookingId\n            newInvoiceId\n            redirectUrl\n            capturePaymentWorkflowId\n            capturePaymentState {\n                id\n                runId\n                tenantId\n                state\n                amountToCapture\n                paymentSessionId\n                completed\n                expired\n                cancelled\n                created\n                isCancellable\n                attempts\n                ...PaymentHandlerData\n            }\n        }\n    }\n":
        types.CourtsiteRescheduleStateByIdDocument,
    "\n    query pelangganUseRescheduleCheckoutWorkflowBookings(\n        $oldBookingId: ID!\n        $newBookingId: ID!\n    ) {\n        oldBooking: myBooking(bookingId: $oldBookingId) {\n            ...bookingFields\n        }\n        newBooking: myBooking(bookingId: $newBookingId) {\n            ...bookingFields\n        }\n    }\n":
        types.PelangganUseRescheduleCheckoutWorkflowBookingsDocument,
    "\n    query getUserBookings(\n        $after: String\n        $filter: MyBookingsFilter\n        $limit: Int\n        $order: SortOrderEnum\n    ) {\n        myBookings(\n            after: $after\n            filter: $filter\n            limit: $limit\n            order: $order\n        ) {\n            edges {\n                node {\n                    uid\n                    created\n                    tenantId\n                    referenceId\n                    startDt\n                    endDt\n                    confirmed\n                    cancelled\n                    service {\n                        uid\n                        serviceMode\n                        category {\n                            uid\n                            name\n                        }\n                    }\n                    resources {\n                        uid\n                        archived\n                        resource {\n                            uid\n                            name\n                        }\n                    }\n                    metadata\n                }\n            }\n            pageInfo {\n                startCursor\n                endCursor\n                hasNextPage\n            }\n        }\n    }\n":
        types.GetUserBookingsDocument,
    "\n    query userGames(\n        $after: String\n        $filter: GameFilter\n        $limit: Int\n        $order: SortOrderEnum\n    ) {\n        myGames(after: $after, filter: $filter, limit: $limit, order: $order) {\n            edges {\n                node {\n                    name\n                    location {\n                        tenantId\n                    }\n                    startDt\n                    endDt\n                    archived\n                    categoryId\n                    metadata\n                    uid\n                    players {\n                        displayPicture\n                    }\n                    maxPlayers\n                    reservedPlayers\n                    organizer {\n                        organizerId\n                    }\n                }\n            }\n            pageInfo {\n                startCursor\n                endCursor\n                hasNextPage\n            }\n        }\n    }\n":
        types.UserGamesDocument,
    "\n    query getUserGroupedBookings(\n        $filter: MyGroupedBookingsFilter\n        $after: String\n        $limit: Int\n        $order: SortOrderEnum\n    ) {\n        myGroupedBookings(\n            filter: $filter\n            after: $after\n            limit: $limit\n            order: $order\n        ) {\n            edges {\n                node {\n                    referenceId\n                    startDt\n                    endDt\n                    bookings {\n                        uid\n                        created\n                        tenantId\n                        referenceId\n                        startDt\n                        endDt\n                        confirmed\n                        cancelled\n                        service {\n                            uid\n                            serviceMode\n                            categoryId\n                            categoryName\n                        }\n                        resources {\n                            uid\n                            resourceId\n                            resourceName\n                        }\n                        metadata\n                    }\n                }\n            }\n            pageInfo {\n                startCursor\n                endCursor\n                hasNextPage\n            }\n        }\n    }\n":
        types.GetUserGroupedBookingsDocument,
    "\n    query myProfile {\n        myProfile {\n            firebaseId\n            name\n            phoneNumber\n            email\n            gender\n            dateOfBirth\n            postCode\n            preferredSports\n            otherPreferredSports\n            sportsFrequency\n        }\n    }\n":
        types.MyProfileDocument,
    "\n    fragment PaymentHandlerData on CapturePaymentState {\n        invoiceId\n        billplzPaymentUrl\n        ipay88BackendUrl\n        ipay88ResponseUrl\n        ipay88RequestSignature\n        razorpayOrderId\n        razorpayResponseUrl\n        kiplepayReferenceNo\n        kiplepayHashValue\n        kiplepayCallbackUrl\n        kiplepayReturnUrl\n        kiplepayMerchantId\n        spayOrderNo\n        spayReturnUrl\n        spayCallbackUrl\n        spayEncryptedPayload\n        spayQrCode\n    }\n":
        types.PaymentHandlerDataFragmentDoc,
    "\n    query pelangganCentreActivitiesCategories(\n        $startsAfter: DateTime!\n        $tenantId: ID!\n    ) {\n        publicGameCount(startsAfter: $startsAfter) {\n            categoryId\n            gameCount\n            locationTenantIds\n        }\n        categories {\n            uid\n            name\n        }\n        organisation(uid: $tenantId) {\n            uid\n            name\n            services\n            metadata\n            isCourtsitePartner\n            location {\n                longitude\n                latitude\n            }\n        }\n    }\n":
        types.PelangganCentreActivitiesCategoriesDocument,
    "\n    query pelangganCentre($tenantId: ID!, $isLoggedIn: Boolean!) {\n        organisation(uid: $tenantId) {\n            uid\n            name\n            services\n            metadata\n            isCourtsitePartner\n            location {\n                longitude\n                latitude\n            }\n        }\n        onlineServices(tenantId: $tenantId) {\n            uid\n            name\n            serviceMode\n            serviceCategory {\n                uid\n                categoryId\n            }\n            resources {\n                uid\n                resourceId\n                archived\n            }\n            metadata\n        }\n        categories {\n            uid\n            name\n            metadata\n        }\n        serviceTags(tenantId: $tenantId) {\n            serviceId\n        }\n        myMemberServiceIds(tenantId: $tenantId) @include(if: $isLoggedIn)\n    }\n":
        types.PelangganCentreDocument,
    "\n    query pelangganCentreNearby($req: CourtsiteSearchRequest!) {\n        courtsiteSearch(request: $req) {\n            uid\n            ...VenueCardOrg\n        }\n    }\n":
        types.PelangganCentreNearbyDocument,
    "\n    fragment VenueCardOrg on Organisation {\n        uid\n        name\n        services\n        metadata\n    }\n":
        types.VenueCardOrgFragmentDoc,
    "\n    query pelangganCentreCategories {\n        categories {\n            uid\n            name\n            metadata\n        }\n    }\n":
        types.PelangganCentreCategoriesDocument,
    "\n    query centreBookingSelect($tenantId: ID!) {\n        organisation(uid: $tenantId) {\n            uid\n        }\n        onlineServices(tenantId: $tenantId) {\n            uid\n            name\n            minBookingWindowMinutes\n            bookingWindow\n            disallowBookingGap\n            metadata\n            serviceMode\n            category {\n                uid\n                name\n                metadata\n            }\n            customDurationOptions\n        }\n        addOns(tenantId: $tenantId) {\n            uid\n            name\n            price\n            isInStock\n        }\n        myMemberServiceIds(tenantId: $tenantId)\n        serviceTags(tenantId: $tenantId) {\n            serviceId\n        }\n    }\n":
        types.CentreBookingSelectDocument,
    "\n    query pelangganCheckoutWorkflowPayment($workflowID: ID!) {\n        courtsiteCheckoutStateById(checkoutId: $workflowID) {\n            id\n            created\n            expired\n            completed\n            cancelled\n            tenantId\n            bookingIds\n            promocode\n            discountedAmount\n            addOns {\n                addOnId\n                name\n                price\n                claimDt\n                quantity\n            }\n            capturePaymentState {\n                id\n                state\n                completed\n                expired\n                cancelled\n                ...PaymentHandlerData\n            }\n        }\n    }\n":
        types.PelangganCheckoutWorkflowPaymentDocument,
    "\n    query courtsiteCheckoutWorkflowPaymentBookings(\n        $tenantId: ID!\n        $bookingIds: [ID!]!\n    ) {\n        bookingsByIds(tenantId: $tenantId, bookingIds: $bookingIds) {\n            uid\n            startDt\n            endDt\n            referenceId\n            metadata\n            cancelled\n            confirmed\n            service {\n                uid\n                name\n                serviceMode\n                minRescheduleNotice\n                serviceCategory {\n                    uid\n                    categoryId\n                }\n            }\n            resources {\n                uid\n                archived\n                resource {\n                    uid\n                    name\n                }\n            }\n        }\n        organisation(uid: $tenantId) {\n            uid\n            name\n            metadata\n            isCourtsitePartner\n        }\n        categories {\n            uid\n            name\n            metadata\n        }\n        onlineServices(tenantId: $tenantId) {\n            uid\n            serviceCategory {\n                uid\n                categoryId\n            }\n        }\n    }\n":
        types.CourtsiteCheckoutWorkflowPaymentBookingsDocument,
    "\n    mutation provideCourtsiteCheckoutPaymentDetails(\n        $checkoutId: ID!\n        $name: String!\n        $email: String!\n        $phoneNumber: String!\n        $paymentProvider: PaymentProvider!\n        $redirectUrl: String\n    ) {\n        provideCourtsiteCheckoutPaymentDetails(\n            request: {\n                checkoutId: $checkoutId\n                name: $name\n                email: $email\n                phoneNumber: $phoneNumber\n                paymentProvider: $paymentProvider\n                redirectUrl: $redirectUrl\n            }\n        ) {\n            __typename\n\n            ... on CourtsiteCheckoutPaymentDetailsProvided {\n                state {\n                    id\n                    runId\n                    state\n                    capturePaymentState {\n                        id\n                        amountToCapture\n                        ...PaymentHandlerData\n                    }\n                }\n            }\n\n            ... on WorkflowNotFound {\n                message\n            }\n\n            ... on CourtsiteCheckoutExpired {\n                message\n            }\n\n            ... on WorkflowCancelled {\n                message\n            }\n\n            ... on WorkflowNotReady {\n                message\n            }\n        }\n    }\n":
        types.ProvideCourtsiteCheckoutPaymentDetailsDocument,
    "\n    mutation forceRetryCapturePayment(\n        $request: ForceRetryCapturePaymentRequest!\n    ) {\n        forceRetryCapturePayment(request: $request) {\n            __typename\n            ... on CapturePaymentForceRetried {\n                state {\n                    id\n                    amountToCapture\n                    ...PaymentHandlerData\n                }\n            }\n\n            ... on WorkflowNotFound {\n                message\n            }\n\n            ... on WorkflowNotReady {\n                message\n            }\n        }\n    }\n":
        types.ForceRetryCapturePaymentDocument,
    "\n    query checkoutResultCourtsiteCheckoutStateById($workflowID: ID!) {\n        courtsiteCheckoutStateById(checkoutId: $workflowID) {\n            id\n            runId\n            tenantId\n            completed\n            expired\n            cancelled\n            created\n            referenceId\n            bookingIds\n            invoiceIds\n            capturePaymentState {\n                id\n                completed\n                expired\n                cancelled\n                state\n            }\n        }\n    }\n":
        types.CheckoutResultCourtsiteCheckoutStateByIdDocument,
    "\n    query checkoutResultBookingsCentre(\n        $tenantId: ID!\n        $bookingIds: [ID!]!\n        $invoiceIds: [String]\n        $showInvoice: Boolean!\n    ) {\n        bookingsByIds(tenantId: $tenantId, bookingIds: $bookingIds) {\n            uid\n            startDt\n            endDt\n            referenceId\n            metadata\n            cancelled\n            confirmed\n            service {\n                uid\n                name\n                serviceMode\n                minRescheduleNotice\n                serviceCategory {\n                    uid\n                    categoryId\n                }\n            }\n            resources {\n                uid\n                archived\n                resource {\n                    uid\n                    name\n                }\n            }\n        }\n        organisation(uid: $tenantId) {\n            uid\n            name\n            location {\n                latitude\n                longitude\n            }\n            metadata\n        }\n        myInvoices(filter: { byInvoiceIds: $invoiceIds })\n            @include(if: $showInvoice) {\n            uid\n            total\n            outstandingBalance\n            cancelled\n            items {\n                uid\n                created\n                description\n                perUnitPrice\n                quantity\n                subtotal\n                metadata\n                relatedEntity {\n                    uid\n                    entityId\n                    entityType\n                }\n            }\n        }\n        groupIdEventsActivationStatus(\n            groupIds: $bookingIds\n            tenantId: $tenantId\n        ) {\n            groupId\n            isActivated\n            requiresUserActivation\n            tenantId\n        }\n    }\n":
        types.CheckoutResultBookingsCentreDocument,
    "\n    query pelangganCheckoutWorkflowVerify($workflowID: ID!) {\n        courtsiteCheckoutStateById(checkoutId: $workflowID) {\n            id\n            created\n            expired\n            completed\n            cancelled\n            capturePaymentState {\n                id\n                completed\n                expired\n                cancelled\n                state\n            }\n        }\n    }\n":
        types.PelangganCheckoutWorkflowVerifyDocument,
    "\n    mutation confirmCapturePayment(\n        $workflowId: ID!\n        $queryParams: JSONString!\n    ) {\n        confirmCapturePayment(\n            request: { workflowId: $workflowId, queryParams: $queryParams }\n        ) {\n            __typename\n\n            ... on CapturePaymentConfirmed {\n                state {\n                    id\n                }\n            }\n\n            ... on WorkflowNotFound {\n                message\n            }\n\n            ... on WorkflowCancelled {\n                message\n            }\n\n            ... on WorkflowNotReady {\n                message\n            }\n        }\n    }\n":
        types.ConfirmCapturePaymentDocument,
    "\n    query courtsiteCheckoutStateByIdSpay($checkoutId: ID!) {\n        courtsiteCheckoutStateById(checkoutId: $checkoutId) {\n            id\n            created\n            cancelled\n            completed\n            expired\n            capturePaymentState {\n                id\n                created\n                cancelled\n                completed\n                expired\n                amountToCapture\n                spayQrCode\n                paymentSessionCreated\n            }\n        }\n    }\n":
        types.CourtsiteCheckoutStateByIdSpayDocument,
    "\n    mutation forceRetryCapturePaymentSPayCheckout(\n        $request: ForceRetryCapturePaymentRequest!\n    ) {\n        forceRetryCapturePayment(request: $request) {\n            __typename\n            ... on CapturePaymentForceRetried {\n                state {\n                    tenantId\n                    invoiceId\n                    state\n                    amountToCapture\n                    completed\n                    expired\n                    cancelled\n                    created\n                    ...SPayDataCheckout\n                }\n            }\n\n            ... on WorkflowNotFound {\n                message\n            }\n\n            ... on WorkflowNotReady {\n                message\n            }\n        }\n    }\n":
        types.ForceRetryCapturePaymentSPayCheckoutDocument,
    "\n    fragment SPayDataCheckout on CapturePaymentState {\n        id\n        spayQrCode\n        paymentSessionCreated\n    }\n":
        types.SPayDataCheckoutFragmentDoc,
    "\n    fragment ExploreCategoryCentre on Organisation {\n        uid\n        name\n        metadata\n    }\n":
        types.ExploreCategoryCentreFragmentDoc,
    "\n    query pelangganExploreByCategoryIdData {\n        locations {\n            uid\n            name\n            coordinates {\n                latitude\n                longitude\n            }\n            city\n            ...LocationAccordionOption\n        }\n        categories {\n            name\n            uid\n            metadata\n        }\n    }\n":
        types.PelangganExploreByCategoryIdDataDocument,
    "\n    query pelangganExploreByCategoryIdSearch(\n        $after: String\n        $filter: ApprovedOrganisationsFilter\n        $sortOrder: OrganisationSortOrder\n        $limit: Int!\n    ) {\n        approvedOrganisations(\n            after: $after\n            filter: $filter\n            sortOrder: $sortOrder\n            limit: $limit\n        ) {\n            edges {\n                node {\n                    uid\n                    name\n                    isCourtsitePartner\n                    metadata\n                    location {\n                        latitude\n                        longitude\n                    }\n                    services\n                    ...ExploreCategoryCentre\n                }\n            }\n            pageInfo {\n                endCursor\n                hasNextPage\n            }\n        }\n    }\n":
        types.PelangganExploreByCategoryIdSearchDocument,
    "\n    query exploreCategory {\n        categories {\n            ...CategoryListCategories\n            ...SearchBarCategories\n        }\n        courtsitePartners {\n            ...CategoryListVenues\n            ...SearchBarVenues\n        }\n        locations {\n            ...SearchBarLocations\n        }\n    }\n":
        types.ExploreCategoryDocument,
    "\n    query exploreGames($startsAfter: DateTime!) {\n        publicGameCount(startsAfter: $startsAfter) {\n            categoryId\n            gameCount\n            locationTenantIds\n        }\n        categories {\n            uid\n            name\n            metadata\n        }\n        courtsitePartners {\n            uid\n            name\n            location {\n                latitude\n                longitude\n            }\n        }\n        locations {\n            uid\n            name\n            coordinates {\n                latitude\n                longitude\n            }\n            city\n        }\n    }\n":
        types.ExploreGamesDocument,
    "\n    query exploreGamesCourtsiteSearch(\n        $filter: ApprovedOrganisationsFilter\n        $limit: Int\n        $after: String\n    ) {\n        approvedOrganisations(filter: $filter, limit: $limit, after: $after) {\n            edges {\n                node {\n                    uid\n                }\n            }\n        }\n    }\n":
        types.ExploreGamesCourtsiteSearchDocument,
    "\n    mutation editGame(\n        $uid: ID!\n        $startDt: DateTime!\n        $endDt: DateTime!\n        $description: String!\n        $location: LocationInput!\n        $maxPlayers: Int!\n        $metadata: JSONString!\n        $name: String!\n        $private: Boolean\n        $reservedPlayers: Int\n    ) {\n        editGame(\n            request: {\n                uid: $uid\n                startDt: $startDt\n                endDt: $endDt\n                description: $description\n                location: $location\n                maxPlayers: $maxPlayers\n                metadata: $metadata\n                name: $name\n                private: $private\n                reservedPlayers: $reservedPlayers\n            }\n        ) {\n            __typename\n            ... on GameEdited {\n                game {\n                    uid\n                }\n            }\n            ... on AccessError {\n                message\n            }\n            ... on NotFoundError {\n                message\n            }\n        }\n    }\n":
        types.EditGameDocument,
    "\n    mutation archiveGame($uid: ID!) {\n        archiveGame(request: { uid: $uid }) {\n            __typename\n            ... on GameArchived {\n                game {\n                    uid\n                }\n            }\n            ... on AccessError {\n                message\n            }\n\n            ... on NotFoundError {\n                message\n            }\n        }\n    }\n":
        types.ArchiveGameDocument,
    "\n    query pelangganGame($gameId: ID!) {\n        game(uid: $gameId) {\n            categoryId\n            archived\n            description\n            endDt\n            location {\n                tenantId\n            }\n            maxPlayers\n            metadata\n            name\n            organizer {\n                displayPicture\n                name\n                organizerId\n            }\n            players {\n                displayPicture\n                name\n                playerId\n            }\n            startDt\n            uid\n            isPrivate\n            reservedPlayers\n        }\n        categories {\n            uid\n            name\n            metadata\n        }\n    }\n":
        types.PelangganGameDocument,
    "\n    query getCentre($orgID: ID!) {\n        organisation(uid: $orgID) {\n            uid\n            name\n            services\n            metadata\n            location {\n                latitude\n                longitude\n            }\n        }\n        onlineServices(tenantId: $orgID) {\n            uid\n            name\n            serviceMode\n            serviceCategory {\n                uid\n                categoryId\n            }\n            resources {\n                uid\n                resourceId\n                archived\n            }\n            metadata\n        }\n    }\n":
        types.GetCentreDocument,
    "\n    mutation startCourtsiteGameWorkflow(\n        $categoryId: ID!\n        $description: String!\n        $endDt: Time!\n        $location: StartCourtsiteGameWorkflowLocationInput!\n        $maxPlayers: Int!\n        $metadata: JSONString!\n        $name: String!\n        $organizerDetails: StartCourtsiteGameWorkflowPlayerInput!\n        $startDt: Time!\n        $private: Boolean\n        $reservedPlayers: Int\n    ) {\n        startCourtsiteGameWorkflow(\n            request: {\n                categoryId: $categoryId\n                description: $description\n                endDt: $endDt\n                location: $location\n                maxPlayers: $maxPlayers\n                metadata: $metadata\n                name: $name\n                organizerDetails: $organizerDetails\n                startDt: $startDt\n                private: $private\n                reservedPlayers: $reservedPlayers\n            }\n        ) {\n            ... on CourtsiteGameWorkflowStarted {\n                state {\n                    gameId\n                }\n            }\n            ... on WorkflowCancelled {\n                message\n            }\n            ... on WorkflowNotReady {\n                message\n            }\n        }\n    }\n":
        types.StartCourtsiteGameWorkflowDocument,
    "\n    query pelangganHomePage(\n        $now: DateTime!\n        $startsAfter: DateTime!\n        $isLoggedIn: Boolean!\n    ) {\n        upcomingBookings: myGroupedBookings(\n            filter: {\n                byStatus: { confirmed: true, unconfirmed: true }\n                byStartDateAfter: $now\n            }\n            limit: 3\n            order: ASC\n        ) @include(if: $isLoggedIn) {\n            edges {\n                node {\n                    referenceId\n                    startDt\n                    endDt\n                    bookings {\n                        tenant {\n                            uid\n                            name\n                        }\n                        uid\n                        created\n                        tenantId\n                        startDt\n                        endDt\n                        confirmed\n                        cancelled\n                        service {\n                            uid\n                            serviceMode\n                            categoryName\n                        }\n                        resources {\n                            resourceId\n                            resourceName\n                        }\n                        metadata\n                    }\n                }\n            }\n        }\n        publicGameCount(startsAfter: $startsAfter) {\n            categoryId\n            gameCount\n            locationTenantIds\n        }\n        categories {\n            ...FeatureVenueCategories\n            ...SocialGamesCategories\n            ...SearchBarCategories\n        }\n        tenantIdsFromPastBookings(limit: 3) @include(if: $isLoggedIn)\n        courtsitePartners(sortOrder: BY_WEIGHTAGE_DESC) {\n            uid\n            name\n            metadata\n            ...SearchBarVenues\n        }\n        locations {\n            ...SearchBarLocations\n        }\n    }\n":
        types.PelangganHomePageDocument,
    "\n    query homepagePublicGame($filter: PublicGamesFilter, $page: PageInput) {\n        publicGames(filter: $filter, page: $page) {\n            edges {\n                node {\n                    uid\n                    name\n                    categoryId\n                    startDt\n                    endDt\n                    maxPlayers\n                    location {\n                        tenant {\n                            uid\n                            name\n                        }\n                    }\n                    organizer {\n                        name\n                    }\n                    players {\n                        displayPicture\n                    }\n                    metadata\n                    reservedPlayers\n                }\n            }\n        }\n    }\n":
        types.HomepagePublicGameDocument,
    "\n    fragment SocialGamesCategories on Category {\n        uid\n        name\n    }\n":
        types.SocialGamesCategoriesFragmentDoc,
    "\n    query pelangganInvoice($tenantId: ID!, $uid: ID!) {\n        invoice(uid: $uid, tenantId: $tenantId) {\n            uid\n            total\n            outstandingBalance\n            metadata\n            expiresOn\n            printNumber\n            cancelled\n            created\n            metadata\n            invoiceeName\n            isCourtsiteOwned\n            items {\n                uid\n                description\n                perUnitPrice\n                quantity\n                subtotal\n                relatedEntity {\n                    uid\n                    entityId\n                    entityType\n                }\n                metadata\n                created\n            }\n            payments {\n                uid\n                created\n                amount\n                paymentType\n                paymentMethod {\n                    uid\n                    name\n                }\n            }\n        }\n        organisation(uid: $tenantId) {\n            uid\n            name\n            metadata\n            isCourtsitePartner\n            services\n        }\n        categories {\n            uid\n            name\n        }\n    }\n":
        types.PelangganInvoiceDocument,
    "\n    query pelangganOnboarding {\n        myProfile {\n            firebaseId\n            name\n            phoneNumber\n            email\n            gender\n            dateOfBirth\n            postCode\n            preferredSports\n            otherPreferredSports\n            sportsFrequency\n        }\n    }\n":
        types.PelangganOnboardingDocument,
    "\n    mutation pelangganOnboardingSetUserProfile(\n        $request: SetUserProfileRequest!\n    ) {\n        setUserProfile(request: $request) {\n            __typename\n            ... on UserProfileSet {\n                user {\n                    firebaseId\n                }\n            }\n            ... on AccessError {\n                message\n            }\n\n            ... on InvalidValue {\n                message\n            }\n        }\n    }\n":
        types.PelangganOnboardingSetUserProfileDocument,
    "\n    query capturePaymentResult(\n        $capturePaymentWorkflowId: ID!\n        $checkoutId: ID!\n        $hasCheckoutId: Boolean!\n    ) {\n        capturePaymentStateById(workflowId: $capturePaymentWorkflowId) {\n            id\n            invoiceId\n            tenantId\n            completed\n            expired\n            cancelled\n            created\n        }\n        courtsiteCheckoutStateById(checkoutId: $checkoutId)\n            @include(if: $hasCheckoutId) {\n            invoiceId\n        }\n    }\n":
        types.CapturePaymentResultDocument,
    "\n    query paymentResult(\n        $invoiceIds: [String!]!\n        $tenantId: ID!\n        $showInvoice: Boolean!\n    ) {\n        myInvoices(filter: { byInvoiceIds: $invoiceIds })\n            @include(if: $showInvoice) {\n            uid\n            total\n            outstandingBalance\n            cancelled\n            items {\n                uid\n                description\n                quantity\n                perUnitPrice\n                subtotal\n                metadata\n                created\n                relatedEntity {\n                    uid\n                    entityId\n                    entityType\n                }\n            }\n        }\n        organisation(uid: $tenantId) {\n            uid\n            name\n            location {\n                latitude\n                longitude\n            }\n            metadata\n        }\n    }\n":
        types.PaymentResultDocument,
    "\n    query paymentResultBookings($bookingIds: [ID!]!) {\n        myBookingsByIds(bookingIds: $bookingIds) {\n            uid\n            startDt\n            endDt\n            metadata\n            referenceId\n            cancelled\n            confirmed\n            service {\n                uid\n                name\n                serviceMode\n                minRescheduleNotice\n                category {\n                    uid\n                }\n            }\n            resources {\n                uid\n                archived\n                resource {\n                    uid\n                    name\n                }\n            }\n        }\n    }\n":
        types.PaymentResultBookingsDocument,
    "\n    query paymentInvoice($tenantId: ID!, $invoiceId: ID!) {\n        invoice(tenantId: $tenantId, uid: $invoiceId) {\n            uid\n            invoiceeName\n            invoiceePhoneNumber\n            invoiceeEmail\n            total\n            metadata\n            tenant {\n                uid\n                name\n                services\n                metadata\n                isCourtsitePartner\n            }\n            items {\n                uid\n                description\n                perUnitPrice\n                quantity\n                subtotal\n                metadata\n                relatedEntity {\n                    entityId\n                    entityType\n                }\n                created\n            }\n        }\n    }\n":
        types.PaymentInvoiceDocument,
    "\n    query paymentBookings($tenantId: ID!, $bookingIds: [ID!]!) {\n        bookingsByIds(tenantId: $tenantId, bookingIds: $bookingIds) {\n            uid\n            resources {\n                uid\n                archived\n                resource {\n                    uid\n                    name\n                }\n            }\n            service {\n                uid\n                serviceMode\n                category {\n                    uid\n                    name\n                }\n            }\n            startDt\n            endDt\n            referenceId\n        }\n    }\n":
        types.PaymentBookingsDocument,
    "\n    query capturePaymentStateById($workflowId: ID!) {\n        capturePaymentStateById(workflowId: $workflowId) {\n            tenantId\n            state\n            cancelled\n            expired\n            completed\n            invoiceId\n            ...PaymentHandlerData\n        }\n    }\n":
        types.CapturePaymentStateByIdDocument,
    "\n    mutation provideCapturePaymentProviderDetails(\n        $workflowId: ID!\n        $paymentProvider: PaymentProvider!\n        $redirectUrl: String\n    ) {\n        provideCapturePaymentProviderDetails(\n            request: {\n                workflowId: $workflowId\n                paymentProvider: $paymentProvider\n                redirectUrl: $redirectUrl\n            }\n        ) {\n            __typename\n\n            ... on CapturePaymentProviderDetailsProvided {\n                state {\n                    id\n                    runId\n                    state\n                    ...PaymentHandlerData\n                }\n            }\n\n            ... on WorkflowNotFound {\n                message\n            }\n\n            ... on WorkflowCancelled {\n                message\n            }\n\n            ... on WorkflowNotReady {\n                message\n            }\n        }\n    }\n":
        types.ProvideCapturePaymentProviderDetailsDocument,
    "\n    mutation forceRetryCapturePaymentLink(\n        $request: ForceRetryCapturePaymentRequest!\n    ) {\n        forceRetryCapturePayment(request: $request) {\n            __typename\n            ... on CapturePaymentForceRetried {\n                state {\n                    id\n                    runId\n                    state\n                    ...PaymentHandlerData\n                }\n            }\n\n            ... on WorkflowNotFound {\n                message\n            }\n\n            ... on WorkflowNotReady {\n                message\n            }\n        }\n    }\n":
        types.ForceRetryCapturePaymentLinkDocument,
    "\n    query pelangganCapturePaymentVerify($capturePaymentWorkflowId: ID!) {\n        capturePaymentStateById(workflowId: $capturePaymentWorkflowId) {\n            cancelled\n            completed\n            expired\n            id\n        }\n    }\n":
        types.PelangganCapturePaymentVerifyDocument,
    "\n    mutation pelangganPaymentCaptureWorkflowVerifyConfirmCapturePayment(\n        $capturePaymentWorkflowId: ID!\n        $queryParams: JSONString!\n    ) {\n        confirmCapturePayment(\n            request: {\n                workflowId: $capturePaymentWorkflowId\n                queryParams: $queryParams\n            }\n        ) {\n            __typename\n\n            ... on CapturePaymentConfirmed {\n                state {\n                    id\n                }\n            }\n\n            ... on WorkflowNotFound {\n                message\n            }\n\n            ... on WorkflowCancelled {\n                message\n            }\n\n            ... on WorkflowNotReady {\n                message\n            }\n        }\n    }\n":
        types.PelangganPaymentCaptureWorkflowVerifyConfirmCapturePaymentDocument,
    "\n    query capturePaymentVerificationSPay($workflowId: ID!) {\n        capturePaymentStateById(workflowId: $workflowId) {\n            id\n            created\n            cancelled\n            completed\n            expired\n            amountToCapture\n            spayQrCode\n            paymentSessionCreated\n        }\n    }\n":
        types.CapturePaymentVerificationSPayDocument,
    "\n    mutation forceRetryCapturePaymentSPayPayment(\n        $request: ForceRetryCapturePaymentRequest!\n    ) {\n        forceRetryCapturePayment(request: $request) {\n            __typename\n            ... on CapturePaymentForceRetried {\n                state {\n                    tenantId\n                    invoiceId\n                    state\n                    amountToCapture\n                    completed\n                    expired\n                    cancelled\n                    created\n                    ...SPayDataPayment\n                }\n            }\n\n            ... on WorkflowNotFound {\n                message\n            }\n\n            ... on WorkflowNotReady {\n                message\n            }\n        }\n    }\n":
        types.ForceRetryCapturePaymentSPayPaymentDocument,
    "\n    fragment SPayDataPayment on CapturePaymentState {\n        id\n        spayQrCode\n        paymentSessionCreated\n    }\n":
        types.SPayDataPaymentFragmentDoc,
    "\n    query pelangganRescheduleCategories {\n        categories {\n            uid\n            name\n            metadata\n        }\n    }\n":
        types.PelangganRescheduleCategoriesDocument,
    "\n    query pelangganRescheduleBookingBookingDetails(\n        $bookingIds: [ID!]!\n        $relatedEntityIds: [String]!\n    ) {\n        myBookingsByIds(bookingIds: $bookingIds) {\n            uid\n            startDt\n            endDt\n            created\n            confirmed\n            cancelled\n            metadata\n            guest {\n                uid\n                name\n                userId\n            }\n            resources {\n                uid\n                archived\n                resource {\n                    uid\n                    name\n                }\n            }\n            referenceId\n            tenant {\n                uid\n                name\n                metadata\n                location {\n                    latitude\n                    longitude\n                }\n            }\n            service {\n                uid\n                name\n                serviceMode\n                minRescheduleNotice\n                category {\n                    uid\n                    name\n                }\n            }\n        }\n        myInvoices(filter: { byRelatedEntityIds: $relatedEntityIds }) {\n            uid\n            associatedUsers {\n                userId\n            }\n            printNumber\n            outstandingBalance\n            metadata\n            cancelled\n            items {\n                uid\n                description\n                perUnitPrice\n                quantity\n                created\n                relatedEntity {\n                    entityId\n                    entityType\n                }\n                metadata\n            }\n        }\n    }\n":
        types.PelangganRescheduleBookingBookingDetailsDocument,
    "\n    query pelangganRescheduleStateByIdResult($workflowId: ID!) {\n        courtsiteRescheduleStateById(workflowId: $workflowId) {\n            id\n            oldBookingId\n            created\n            completed\n            cancelled\n            expired\n            priceDifference\n            capturePaymentState {\n                id\n                completed\n                expired\n                cancelled\n                created\n            }\n        }\n    }\n":
        types.PelangganRescheduleStateByIdResultDocument,
    "\n    mutation ProvideCourtsiteReschedulePaymentDetails(\n        $request: ProvideCourtsiteReschedulePaymentDetailsRequest!\n    ) {\n        provideCourtsiteReschedulePaymentDetails(request: $request) {\n            __typename\n            ... on CourtsiteReschedulePaymentDetailsProvided {\n                state {\n                    id\n                    runId\n                    state\n                    completed\n                    expired\n                    cancelled\n                    created\n                    priceDifference\n                    newBookingId\n                    newInvoiceId\n                    redirectUrl\n                    capturePaymentWorkflowId\n                    capturePaymentState {\n                        id\n                        runId\n                        state\n                        ...PaymentHandlerData\n                    }\n                }\n            }\n            ... on InvalidCourtsiteReschedulePaymentDetailsRequest {\n                message\n            }\n            ... on CourtsiteRescheduleExpired {\n                message\n            }\n            ... on WorkflowNotFound {\n                message\n            }\n            ... on WorkflowCancelled {\n                message\n            }\n            ... on WorkflowNotReady {\n                message\n            }\n        }\n    }\n":
        types.ProvideCourtsiteReschedulePaymentDetailsDocument,
    "\n    mutation pelangganRescheduleCheckoutForceRetryPayment(\n        $request: ForceRetryCapturePaymentRequest!\n    ) {\n        forceRetryCapturePayment(request: $request) {\n            __typename\n            ... on CapturePaymentForceRetried {\n                state {\n                    id\n                    runId\n                    state\n                    amountToCapture\n                    ...PaymentHandlerData\n                }\n            }\n\n            ... on WorkflowNotFound {\n                message\n            }\n\n            ... on WorkflowNotReady {\n                message\n            }\n        }\n    }\n":
        types.PelangganRescheduleCheckoutForceRetryPaymentDocument,
    "\n    query pelangganRescheduleStateByIdPayment($workflowId: ID!) {\n        courtsiteRescheduleStateById(workflowId: $workflowId) {\n            id\n            oldBookingId\n            newBookingId\n            newInvoiceId\n            created\n            completed\n            cancelled\n            expired\n            priceDifference\n            state\n            capturePaymentState {\n                id\n                state\n                completed\n                expired\n                cancelled\n                created\n                ...PaymentHandlerData\n            }\n        }\n    }\n":
        types.PelangganRescheduleStateByIdPaymentDocument,
    "\n    fragment bookingFieldsReschedulePayment on Booking {\n        uid\n        startDt\n        endDt\n        referenceId\n        tenantId\n        metadata\n        service {\n            uid\n            name\n            serviceMode\n            minRescheduleNotice\n            category {\n                uid\n                name\n                metadata\n            }\n        }\n        resources {\n            uid\n            archived\n            resource {\n                uid\n                name\n            }\n        }\n    }\n":
        types.BookingFieldsReschedulePaymentFragmentDoc,
    "\n    query pelangganRescheduleCheckoutPaymentBooking(\n        $oldBookingId: ID!\n        $newBookingId: ID!\n    ) {\n        oldBooking: myBooking(bookingId: $oldBookingId) {\n            ...bookingFieldsReschedulePayment\n        }\n        newBooking: myBooking(bookingId: $newBookingId) {\n            ...bookingFieldsReschedulePayment\n        }\n    }\n":
        types.PelangganRescheduleCheckoutPaymentBookingDocument,
    "\n    query rescheduleCheckoutPaymentOrg($tenantId: ID!) {\n        organisation(uid: $tenantId) {\n            uid\n            name\n            metadata\n            isCourtsitePartner\n        }\n        onlineServices(tenantId: $tenantId) {\n            uid\n            serviceCategory {\n                uid\n                categoryId\n            }\n        }\n    }\n":
        types.RescheduleCheckoutPaymentOrgDocument,
    "\n    query pelangganRescheduleStateByIdSuccess($workflowId: ID!) {\n        courtsiteRescheduleStateById(workflowId: $workflowId) {\n            id\n            newBookingId\n            newInvoiceId\n            created\n            completed\n            cancelled\n            expired\n            priceDifference\n            capturePaymentState {\n                id\n                tenantId\n                completed\n                expired\n                cancelled\n                created\n            }\n        }\n    }\n":
        types.PelangganRescheduleStateByIdSuccessDocument,
    "\n    query pelangganRescheduleCheckoutSuccess($bookingId: ID!, $invoiceId: ID!) {\n        myBooking(bookingId: $bookingId) {\n            uid\n            startDt\n            endDt\n            tenantId\n            referenceId\n            cancelled\n            confirmed\n            metadata\n            service {\n                uid\n                name\n                serviceMode\n                minRescheduleNotice\n                category {\n                    uid\n                    name\n                }\n            }\n            resources {\n                uid\n                archived\n                resource {\n                    uid\n                    name\n                }\n            }\n        }\n        myInvoice(invoiceId: $invoiceId) {\n            uid\n            total\n            outstandingBalance\n            cancelled\n            items {\n                uid\n                created\n                description\n                perUnitPrice\n                quantity\n                subtotal\n                metadata\n                relatedEntity {\n                    uid\n                    entityId\n                    entityType\n                }\n            }\n        }\n    }\n":
        types.PelangganRescheduleCheckoutSuccessDocument,
    "\n    query rescheduleCheckoutSuccessOrg($tenantId: ID!) {\n        organisation(uid: $tenantId) {\n            uid\n            name\n            location {\n                latitude\n                longitude\n            }\n            metadata\n        }\n    }\n":
        types.RescheduleCheckoutSuccessOrgDocument,
    "\n    query pelangganRescheduleStateByIdVerify($workflowId: ID!) {\n        courtsiteRescheduleStateById(workflowId: $workflowId) {\n            id\n            completed\n            cancelled\n            priceDifference\n            capturePaymentState {\n                id\n                completed\n                expired\n                cancelled\n            }\n        }\n    }\n":
        types.PelangganRescheduleStateByIdVerifyDocument,
    "\n    mutation pelangganRescheduleCaptureWorkflowVerifyConfirmCapturePayment(\n        $workflowId: ID!\n        $queryParams: JSONString!\n    ) {\n        confirmCapturePayment(\n            request: { workflowId: $workflowId, queryParams: $queryParams }\n        ) {\n            __typename\n\n            ... on CapturePaymentConfirmed {\n                state {\n                    id\n                }\n            }\n\n            ... on WorkflowNotFound {\n                message\n            }\n\n            ... on WorkflowCancelled {\n                message\n            }\n\n            ... on WorkflowNotReady {\n                message\n            }\n        }\n    }\n":
        types.PelangganRescheduleCaptureWorkflowVerifyConfirmCapturePaymentDocument,
    "\n    query CourtsiteRescheduleStateByIdSpay($workflowId: ID!) {\n        courtsiteRescheduleStateById(workflowId: $workflowId) {\n            id\n            completed\n            expired\n            cancelled\n            created\n            capturePaymentState {\n                id\n                created\n                cancelled\n                completed\n                expired\n                amountToCapture\n                spayQrCode\n                paymentSessionCreated\n            }\n        }\n    }\n":
        types.CourtsiteRescheduleStateByIdSpayDocument,
    "\n    mutation forceRetryCapturePaymentSPayReschedule(\n        $request: ForceRetryCapturePaymentRequest!\n    ) {\n        forceRetryCapturePayment(request: $request) {\n            __typename\n            ... on CapturePaymentForceRetried {\n                state {\n                    tenantId\n                    invoiceId\n                    state\n                    amountToCapture\n                    completed\n                    expired\n                    cancelled\n                    created\n                    ...SPayDataReschedule\n                }\n            }\n\n            ... on WorkflowNotFound {\n                message\n            }\n\n            ... on WorkflowNotReady {\n                message\n            }\n        }\n    }\n":
        types.ForceRetryCapturePaymentSPayRescheduleDocument,
    "\n    fragment SPayDataReschedule on CapturePaymentState {\n        id\n        spayQrCode\n        paymentSessionCreated\n    }\n":
        types.SPayDataRescheduleFragmentDoc,
    "\n    query pelangganRescheduleBookingInvoiceDetails(\n        $bookingIds: [ID!]!\n        $relatedEntityIds: [String]!\n    ) {\n        myBookingsByIds(bookingIds: $bookingIds) {\n            uid\n            startDt\n            endDt\n            created\n            confirmed\n            cancelled\n            metadata\n            resources {\n                uid\n                archived\n                resource {\n                    uid\n                    name\n                }\n            }\n            referenceId\n            tenant {\n                uid\n                name\n                metadata\n                location {\n                    latitude\n                    longitude\n                }\n            }\n            service {\n                uid\n                name\n                serviceMode\n                minRescheduleNotice\n                category {\n                    uid\n                    name\n                }\n            }\n        }\n        myInvoices(filter: { byRelatedEntityIds: $relatedEntityIds }) {\n            uid\n            associatedUsers {\n                userId\n            }\n            printNumber\n            outstandingBalance\n            metadata\n            cancelled\n            items {\n                uid\n                subtotal\n                description\n                perUnitPrice\n                quantity\n                created\n                relatedEntity {\n                    entityId\n                    entityType\n                }\n                metadata\n            }\n        }\n    }\n":
        types.PelangganRescheduleBookingInvoiceDetailsDocument,
    "\n    query pelangganSearchPage {\n        categories {\n            uid\n            name\n            ...FeatureVenueCategories\n            ...CategoryListCategories\n            ...SearchBarCategories\n        }\n        locations {\n            uid\n            name\n            city\n            coordinates {\n                latitude\n                longitude\n            }\n            ...SearchBarLocations\n        }\n        courtsitePartners {\n            uid\n            name\n            ...CategoryListVenues\n            ...SearchBarVenues\n        }\n    }\n":
        types.PelangganSearchPageDocument,
    "\n    query pelangganSearchResultPage {\n        categories {\n            ...SearchBarCategories\n            ...SearchMobileCategories\n        }\n        locations {\n            ...SearchBarLocations\n            ...SearchMobileLocations\n        }\n        courtsitePartners {\n            ...SearchBarVenues\n            ...SearchMobileVenues\n        }\n    }\n":
        types.PelangganSearchResultPageDocument,
    "\n    query pelangganShareInvoicePageData($tenantId: ID!) {\n        organisation(uid: $tenantId) {\n            uid\n            name\n            metadata\n            isCourtsitePartner\n            location {\n                latitude\n                longitude\n            }\n        }\n        categories {\n            uid\n            name\n            metadata\n        }\n        myProfile {\n            firebaseId\n            name\n            phoneNumber\n            email\n        }\n    }\n":
        types.PelangganShareInvoicePageDataDocument,
    "\n    query invoiceSharePage($tenantId: ID!, $uid: ID!) {\n        invoice(uid: $uid, tenantId: $tenantId) {\n            uid\n            associatedUsers {\n                userId\n            }\n            invoiceeName\n            metadata\n            outstandingBalance\n            cancelled\n            items {\n                uid\n                description\n                quantity\n                perUnitPrice\n                subtotal\n                metadata\n                relatedEntity {\n                    uid\n                    entityId\n                    entityType\n                }\n            }\n        }\n    }\n":
        types.InvoiceSharePageDocument,
    "\n    mutation AddBookingGuestToBookingsDetails(\n        $tenantId: ID!\n        $bookingIds: [ID!]!\n        $guestName: String!\n        $phoneNumber: PhoneNumber\n        $email: EmailAddress\n        $userId: ID!\n    ) {\n        addBookingGuestToBookings(\n            request: {\n                tenantId: $tenantId\n                bookingIds: $bookingIds\n                guestName: $guestName\n                phoneNumber: $phoneNumber\n                email: $email\n                userId: $userId\n            }\n        ) {\n            __typename\n            ... on BookingGuestAddedToBookings {\n                bookingIds\n            }\n            ... on AccessError {\n                message\n            }\n            ... on NotFoundError {\n                message\n            }\n        }\n    }\n":
        types.AddBookingGuestToBookingsDetailsDocument,
    "\n    mutation RemoveBookingGuestToBookingDetails(\n        $tenantId: ID!\n        $bookingIds: [ID!]!\n        $userId: ID!\n    ) {\n        removeBookingGuest(\n            request: {\n                tenantId: $tenantId\n                bookingIds: $bookingIds\n                userId: $userId\n            }\n        ) {\n            __typename\n            ... on BookingGuestRemoved {\n                bookingIds\n            }\n            ... on AccessError {\n                message\n            }\n            ... on NotFoundError {\n                message\n            }\n        }\n    }\n":
        types.RemoveBookingGuestToBookingDetailsDocument,
    "\n    query userBookingsCount {\n        myBookingFilterCount {\n            categoryId\n            bookingCount\n        }\n        categories {\n            uid\n            name\n        }\n    }\n":
        types.UserBookingsCountDocument,
    "\n    query pelangganUserBookingsDetailsNew($bookingIds: [ID!]!) {\n        myBookingsByIds(bookingIds: $bookingIds) {\n            uid\n            tenantId\n            referenceId\n            startDt\n            endDt\n            confirmed\n            cancelled\n            cancelledReason\n            metadata\n            bookingSource\n            service {\n                uid\n                serviceMode\n                minRescheduleNotice\n                category {\n                    uid\n                    name\n                }\n            }\n            resources {\n                uid\n                archived\n                resource {\n                    uid\n                    name\n                }\n            }\n            guests {\n                userId\n            }\n        }\n        myInvoicesByRelatedEntityIds(relatedEntityIds: $bookingIds) {\n            uid\n            tenantId\n            printNumber\n            total\n            outstandingBalance\n            cancelled\n            created\n        }\n    }\n":
        types.PelangganUserBookingsDetailsNewDocument,
    "\n    query pelangganUserBookingsDetailsOrg($tenantId: ID!) {\n        organisation(uid: $tenantId) {\n            uid\n            name\n            location {\n                latitude\n                longitude\n            }\n            metadata\n        }\n    }\n":
        types.PelangganUserBookingsDetailsOrgDocument,
    "\n    query pelangganUserBookingsDetailsWebCheckout($workflowId: ID!) {\n        courtsiteCheckoutStateById(checkoutId: $workflowId) {\n            id\n            cancelled\n            expired\n            created\n            state\n        }\n    }\n":
        types.PelangganUserBookingsDetailsWebCheckoutDocument,
    "\n    query pelangganUserBookingsDetailsWebCheckoutReschedule($workflowId: ID!) {\n        courtsiteRescheduleStateById(workflowId: $workflowId) {\n            id\n            created\n            cancelled\n            expired\n            state\n        }\n    }\n":
        types.PelangganUserBookingsDetailsWebCheckoutRescheduleDocument,
    "\n    query userGamesCount {\n        myGameFilterCount {\n            categoryId\n            gameCount\n        }\n        categories {\n            uid\n            name\n        }\n    }\n":
        types.UserGamesCountDocument,
    "\n    query getUserProfile($startOfYear: DateTime) {\n        myProfile {\n            firebaseId\n            name\n            phoneNumber\n            email\n            gender\n            dateOfBirth\n            postCode\n            joined\n            preferredSports\n            otherPreferredSports\n            sportsFrequency\n        }\n        categoryTags {\n            uid\n            name\n            categories {\n                uid\n                name\n            }\n        }\n        myInvoiceStatistics(filter: { byCreatedAfter: $startOfYear }) {\n            invoicePerYear\n        }\n        myGameStatistics {\n            gamesMade\n        }\n        myBookingStatistics {\n            bookingsHours\n            bookingsMade\n        }\n    }\n":
        types.GetUserProfileDocument,
    "\n    query getMyDetailsUpcomingBooking($now: DateTime) {\n        myGroupedBookings(\n            filter: {\n                byStatus: { confirmed: true, unconfirmed: true }\n                byStartDateAfter: $now\n            }\n            limit: 1\n            order: ASC\n        ) {\n            edges {\n                node {\n                    referenceId\n                    startDt\n                    bookings {\n                        uid\n                        created\n                        tenantId\n                        startDt\n                        endDt\n                        confirmed\n                        cancelled\n                        service {\n                            uid\n                            serviceMode\n                            categoryName\n                        }\n                        resources {\n                            resourceId\n                            resourceName\n                        }\n                        metadata\n                    }\n                }\n            }\n        }\n        myGames(filter: { startDateAfter: $now }, limit: 1, order: ASC) {\n            edges {\n                node {\n                    name\n                    location {\n                        tenantId\n                    }\n                    startDt\n                    endDt\n                    archived\n                    categoryId\n                    metadata\n                    uid\n                    players {\n                        displayPicture\n                    }\n                    maxPlayers\n                    reservedPlayers\n                    organizer {\n                        organizerId\n                    }\n                }\n            }\n        }\n    }\n":
        types.GetMyDetailsUpcomingBookingDocument,
    "\n    query getMyDetailsLastBooking {\n        myGroupedBookings(\n            filter: { byStatus: { confirmed: true, unconfirmed: true } }\n            limit: 1\n            order: DESC\n        ) {\n            edges {\n                node {\n                    referenceId\n                    startDt\n                    bookings {\n                        uid\n                        created\n                        tenantId\n                        startDt\n                        endDt\n                        confirmed\n                        cancelled\n                        service {\n                            uid\n                            serviceMode\n                            categoryName\n                        }\n                        resources {\n                            resourceId\n                            resourceName\n                        }\n                        metadata\n                    }\n                }\n            }\n        }\n    }\n":
        types.GetMyDetailsLastBookingDocument,
    "\n    query getMyDetailsLastGame {\n        myGames(limit: 1, order: DESC) {\n            edges {\n                node {\n                    name\n                    location {\n                        tenantId\n                    }\n                    startDt\n                    endDt\n                    archived\n                    categoryId\n                    metadata\n                    uid\n                    players {\n                        displayPicture\n                    }\n                    maxPlayers\n                    reservedPlayers\n                    organizer {\n                        organizerId\n                    }\n                }\n            }\n        }\n    }\n":
        types.GetMyDetailsLastGameDocument,
    "\n    query pelangganUserInvoice($invoiceId: ID!) {\n        myInvoice(invoiceId: $invoiceId) {\n            uid\n            total\n            outstandingBalance\n            amountPaid\n            cancelled\n            created\n            metadata\n            invoiceeName\n            tenantId\n            printNumber\n            isCourtsiteOwned\n            tenant {\n                uid\n                name\n                location {\n                    latitude\n                    longitude\n                }\n                metadata\n            }\n            items {\n                uid\n                description\n                perUnitPrice\n                quantity\n                subtotal\n                relatedEntity {\n                    uid\n                    entityId\n                    entityType\n                }\n                metadata\n                created\n            }\n            payments {\n                uid\n                created\n                cancelled\n                amount\n                paymentType\n                paymentMethod {\n                    uid\n                    name\n                }\n            }\n        }\n    }\n":
        types.PelangganUserInvoiceDocument,
    "\n    query centreDetailsAndCategories($tenantId: ID!) {\n        organisation(uid: $tenantId) {\n            uid\n            name\n            isCourtsitePartner\n            metadata\n            services\n        }\n        categories {\n            uid\n            name\n        }\n    }\n":
        types.CentreDetailsAndCategoriesDocument,
    "\n    query ValidateTaxpayerTin(\n        $tin: String!\n        $idType: IdType!\n        $idValue: String!\n    ) {\n        validateTaxpayerTin(tin: $tin, idType: $idType, idValue: $idValue) {\n            __typename\n            ... on TaxPayerTINValidated {\n                tin\n                idType\n                idValue\n            }\n            ... on AccessError {\n                message\n            }\n            ... on InternalError {\n                message\n            }\n        }\n    }\n":
        types.ValidateTaxpayerTinDocument,
    "\n    query invoiceLHDNRequest($invoiceId: ID!) {\n        myInvoice(invoiceId: $invoiceId) {\n            uid\n            total\n            outstandingBalance\n            payments {\n                uid\n                created\n                cancelled\n                paymentType\n                paymentMethod {\n                    uid\n                    name\n                }\n            }\n        }\n    }\n":
        types.InvoiceLhdnRequestDocument,
    "\n    query pelangganUserInvoices($status: InvoiceStatus, $cursor: String) {\n        myInvoicesPaged(\n            filter: { byStatus: $status }\n            limit: 10\n            after: $cursor\n            order: DESC\n        ) {\n            edges {\n                node {\n                    uid\n                    created\n                    ...UserInvoicesDetails\n                }\n            }\n            pageInfo {\n                hasNextPage\n                endCursor\n            }\n        }\n    }\n":
        types.PelangganUserInvoicesDocument,
    "\n    query pelangganUserInvoiceStatistics(\n        $startOfYear: DateTime\n        $pastStartOfYear: DateTime\n        $pastEndOfYear: DateTime\n    ) {\n        currentYear: myInvoiceStatistics(\n            filter: { byCreatedAfter: $startOfYear }\n        ) {\n            invoicePerYear\n        }\n        pastYear: myInvoiceStatistics(\n            filter: {\n                byCreatedAfter: $pastStartOfYear\n                byCreatedBefore: $pastEndOfYear\n            }\n        ) {\n            invoicePerYear\n        }\n    }\n":
        types.PelangganUserInvoiceStatisticsDocument,
    "\n    fragment UserInvoicesDetails on Invoice {\n        uid\n        outstandingBalance\n        total\n        created\n        tenant {\n            uid\n            name\n        }\n    }\n":
        types.UserInvoicesDetailsFragmentDoc,
    "\n    mutation pelangganUserProfileSetUserProfile(\n        $request: SetUserProfileRequest!\n    ) {\n        setUserProfile(request: $request) {\n            __typename\n            ... on UserProfileSet {\n                user {\n                    firebaseId\n                }\n            }\n            ... on AccessError {\n                message\n            }\n\n            ... on InvalidValue {\n                message\n            }\n        }\n    }\n":
        types.PelangganUserProfileSetUserProfileDocument,
    "\n    query pelangganUserProfile {\n        myProfile {\n            firebaseId\n            name\n            phoneNumber\n            email\n            gender\n            dateOfBirth\n            postCode\n            preferredSports\n            otherPreferredSports\n            sportsFrequency\n        }\n        categories {\n            uid\n            name\n        }\n    }\n":
        types.PelangganUserProfileDocument,
    "\n    query pelangganUserUpcomingCategories {\n        categories {\n            uid\n            name\n        }\n    }\n":
        types.PelangganUserUpcomingCategoriesDocument,
    "\n    query bookAgainSectionUpcomingQuery($limit: Int) {\n        tenantIdsFromPastBookings(limit: $limit)\n        courtsitePartners {\n            uid\n            name\n            metadata\n        }\n    }\n":
        types.BookAgainSectionUpcomingQueryDocument,
    "\n    query userUpcomingBookingsInvoices($filter: MyInvoicesFilter) {\n        myInvoices(filter: $filter) {\n            uid\n            items {\n                uid\n                relatedEntity {\n                    uid\n                    entityId\n                }\n            }\n        }\n    }\n":
        types.UserUpcomingBookingsInvoicesDocument,
    "\n    query userUpcomingBookingCount($startsAfter: DateTime) {\n        myBookingFilterCount(startsAfter: $startsAfter) {\n            bookingCount\n            categoryId\n        }\n    }\n":
        types.UserUpcomingBookingCountDocument,
    "\n    query userUpcomingGameCount($startsAfter: DateTime) {\n        myGameFilterCount(startsAfter: $startsAfter) {\n            gameCount\n            categoryId\n        }\n    }\n":
        types.UserUpcomingGameCountDocument,
};

/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 *
 *
 * @example
 * ```ts
 * const query = graphql(`query GetUser($id: ID!) { user(id: $id) { name } }`);
 * ```
 *
 * The query argument is unknown!
 * Please regenerate the types.
 */
export function graphql(source: string): unknown;

/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
    source: "\n    query unavailableSlotsService(\n        $categoryId: ID!\n        $dtRange: DateRangeFilter!\n        $tenantId: ID!\n        $isLoggedIn: Boolean!\n    ) {\n        unavailableResourceSlotsBetween(\n            categoryId: $categoryId\n            dtRange: $dtRange\n            tenantId: $tenantId\n        ) {\n            resourceId\n            startDt\n            endDt\n        }\n        onlineServices(\n            tenantId: $tenantId\n            filter: { byCategoryId: $categoryId }\n        ) {\n            uid\n            name\n            startTime\n            resources {\n                uid\n                archived\n                resourceId\n            }\n        }\n        serviceTags(tenantId: $tenantId) {\n            serviceId\n        }\n        myMemberServiceIds(tenantId: $tenantId) @include(if: $isLoggedIn)\n    }\n",
): (typeof documents)["\n    query unavailableSlotsService(\n        $categoryId: ID!\n        $dtRange: DateRangeFilter!\n        $tenantId: ID!\n        $isLoggedIn: Boolean!\n    ) {\n        unavailableResourceSlotsBetween(\n            categoryId: $categoryId\n            dtRange: $dtRange\n            tenantId: $tenantId\n        ) {\n            resourceId\n            startDt\n            endDt\n        }\n        onlineServices(\n            tenantId: $tenantId\n            filter: { byCategoryId: $categoryId }\n        ) {\n            uid\n            name\n            startTime\n            resources {\n                uid\n                archived\n                resourceId\n            }\n        }\n        serviceTags(tenantId: $tenantId) {\n            serviceId\n        }\n        myMemberServiceIds(tenantId: $tenantId) @include(if: $isLoggedIn)\n    }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
    source: "\n    query unavailableSlots(\n        $categoryId: ID!\n        $dtRange: DateRangeFilter!\n        $tenantId: ID!\n    ) {\n        unavailableResourceSlotsBetween(\n            categoryId: $categoryId\n            dtRange: $dtRange\n            tenantId: $tenantId\n        ) {\n            tenantId\n            resourceId\n            startDt\n            endDt\n        }\n        onlineServices(\n            tenantId: $tenantId\n            filter: { byCategoryId: $categoryId }\n        ) {\n            uid\n            resources {\n                uid\n                archived\n                resource {\n                    uid\n                    name\n                }\n            }\n        }\n    }\n",
): (typeof documents)["\n    query unavailableSlots(\n        $categoryId: ID!\n        $dtRange: DateRangeFilter!\n        $tenantId: ID!\n    ) {\n        unavailableResourceSlotsBetween(\n            categoryId: $categoryId\n            dtRange: $dtRange\n            tenantId: $tenantId\n        ) {\n            tenantId\n            resourceId\n            startDt\n            endDt\n        }\n        onlineServices(\n            tenantId: $tenantId\n            filter: { byCategoryId: $categoryId }\n        ) {\n            uid\n            resources {\n                uid\n                archived\n                resource {\n                    uid\n                    name\n                }\n            }\n        }\n    }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
    source: "\n    query pelangganAvailabilityView($tenantId: ID!, $isLoggedIn: Boolean!) {\n        categories {\n            uid\n            name\n        }\n        onlineServices(tenantId: $tenantId) {\n            uid\n            name\n            serviceMode\n            bookingWindow\n            metadata\n            resources {\n                uid\n                archived\n                resource {\n                    uid\n                    name\n                }\n            }\n            serviceCategory {\n                uid\n                categoryId\n            }\n        }\n        serviceTags(tenantId: $tenantId) {\n            serviceId\n        }\n        myMemberServiceIds(tenantId: $tenantId) @include(if: $isLoggedIn)\n    }\n",
): (typeof documents)["\n    query pelangganAvailabilityView($tenantId: ID!, $isLoggedIn: Boolean!) {\n        categories {\n            uid\n            name\n        }\n        onlineServices(tenantId: $tenantId) {\n            uid\n            name\n            serviceMode\n            bookingWindow\n            metadata\n            resources {\n                uid\n                archived\n                resource {\n                    uid\n                    name\n                }\n            }\n            serviceCategory {\n                uid\n                categoryId\n            }\n        }\n        serviceTags(tenantId: $tenantId) {\n            serviceId\n        }\n        myMemberServiceIds(tenantId: $tenantId) @include(if: $isLoggedIn)\n    }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
    source: "\n    query pelangganAvailabilityViewUnavailabilities(\n        $tenantId: ID!\n        $categoryId: ID!\n        $dtRange: DateRangeFilter!\n    ) {\n        unavailableResourceSlotsBetween(\n            categoryId: $categoryId\n            dtRange: $dtRange\n            tenantId: $tenantId\n        ) {\n            resourceId\n            startDt\n            endDt\n        }\n    }\n",
): (typeof documents)["\n    query pelangganAvailabilityViewUnavailabilities(\n        $tenantId: ID!\n        $categoryId: ID!\n        $dtRange: DateRangeFilter!\n    ) {\n        unavailableResourceSlotsBetween(\n            categoryId: $categoryId\n            dtRange: $dtRange\n            tenantId: $tenantId\n        ) {\n            resourceId\n            startDt\n            endDt\n        }\n    }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
    source: "\n    query pelangganAvailabilityViewDurationForm(\n        $req: [ServiceAvailabilityRequest!]!\n        $quotReq: [QuotationRequest!]!\n    ) {\n        serviceAvailabilities(request: $req) {\n            isAvailable\n        }\n        quotations(request: $quotReq) {\n            deposit\n            remainder\n            price\n            satisfiesMinimumDuration\n            timeFullyAccounted\n        }\n    }\n",
): (typeof documents)["\n    query pelangganAvailabilityViewDurationForm(\n        $req: [ServiceAvailabilityRequest!]!\n        $quotReq: [QuotationRequest!]!\n    ) {\n        serviceAvailabilities(request: $req) {\n            isAvailable\n        }\n        quotations(request: $quotReq) {\n            deposit\n            remainder\n            price\n            satisfiesMinimumDuration\n            timeFullyAccounted\n        }\n    }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
    source: "\n    query bookingCard($tenantId: ID!) {\n        organisation(uid: $tenantId) {\n            uid\n            name\n        }\n    }\n",
): (typeof documents)["\n    query bookingCard($tenantId: ID!) {\n        organisation(uid: $tenantId) {\n            uid\n            name\n        }\n    }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
    source: "\n    query reattemptBookingCard($tenantId: ID!) {\n        organisation(uid: $tenantId) {\n            uid\n            name\n        }\n    }\n",
): (typeof documents)["\n    query reattemptBookingCard($tenantId: ID!) {\n        organisation(uid: $tenantId) {\n            uid\n            name\n        }\n    }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
    source: "\n    mutation activateLightEvents($request: ActivateLightEventsRequest!) {\n        activateLightEvents(request: $request) {\n            __typename\n            ... on LightEventsActivated {\n                groupActivations {\n                    archived\n                    created\n                    groupId\n                    tenant {\n                        name\n                        uid\n                        metadata\n                        isCourtsitePartner\n                        services\n                    }\n                    tenantId\n                    uid\n                }\n            }\n            ... on AccessError {\n                message\n            }\n            ... on NotFoundError {\n                message\n            }\n        }\n    }\n",
): (typeof documents)["\n    mutation activateLightEvents($request: ActivateLightEventsRequest!) {\n        activateLightEvents(request: $request) {\n            __typename\n            ... on LightEventsActivated {\n                groupActivations {\n                    archived\n                    created\n                    groupId\n                    tenant {\n                        name\n                        uid\n                        metadata\n                        isCourtsitePartner\n                        services\n                    }\n                    tenantId\n                    uid\n                }\n            }\n            ... on AccessError {\n                message\n            }\n            ... on NotFoundError {\n                message\n            }\n        }\n    }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
    source: "\n    query pelangganUserBookingsCardWebCheckout($workflowId: ID!) {\n        courtsiteCheckoutStateById(checkoutId: $workflowId) {\n            created\n            hasDeposit\n            state\n        }\n    }\n",
): (typeof documents)["\n    query pelangganUserBookingsCardWebCheckout($workflowId: ID!) {\n        courtsiteCheckoutStateById(checkoutId: $workflowId) {\n            created\n            hasDeposit\n            state\n        }\n    }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
    source: "\n    query pelangganUserBookingsCardWebCheckoutReschedule($workflowId: ID!) {\n        courtsiteRescheduleStateById(workflowId: $workflowId) {\n            created\n            state\n        }\n    }\n",
): (typeof documents)["\n    query pelangganUserBookingsCardWebCheckoutReschedule($workflowId: ID!) {\n        courtsiteRescheduleStateById(workflowId: $workflowId) {\n            created\n            state\n        }\n    }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
    source: "\n    query bottomBarProfile {\n        myProfile {\n            firebaseId\n            name\n            phoneNumber\n            email\n        }\n    }\n",
): (typeof documents)["\n    query bottomBarProfile {\n        myProfile {\n            firebaseId\n            name\n            phoneNumber\n            email\n        }\n    }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
    source: "\n    mutation joinGame(\n        $gameID: ID!\n        $displayPicture: String\n        $email: EmailAddress\n        $name: String!\n        $phoneNumber: PhoneNumber\n    ) {\n        joinGame(\n            request: {\n                uid: $gameID\n                playerDetails: {\n                    displayPicture: $displayPicture\n                    email: $email\n                    name: $name\n                    phoneNumber: $phoneNumber\n                }\n            }\n        ) {\n            __typename\n            ... on AccessError {\n                message\n            }\n            ... on NoPlayerSlotError {\n                message\n            }\n            ... on NotFoundError {\n                message\n            }\n        }\n    }\n",
): (typeof documents)["\n    mutation joinGame(\n        $gameID: ID!\n        $displayPicture: String\n        $email: EmailAddress\n        $name: String!\n        $phoneNumber: PhoneNumber\n    ) {\n        joinGame(\n            request: {\n                uid: $gameID\n                playerDetails: {\n                    displayPicture: $displayPicture\n                    email: $email\n                    name: $name\n                    phoneNumber: $phoneNumber\n                }\n            }\n        ) {\n            __typename\n            ... on AccessError {\n                message\n            }\n            ... on NoPlayerSlotError {\n                message\n            }\n            ... on NotFoundError {\n                message\n            }\n        }\n    }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
    source: "\n    mutation leaveGame($gameID: ID!) {\n        leaveGame(request: { uid: $gameID }) {\n            __typename\n            ... on AccessError {\n                message\n            }\n            ... on NotFoundError {\n                message\n            }\n        }\n    }\n",
): (typeof documents)["\n    mutation leaveGame($gameID: ID!) {\n        leaveGame(request: { uid: $gameID }) {\n            __typename\n            ... on AccessError {\n                message\n            }\n            ... on NotFoundError {\n                message\n            }\n        }\n    }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
    source: "\n    fragment CategoryListCategories on Category {\n        uid\n        name\n    }\n",
): (typeof documents)["\n    fragment CategoryListCategories on Category {\n        uid\n        name\n    }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
    source: "\n    fragment CategoryListVenues on Organisation {\n        uid\n        services\n    }\n",
): (typeof documents)["\n    fragment CategoryListVenues on Organisation {\n        uid\n        services\n    }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
    source: "\n    query desktopNavProfile {\n        myProfile {\n            firebaseId\n            name\n            email\n        }\n    }\n",
): (typeof documents)["\n    query desktopNavProfile {\n        myProfile {\n            firebaseId\n            name\n            email\n        }\n    }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
    source: "\n    query downloadInvoice($invoiceId: ID!) {\n        myInvoice(invoiceId: $invoiceId) {\n            uid\n            invoiceeName\n            outstandingBalance\n            total\n            printNumber\n            created\n            isCourtsiteOwned\n            tenant {\n                uid\n                name\n                metadata\n            }\n            payments {\n                uid\n                paymentType\n                paymentMethod {\n                    uid\n                    name\n                }\n                created\n            }\n            items {\n                uid\n                description\n                quantity\n                subtotal\n                metadata\n                relatedEntity {\n                    uid\n                    entityId\n                    entityType\n                }\n            }\n        }\n    }\n",
): (typeof documents)["\n    query downloadInvoice($invoiceId: ID!) {\n        myInvoice(invoiceId: $invoiceId) {\n            uid\n            invoiceeName\n            outstandingBalance\n            total\n            printNumber\n            created\n            isCourtsiteOwned\n            tenant {\n                uid\n                name\n                metadata\n            }\n            payments {\n                uid\n                paymentType\n                paymentMethod {\n                    uid\n                    name\n                }\n                created\n            }\n            items {\n                uid\n                description\n                quantity\n                subtotal\n                metadata\n                relatedEntity {\n                    uid\n                    entityId\n                    entityType\n                }\n            }\n        }\n    }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
    source: "\n    query downloadInvoiceBookings($bookingIds: [ID!]!) {\n        myBookingsByIds(bookingIds: $bookingIds) {\n            uid\n            startDt\n            endDt\n            service {\n                uid\n                name\n                serviceMode\n            }\n            resources {\n                uid\n                archived\n                resource {\n                    uid\n                    name\n                }\n            }\n        }\n    }\n",
): (typeof documents)["\n    query downloadInvoiceBookings($bookingIds: [ID!]!) {\n        myBookingsByIds(bookingIds: $bookingIds) {\n            uid\n            startDt\n            endDt\n            service {\n                uid\n                name\n                serviceMode\n            }\n            resources {\n                uid\n                archived\n                resource {\n                    uid\n                    name\n                }\n            }\n        }\n    }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
    source: "\n    fragment FeatureVenueCategories on Category {\n        uid\n        name\n    }\n",
): (typeof documents)["\n    fragment FeatureVenueCategories on Category {\n        uid\n        name\n    }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
    source: "\n    query featureVenueOrganisations(\n        $filter: ApprovedOrganisationsFilter\n        $cursor: String\n    ) {\n        approvedOrganisations(\n            sortOrder: BY_WEIGHTAGE_DESC\n            limit: 6\n            after: $cursor\n            filter: $filter\n        ) {\n            edges {\n                node {\n                    uid\n                    name\n                    metadata\n                    services\n                }\n            }\n        }\n    }\n",
): (typeof documents)["\n    query featureVenueOrganisations(\n        $filter: ApprovedOrganisationsFilter\n        $cursor: String\n    ) {\n        approvedOrganisations(\n            sortOrder: BY_WEIGHTAGE_DESC\n            limit: 6\n            after: $cursor\n            filter: $filter\n        ) {\n            edges {\n                node {\n                    uid\n                    name\n                    metadata\n                    services\n                }\n            }\n        }\n    }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
    source: "\n    mutation createUserProfile(\n        $name: String!\n        $email: EmailAddress\n        $phoneNumber: PhoneNumber\n    ) {\n        createUserProfile(\n            request: { name: $name, email: $email, phoneNumber: $phoneNumber }\n        ) {\n            __typename\n            ... on AccessError {\n                message\n            }\n            ... on InvalidValue {\n                message\n            }\n        }\n    }\n",
): (typeof documents)["\n    mutation createUserProfile(\n        $name: String!\n        $email: EmailAddress\n        $phoneNumber: PhoneNumber\n    ) {\n        createUserProfile(\n            request: { name: $name, email: $email, phoneNumber: $phoneNumber }\n        ) {\n            __typename\n            ... on AccessError {\n                message\n            }\n            ... on InvalidValue {\n                message\n            }\n        }\n    }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
    source: "\n    query addOnSelect($tenantId: ID!) {\n        addOns(tenantId: $tenantId) {\n            uid\n            name\n            price\n            isInStock\n        }\n    }\n",
): (typeof documents)["\n    query addOnSelect($tenantId: ID!) {\n        addOns(tenantId: $tenantId) {\n            uid\n            name\n            price\n            isInStock\n        }\n    }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
    source: "\n    query bookingCategorySelect($tenantId: ID!) {\n        onlineServices(tenantId: $tenantId) {\n            uid\n            name\n            minBookingWindowMinutes\n            bookingWindow\n            disallowBookingGap\n            metadata\n            serviceMode\n            category {\n                uid\n                name\n                metadata\n            }\n            resources {\n                uid\n                resourceId\n                archived\n            }\n            customDurationOptions\n        }\n        addOns(tenantId: $tenantId) {\n            uid\n            name\n            price\n            isInStock\n        }\n        myMemberServiceIds(tenantId: $tenantId)\n        serviceTags(tenantId: $tenantId) {\n            serviceId\n        }\n    }\n",
): (typeof documents)["\n    query bookingCategorySelect($tenantId: ID!) {\n        onlineServices(tenantId: $tenantId) {\n            uid\n            name\n            minBookingWindowMinutes\n            bookingWindow\n            disallowBookingGap\n            metadata\n            serviceMode\n            category {\n                uid\n                name\n                metadata\n            }\n            resources {\n                uid\n                resourceId\n                archived\n            }\n            customDurationOptions\n        }\n        addOns(tenantId: $tenantId) {\n            uid\n            name\n            price\n            isInStock\n        }\n        myMemberServiceIds(tenantId: $tenantId)\n        serviceTags(tenantId: $tenantId) {\n            serviceId\n        }\n    }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
    source: "\n    query pelangganBookingFormOnlineServices($tenantId: ID!, $categoryId: ID!) {\n        onlineServices(\n            tenantId: $tenantId\n            filter: { byCategoryId: $categoryId }\n        ) {\n            uid\n            name\n            serviceMode\n            category {\n                uid\n            }\n            resources {\n                uid\n                archived\n                resource {\n                    uid\n                    name\n                    linkedResources {\n                        uid\n                    }\n                }\n            }\n            minBookingWindowMinutes\n            bookingWindow\n            disallowBookingGap\n            customDurationOptions\n            startTime\n            endTime\n        }\n        organisation(uid: $tenantId) {\n            uid\n            metadata\n        }\n    }\n",
): (typeof documents)["\n    query pelangganBookingFormOnlineServices($tenantId: ID!, $categoryId: ID!) {\n        onlineServices(\n            tenantId: $tenantId\n            filter: { byCategoryId: $categoryId }\n        ) {\n            uid\n            name\n            serviceMode\n            category {\n                uid\n            }\n            resources {\n                uid\n                archived\n                resource {\n                    uid\n                    name\n                    linkedResources {\n                        uid\n                    }\n                }\n            }\n            minBookingWindowMinutes\n            bookingWindow\n            disallowBookingGap\n            customDurationOptions\n            startTime\n            endTime\n        }\n        organisation(uid: $tenantId) {\n            uid\n            metadata\n        }\n    }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
    source: "\n    query bookingSelectStartDurationSelect(\n        $tenantId: ID!\n        $serviceIds: [ID!]!\n        $startDt: DateTime!\n        $endDt: DateTime!\n        $categoryId: ID!\n    ) {\n        onlineServices(tenantId: $tenantId) {\n            uid\n            minBookingWindowMinutes\n            disallowBookingGap\n            metadata\n            resources {\n                uid\n                archived\n                resourceId\n            }\n            customDurationOptions\n        }\n        fixedDurationOptionsStartingBetween(\n            tenantId: $tenantId\n            serviceIds: $serviceIds\n            start: $startDt\n            end: $endDt\n        ) {\n            serviceId\n            options {\n                start\n                end\n            }\n        }\n        unavailableResourceSlotsBetween(\n            categoryId: $categoryId\n            dtRange: { startDt: $startDt, endDt: $endDt }\n            tenantId: $tenantId\n        ) {\n            endDt\n            resourceId\n            startDt\n        }\n    }\n",
): (typeof documents)["\n    query bookingSelectStartDurationSelect(\n        $tenantId: ID!\n        $serviceIds: [ID!]!\n        $startDt: DateTime!\n        $endDt: DateTime!\n        $categoryId: ID!\n    ) {\n        onlineServices(tenantId: $tenantId) {\n            uid\n            minBookingWindowMinutes\n            disallowBookingGap\n            metadata\n            resources {\n                uid\n                archived\n                resourceId\n            }\n            customDurationOptions\n        }\n        fixedDurationOptionsStartingBetween(\n            tenantId: $tenantId\n            serviceIds: $serviceIds\n            start: $startDt\n            end: $endDt\n        ) {\n            serviceId\n            options {\n                start\n                end\n            }\n        }\n        unavailableResourceSlotsBetween(\n            categoryId: $categoryId\n            dtRange: { startDt: $startDt, endDt: $endDt }\n            tenantId: $tenantId\n        ) {\n            endDt\n            resourceId\n            startDt\n        }\n    }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
    source: "\n    query cartCentreDetails($tenantId: ID!) {\n        organisation(uid: $tenantId) {\n            uid\n            name\n            services\n            metadata\n        }\n        categories {\n            uid\n            name\n        }\n    }\n",
): (typeof documents)["\n    query cartCentreDetails($tenantId: ID!) {\n        organisation(uid: $tenantId) {\n            uid\n            name\n            services\n            metadata\n        }\n        categories {\n            uid\n            name\n        }\n    }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
    source: "\n    query checkoutDiscount($items: [CheckoutDiscountItem!]!, $promocode: ID!) {\n        checkoutDiscount(items: $items, promocodeId: $promocode) {\n            discountedAmount\n        }\n    }\n",
): (typeof documents)["\n    query checkoutDiscount($items: [CheckoutDiscountItem!]!, $promocode: ID!) {\n        checkoutDiscount(items: $items, promocodeId: $promocode) {\n            discountedAmount\n        }\n    }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
    source: "\n    query dailyBookingSteps($tenantId: ID!) {\n        onlineServices(tenantId: $tenantId) {\n            uid\n            name\n            serviceMode\n            category {\n                uid\n            }\n            resources {\n                uid\n                archived\n                resource {\n                    uid\n                    name\n                    linkedResources {\n                        uid\n                    }\n                }\n            }\n            minBookingWindowMinutes\n            bookingWindow\n            disallowBookingGap\n            startTime\n            endTime\n        }\n    }\n",
): (typeof documents)["\n    query dailyBookingSteps($tenantId: ID!) {\n        onlineServices(tenantId: $tenantId) {\n            uid\n            name\n            serviceMode\n            category {\n                uid\n            }\n            resources {\n                uid\n                archived\n                resource {\n                    uid\n                    name\n                    linkedResources {\n                        uid\n                    }\n                }\n            }\n            minBookingWindowMinutes\n            bookingWindow\n            disallowBookingGap\n            startTime\n            endTime\n        }\n    }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
    source: "\n    query bookingSelectAvailability(\n        $availabilitiesRequest: [ServiceAvailabilityRequest!]!\n        $quotationsRequest: [QuotationRequest!]!\n        $tenantId: ID!\n        $startDt: DateTime!\n        $endDt: DateTime!\n        $categoryId: ID!\n    ) {\n        serviceAvailabilities(request: $availabilitiesRequest) {\n            availableSelectableResourceIds\n            serviceId\n        }\n        quotations(request: $quotationsRequest) {\n            deposit\n            price\n            quotationFor\n            remainder\n            timeFullyAccounted\n        }\n        unavailableResourceSlotsBetween(\n            categoryId: $categoryId\n            dtRange: { startDt: $startDt, endDt: $endDt }\n            tenantId: $tenantId\n        ) {\n            endDt\n            resourceId\n            startDt\n        }\n        onlineServices(tenantId: $tenantId) {\n            uid\n            category {\n                uid\n            }\n        }\n    }\n",
): (typeof documents)["\n    query bookingSelectAvailability(\n        $availabilitiesRequest: [ServiceAvailabilityRequest!]!\n        $quotationsRequest: [QuotationRequest!]!\n        $tenantId: ID!\n        $startDt: DateTime!\n        $endDt: DateTime!\n        $categoryId: ID!\n    ) {\n        serviceAvailabilities(request: $availabilitiesRequest) {\n            availableSelectableResourceIds\n            serviceId\n        }\n        quotations(request: $quotationsRequest) {\n            deposit\n            price\n            quotationFor\n            remainder\n            timeFullyAccounted\n        }\n        unavailableResourceSlotsBetween(\n            categoryId: $categoryId\n            dtRange: { startDt: $startDt, endDt: $endDt }\n            tenantId: $tenantId\n        ) {\n            endDt\n            resourceId\n            startDt\n        }\n        onlineServices(tenantId: $tenantId) {\n            uid\n            category {\n                uid\n            }\n        }\n    }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
    source: "\n    query pelangganGameFormGameCreateFromInvoiceForm(\n        $tenantId: ID!\n        $uid: ID!\n    ) {\n        invoice(uid: $uid, tenantId: $tenantId) {\n            uid\n            items {\n                uid\n                relatedEntity {\n                    uid\n                    entityId\n                    entityType\n                }\n            }\n        }\n        organisation(uid: $tenantId) {\n            uid\n            name\n            metadata\n        }\n    }\n",
): (typeof documents)["\n    query pelangganGameFormGameCreateFromInvoiceForm(\n        $tenantId: ID!\n        $uid: ID!\n    ) {\n        invoice(uid: $uid, tenantId: $tenantId) {\n            uid\n            items {\n                uid\n                relatedEntity {\n                    uid\n                    entityId\n                    entityType\n                }\n            }\n        }\n        organisation(uid: $tenantId) {\n            uid\n            name\n            metadata\n        }\n    }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
    source: "\n    query pelangganGameFormDateTimeForm($tenantId: ID!, $uid: ID!) {\n        invoice(uid: $uid, tenantId: $tenantId) {\n            uid\n            items {\n                uid\n                relatedEntity {\n                    uid\n                    entityId\n                    entityType\n                }\n            }\n        }\n        organisation(uid: $tenantId) {\n            uid\n            name\n            metadata\n        }\n    }\n",
): (typeof documents)["\n    query pelangganGameFormDateTimeForm($tenantId: ID!, $uid: ID!) {\n        invoice(uid: $uid, tenantId: $tenantId) {\n            uid\n            items {\n                uid\n                relatedEntity {\n                    uid\n                    entityId\n                    entityType\n                }\n            }\n        }\n        organisation(uid: $tenantId) {\n            uid\n            name\n            metadata\n        }\n    }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
    source: "\n    query pelangganGameFormDateTimeFormBookings($tenantId: ID!, $uids: [ID!]!) {\n        bookingsByIds(tenantId: $tenantId, bookingIds: $uids) {\n            uid\n            startDt\n            endDt\n        }\n    }\n",
): (typeof documents)["\n    query pelangganGameFormDateTimeFormBookings($tenantId: ID!, $uids: [ID!]!) {\n        bookingsByIds(tenantId: $tenantId, bookingIds: $uids) {\n            uid\n            startDt\n            endDt\n        }\n    }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
    source: "\n    mutation pelangganUserDataUseUpdateUserProfile(\n        $request: SetUserProfileRequest!\n    ) {\n        setUserProfile(request: $request) {\n            __typename\n            ... on UserProfileSet {\n                user {\n                    firebaseId\n                    name\n                    email\n                    phoneNumber\n                }\n            }\n            ... on AccessError {\n                message\n            }\n\n            ... on InvalidValue {\n                message\n            }\n        }\n    }\n",
): (typeof documents)["\n    mutation pelangganUserDataUseUpdateUserProfile(\n        $request: SetUserProfileRequest!\n    ) {\n        setUserProfile(request: $request) {\n            __typename\n            ... on UserProfileSet {\n                user {\n                    firebaseId\n                    name\n                    email\n                    phoneNumber\n                }\n            }\n            ... on AccessError {\n                message\n            }\n\n            ... on InvalidValue {\n                message\n            }\n        }\n    }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
    source: "\n    query userDataCollectionForm {\n        myProfile {\n            firebaseId\n            name\n            email\n            phoneNumber\n            gender\n            dateOfBirth\n            postCode\n            preferredSports\n            otherPreferredSports\n            sportsFrequency\n        }\n        categories {\n            uid\n            name\n        }\n    }\n",
): (typeof documents)["\n    query userDataCollectionForm {\n        myProfile {\n            firebaseId\n            name\n            email\n            phoneNumber\n            gender\n            dateOfBirth\n            postCode\n            preferredSports\n            otherPreferredSports\n            sportsFrequency\n        }\n        categories {\n            uid\n            name\n        }\n    }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
    source: "\n    query pendingPayments($startAfter: DateTime) {\n        myInvoices(filter: { byStatus: { unpaid: true } }) {\n            uid\n            created\n            items {\n                uid\n                metadata\n                relatedEntity {\n                    uid\n                    entityType\n                    entityId\n                }\n            }\n        }\n        myBookings(\n            limit: 30\n            filter: {\n                byStatus: { unconfirmed: true }\n                byStartDateAfter: $startAfter\n            }\n        ) {\n            edges {\n                node {\n                    uid\n                    tenantId\n                    referenceId\n                    created\n                    startDt\n                    endDt\n                    confirmed\n                    cancelled\n                    metadata\n                    resources {\n                        uid\n                        resource {\n                            uid\n                            name\n                            archived\n                        }\n                    }\n                    service {\n                        uid\n                        serviceMode\n                        category {\n                            uid\n                            name\n                        }\n                    }\n                }\n            }\n        }\n    }\n",
): (typeof documents)["\n    query pendingPayments($startAfter: DateTime) {\n        myInvoices(filter: { byStatus: { unpaid: true } }) {\n            uid\n            created\n            items {\n                uid\n                metadata\n                relatedEntity {\n                    uid\n                    entityType\n                    entityId\n                }\n            }\n        }\n        myBookings(\n            limit: 30\n            filter: {\n                byStatus: { unconfirmed: true }\n                byStartDateAfter: $startAfter\n            }\n        ) {\n            edges {\n                node {\n                    uid\n                    tenantId\n                    referenceId\n                    created\n                    startDt\n                    endDt\n                    confirmed\n                    cancelled\n                    metadata\n                    resources {\n                        uid\n                        resource {\n                            uid\n                            name\n                            archived\n                        }\n                    }\n                    service {\n                        uid\n                        serviceMode\n                        category {\n                            uid\n                            name\n                        }\n                    }\n                }\n            }\n        }\n    }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
    source: "\n    query homepageUpcomingCardWebCheckout($workflowId: ID!) {\n        courtsiteCheckoutStateById(checkoutId: $workflowId) {\n            id\n            cancelled\n            expired\n            created\n            hasDeposit\n            state\n        }\n    }\n",
): (typeof documents)["\n    query homepageUpcomingCardWebCheckout($workflowId: ID!) {\n        courtsiteCheckoutStateById(checkoutId: $workflowId) {\n            id\n            cancelled\n            expired\n            created\n            hasDeposit\n            state\n        }\n    }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
    source: "\n    query homepageUpcomingCardWebCheckoutReschedule($workflowId: ID!) {\n        courtsiteRescheduleStateById(workflowId: $workflowId) {\n            id\n            cancelled\n            expired\n            created\n            state\n        }\n    }\n",
): (typeof documents)["\n    query homepageUpcomingCardWebCheckoutReschedule($workflowId: ID!) {\n        courtsiteRescheduleStateById(workflowId: $workflowId) {\n            id\n            cancelled\n            expired\n            created\n            state\n        }\n    }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
    source: "\n    fragment LocationAccordionOption on SearchLocation {\n        uid\n        name\n        coordinates {\n            latitude\n            longitude\n        }\n        city\n    }\n",
): (typeof documents)["\n    fragment LocationAccordionOption on SearchLocation {\n        uid\n        name\n        coordinates {\n            latitude\n            longitude\n        }\n        city\n    }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
    source: "\n    mutation pelangganUserDetailsModalSetUserProfile(\n        $request: SetUserProfileRequest!\n    ) {\n        setUserProfile(request: $request) {\n            __typename\n            ... on UserProfileSet {\n                user {\n                    firebaseId\n                }\n            }\n\n            ... on AccessError {\n                message\n            }\n\n            ... on InvalidValue {\n                message\n            }\n        }\n    }\n",
): (typeof documents)["\n    mutation pelangganUserDetailsModalSetUserProfile(\n        $request: SetUserProfileRequest!\n    ) {\n        setUserProfile(request: $request) {\n            __typename\n            ... on UserProfileSet {\n                user {\n                    firebaseId\n                }\n            }\n\n            ... on AccessError {\n                message\n            }\n\n            ... on InvalidValue {\n                message\n            }\n        }\n    }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
    source: "\n    query navigationMenuProfile {\n        myProfile {\n            firebaseId\n            name\n            phoneNumber\n            email\n        }\n    }\n",
): (typeof documents)["\n    query navigationMenuProfile {\n        myProfile {\n            firebaseId\n            name\n            phoneNumber\n            email\n        }\n    }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
    source: "\n    query confirmCheckoutAvailabilities(\n        $serviceAvailabilitiesRequest: [ServiceAvailabilityRequest!]!\n    ) {\n        serviceAvailabilities(request: $serviceAvailabilitiesRequest) {\n            isAvailable\n        }\n    }\n",
): (typeof documents)["\n    query confirmCheckoutAvailabilities(\n        $serviceAvailabilitiesRequest: [ServiceAvailabilityRequest!]!\n    ) {\n        serviceAvailabilities(request: $serviceAvailabilitiesRequest) {\n            isAvailable\n        }\n    }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
    source: "\n    mutation startRescheduleCheckout(\n        $tenantId: ID!\n        $bookingId: ID!\n        $newSlot: CourtsiteBookingSlotInput!\n    ) {\n        startCourtsiteReschedule(\n            request: {\n                tenantId: $tenantId\n                bookingId: $bookingId\n                newSlot: $newSlot\n            }\n        ) {\n            __typename\n            ... on CourtsiteRescheduleStarted {\n                state {\n                    id\n                    runId\n                    state\n                    completed\n                    expired\n                    cancelled\n                    created\n                    priceDifference\n                    newBookingId\n                    newInvoiceId\n                    redirectUrl\n                    capturePaymentWorkflowId\n                    capturePaymentState {\n                        id\n                        tenantId\n                        invoiceId\n                        state\n                        amountToCapture\n                        paymentSessionId\n                        completed\n                        expired\n                        cancelled\n                        created\n                    }\n                }\n            }\n\n            ... on CourtsiteRescheduleBookingsUnavailable {\n                message\n            }\n\n            ... on WorkflowCancelled {\n                message\n            }\n\n            ... on WorkflowNotReady {\n                message\n            }\n        }\n    }\n",
): (typeof documents)["\n    mutation startRescheduleCheckout(\n        $tenantId: ID!\n        $bookingId: ID!\n        $newSlot: CourtsiteBookingSlotInput!\n    ) {\n        startCourtsiteReschedule(\n            request: {\n                tenantId: $tenantId\n                bookingId: $bookingId\n                newSlot: $newSlot\n            }\n        ) {\n            __typename\n            ... on CourtsiteRescheduleStarted {\n                state {\n                    id\n                    runId\n                    state\n                    completed\n                    expired\n                    cancelled\n                    created\n                    priceDifference\n                    newBookingId\n                    newInvoiceId\n                    redirectUrl\n                    capturePaymentWorkflowId\n                    capturePaymentState {\n                        id\n                        tenantId\n                        invoiceId\n                        state\n                        amountToCapture\n                        paymentSessionId\n                        completed\n                        expired\n                        cancelled\n                        created\n                    }\n                }\n            }\n\n            ... on CourtsiteRescheduleBookingsUnavailable {\n                message\n            }\n\n            ... on WorkflowCancelled {\n                message\n            }\n\n            ... on WorkflowNotReady {\n                message\n            }\n        }\n    }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
    source: "\n    query searchCentreCardQuery {\n        categories {\n            uid\n            name\n        }\n    }\n",
): (typeof documents)["\n    query searchCentreCardQuery {\n        categories {\n            uid\n            name\n        }\n    }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
    source: "\n    fragment SearchBarCategories on Category {\n        uid\n        name\n        ...CategoryListCategories\n    }\n",
): (typeof documents)["\n    fragment SearchBarCategories on Category {\n        uid\n        name\n        ...CategoryListCategories\n    }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
    source: "\n    fragment SearchBarLocations on SearchLocation {\n        uid\n        name\n        city\n        coordinates {\n            latitude\n            longitude\n        }\n    }\n",
): (typeof documents)["\n    fragment SearchBarLocations on SearchLocation {\n        uid\n        name\n        city\n        coordinates {\n            latitude\n            longitude\n        }\n    }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
    source: "\n    fragment SearchBarVenues on Organisation {\n        uid\n        name\n        ...CategoryListVenues\n    }\n",
): (typeof documents)["\n    fragment SearchBarVenues on Organisation {\n        uid\n        name\n        ...CategoryListVenues\n    }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
    source: "\n    fragment SearchMobileCategories on Category {\n        uid\n        name\n        ...CategoryListCategories\n    }\n",
): (typeof documents)["\n    fragment SearchMobileCategories on Category {\n        uid\n        name\n        ...CategoryListCategories\n    }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
    source: "\n    fragment SearchMobileLocations on SearchLocation {\n        uid\n        name\n        city\n        coordinates {\n            latitude\n            longitude\n        }\n    }\n",
): (typeof documents)["\n    fragment SearchMobileLocations on SearchLocation {\n        uid\n        name\n        city\n        coordinates {\n            latitude\n            longitude\n        }\n    }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
    source: "\n    fragment SearchMobileVenues on Organisation {\n        uid\n        name\n        ...CategoryListVenues\n    }\n",
): (typeof documents)["\n    fragment SearchMobileVenues on Organisation {\n        uid\n        name\n        ...CategoryListVenues\n    }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
    source: "\n    query pelangganSearchResults($request: CourtsiteSearchRequest!) {\n        courtsiteSearch(request: $request) {\n            uid\n            name\n            services\n            metadata\n        }\n    }\n",
): (typeof documents)["\n    query pelangganSearchResults($request: CourtsiteSearchRequest!) {\n        courtsiteSearch(request: $request) {\n            uid\n            name\n            services\n            metadata\n        }\n    }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
    source: "\n    query pelangganSearchResultLocation {\n        locations {\n            uid\n            name\n            coordinates {\n                latitude\n                longitude\n            }\n            city\n        }\n    }\n",
): (typeof documents)["\n    query pelangganSearchResultLocation {\n        locations {\n            uid\n            name\n            coordinates {\n                latitude\n                longitude\n            }\n            city\n        }\n    }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
    source: "\n    query pelangganSearchResultService($categoryId: ID!) {\n        onlineServicesByCategory(categoryId: $categoryId) {\n            uid\n            tenantId\n        }\n    }\n",
): (typeof documents)["\n    query pelangganSearchResultService($categoryId: ID!) {\n        onlineServicesByCategory(categoryId: $categoryId) {\n            uid\n            tenantId\n        }\n    }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
    source: "\n    query pelangganCentreSearchUseAvailabilities(\n        $request: [ServiceAvailabilityRequest!]!\n        $quotationsRequest: [QuotationRequest!]!\n    ) {\n        serviceAvailabilities(request: $request) {\n            isAvailable\n            tenantId\n            serviceId\n        }\n        quotations(request: $quotationsRequest) {\n            quotationFor\n            timeFullyAccounted\n        }\n    }\n",
): (typeof documents)["\n    query pelangganCentreSearchUseAvailabilities(\n        $request: [ServiceAvailabilityRequest!]!\n        $quotationsRequest: [QuotationRequest!]!\n    ) {\n        serviceAvailabilities(request: $request) {\n            isAvailable\n            tenantId\n            serviceId\n        }\n        quotations(request: $quotationsRequest) {\n            quotationFor\n            timeFullyAccounted\n        }\n    }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
    source: "\n    query viewLiveAvailability($tenantId: ID!) {\n        onlineServices(tenantId: $tenantId) {\n            uid\n            name\n            minBookingWindowMinutes\n            bookingWindow\n            disallowBookingGap\n            metadata\n            serviceMode\n            category {\n                uid\n                name\n                metadata\n            }\n            resources {\n                uid\n                resourceId\n                archived\n            }\n            customDurationOptions\n        }\n        addOns(tenantId: $tenantId) {\n            uid\n            name\n            price\n            isInStock\n        }\n        myMemberServiceIds(tenantId: $tenantId)\n        serviceTags(tenantId: $tenantId) {\n            serviceId\n        }\n    }\n",
): (typeof documents)["\n    query viewLiveAvailability($tenantId: ID!) {\n        onlineServices(tenantId: $tenantId) {\n            uid\n            name\n            minBookingWindowMinutes\n            bookingWindow\n            disallowBookingGap\n            metadata\n            serviceMode\n            category {\n                uid\n                name\n                metadata\n            }\n            resources {\n                uid\n                resourceId\n                archived\n            }\n            customDurationOptions\n        }\n        addOns(tenantId: $tenantId) {\n            uid\n            name\n            price\n            isInStock\n        }\n        myMemberServiceIds(tenantId: $tenantId)\n        serviceTags(tenantId: $tenantId) {\n            serviceId\n        }\n    }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
    source: "\n    query pelangganUseBookingsUnconfirmed(\n        $after: String\n        $filter: MyBookingsFilter\n        $limit: Int\n    ) {\n        myBookings(after: $after, filter: $filter, limit: $limit) {\n            edges {\n                node {\n                    uid\n                    created\n                    tenantId\n                    referenceId\n                    startDt\n                    endDt\n                    confirmed\n                    cancelled\n                    service {\n                        category {\n                            uid\n                        }\n                    }\n                    resources {\n                        resource {\n                            uid\n                            name\n                        }\n                        archived\n                    }\n                    metadata\n                }\n            }\n            pageInfo {\n                startCursor\n                endCursor\n                hasNextPage\n            }\n        }\n    }\n",
): (typeof documents)["\n    query pelangganUseBookingsUnconfirmed(\n        $after: String\n        $filter: MyBookingsFilter\n        $limit: Int\n    ) {\n        myBookings(after: $after, filter: $filter, limit: $limit) {\n            edges {\n                node {\n                    uid\n                    created\n                    tenantId\n                    referenceId\n                    startDt\n                    endDt\n                    confirmed\n                    cancelled\n                    service {\n                        category {\n                            uid\n                        }\n                    }\n                    resources {\n                        resource {\n                            uid\n                            name\n                        }\n                        archived\n                    }\n                    metadata\n                }\n            }\n            pageInfo {\n                startCursor\n                endCursor\n                hasNextPage\n            }\n        }\n    }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
    source: "\n    query pelangganUseBookings($tenantId: ID!, $bookingIds: [ID!]!) {\n        bookingsByIds(tenantId: $tenantId, bookingIds: $bookingIds) {\n            uid\n            startDt\n            endDt\n            metadata\n            cancelled\n            service {\n                uid\n                name\n                serviceMode\n                minRescheduleNotice\n                serviceCategory {\n                    uid\n                    categoryId\n                }\n            }\n            guests {\n                uid\n                userId\n            }\n            resources {\n                uid\n                archived\n                resource {\n                    uid\n                    name\n                }\n            }\n            referenceId\n        }\n    }\n",
): (typeof documents)["\n    query pelangganUseBookings($tenantId: ID!, $bookingIds: [ID!]!) {\n        bookingsByIds(tenantId: $tenantId, bookingIds: $bookingIds) {\n            uid\n            startDt\n            endDt\n            metadata\n            cancelled\n            service {\n                uid\n                name\n                serviceMode\n                minRescheduleNotice\n                serviceCategory {\n                    uid\n                    categoryId\n                }\n            }\n            guests {\n                uid\n                userId\n            }\n            resources {\n                uid\n                archived\n                resource {\n                    uid\n                    name\n                }\n            }\n            referenceId\n        }\n    }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
    source: "\n    query centreByUid($uid: ID!, $isLoggedIn: Boolean!) {\n        organisation(uid: $uid) {\n            uid\n            name\n            location {\n                latitude\n                longitude\n            }\n            approved\n            metadata\n            services\n            isCourtsitePartner\n        }\n        onlineServices(tenantId: $uid) {\n            uid\n            name\n            serviceCategory {\n                uid\n                categoryId\n            }\n            resources {\n                uid\n                created\n                resourceId\n                optionKey\n                archived\n                resource {\n                    name\n                }\n            }\n            metadata\n            bookingWindow\n            minBookingWindowMinutes\n            disallowBookingGap\n            serviceMode\n            startTime\n            endTime\n            customDurationOptions\n        }\n        myMemberServiceIds(tenantId: $uid) @include(if: $isLoggedIn)\n        serviceTags(tenantId: $uid) {\n            serviceId\n        }\n        categories {\n            uid\n            name\n            metadata\n        }\n        addOns(tenantId: $uid) {\n            uid\n            name\n            price\n            isInStock\n        }\n    }\n",
): (typeof documents)["\n    query centreByUid($uid: ID!, $isLoggedIn: Boolean!) {\n        organisation(uid: $uid) {\n            uid\n            name\n            location {\n                latitude\n                longitude\n            }\n            approved\n            metadata\n            services\n            isCourtsitePartner\n        }\n        onlineServices(tenantId: $uid) {\n            uid\n            name\n            serviceCategory {\n                uid\n                categoryId\n            }\n            resources {\n                uid\n                created\n                resourceId\n                optionKey\n                archived\n                resource {\n                    name\n                }\n            }\n            metadata\n            bookingWindow\n            minBookingWindowMinutes\n            disallowBookingGap\n            serviceMode\n            startTime\n            endTime\n            customDurationOptions\n        }\n        myMemberServiceIds(tenantId: $uid) @include(if: $isLoggedIn)\n        serviceTags(tenantId: $uid) {\n            serviceId\n        }\n        categories {\n            uid\n            name\n            metadata\n        }\n        addOns(tenantId: $uid) {\n            uid\n            name\n            price\n            isInStock\n        }\n    }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
    source: "\n    query courtsiteCheckoutStateById($checkoutId: ID!) {\n        courtsiteCheckoutStateById(checkoutId: $checkoutId) {\n            id\n            runId\n            tenantId\n            userId\n            state\n            completed\n            expired\n            cancelled\n            created\n            referenceId\n            bookingIds\n            invoiceIds\n            promocode\n            discountedAmount\n            addOns {\n                addOnId\n                tenantId\n                name\n                price\n                claimDt\n                quantity\n            }\n            capturePaymentState {\n                id\n                tenantId\n                state\n                paymentSessionId\n                completed\n                expired\n                cancelled\n                created\n                ...PaymentHandlerData\n            }\n        }\n    }\n",
): (typeof documents)["\n    query courtsiteCheckoutStateById($checkoutId: ID!) {\n        courtsiteCheckoutStateById(checkoutId: $checkoutId) {\n            id\n            runId\n            tenantId\n            userId\n            state\n            completed\n            expired\n            cancelled\n            created\n            referenceId\n            bookingIds\n            invoiceIds\n            promocode\n            discountedAmount\n            addOns {\n                addOnId\n                tenantId\n                name\n                price\n                claimDt\n                quantity\n            }\n            capturePaymentState {\n                id\n                tenantId\n                state\n                paymentSessionId\n                completed\n                expired\n                cancelled\n                created\n                ...PaymentHandlerData\n            }\n        }\n    }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
    source: "\n    query courtsiteCheckoutWorkflowBookings(\n        $tenantId: ID!\n        $bookingIds: [ID!]!\n    ) {\n        bookingsByIds(tenantId: $tenantId, bookingIds: $bookingIds) {\n            uid\n            startDt\n            endDt\n            referenceId\n            metadata\n            cancelled\n            confirmed\n            service {\n                uid\n                name\n                serviceMode\n                minRescheduleNotice\n                serviceCategory {\n                    uid\n                    categoryId\n                }\n            }\n            resources {\n                uid\n                archived\n                resource {\n                    uid\n                    name\n                }\n            }\n        }\n    }\n",
): (typeof documents)["\n    query courtsiteCheckoutWorkflowBookings(\n        $tenantId: ID!\n        $bookingIds: [ID!]!\n    ) {\n        bookingsByIds(tenantId: $tenantId, bookingIds: $bookingIds) {\n            uid\n            startDt\n            endDt\n            referenceId\n            metadata\n            cancelled\n            confirmed\n            service {\n                uid\n                name\n                serviceMode\n                minRescheduleNotice\n                serviceCategory {\n                    uid\n                    categoryId\n                }\n            }\n            resources {\n                uid\n                archived\n                resource {\n                    uid\n                    name\n                }\n            }\n        }\n    }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
    source: "\n    query fixedDurationOptionsStartingBetween(\n        $tenantId: ID!\n        $serviceIds: [ID!]!\n        $start: DateTime!\n        $end: DateTime!\n    ) {\n        fixedDurationOptionsStartingBetween(\n            tenantId: $tenantId\n            serviceIds: $serviceIds\n            start: $start\n            end: $end\n        ) {\n            serviceId\n            options {\n                start\n                end\n            }\n        }\n    }\n",
): (typeof documents)["\n    query fixedDurationOptionsStartingBetween(\n        $tenantId: ID!\n        $serviceIds: [ID!]!\n        $start: DateTime!\n        $end: DateTime!\n    ) {\n        fixedDurationOptionsStartingBetween(\n            tenantId: $tenantId\n            serviceIds: $serviceIds\n            start: $start\n            end: $end\n        ) {\n            serviceId\n            options {\n                start\n                end\n            }\n        }\n    }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
    source: "\n    query getGame($gameId: ID!) {\n        game(uid: $gameId) {\n            categoryId\n            description\n            endDt\n            location {\n                tenant {\n                    uid\n                    name\n                    metadata\n                }\n            }\n            maxPlayers\n            metadata\n            name\n            organizer {\n                displayPicture\n                name\n                organizerId\n            }\n            players {\n                displayPicture\n                name\n                playerId\n            }\n            startDt\n            uid\n            isPrivate\n            reservedPlayers\n        }\n        categories {\n            uid\n            name\n        }\n    }\n",
): (typeof documents)["\n    query getGame($gameId: ID!) {\n        game(uid: $gameId) {\n            categoryId\n            description\n            endDt\n            location {\n                tenant {\n                    uid\n                    name\n                    metadata\n                }\n            }\n            maxPlayers\n            metadata\n            name\n            organizer {\n                displayPicture\n                name\n                organizerId\n            }\n            players {\n                displayPicture\n                name\n                playerId\n            }\n            startDt\n            uid\n            isPrivate\n            reservedPlayers\n        }\n        categories {\n            uid\n            name\n        }\n    }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
    source: '\n    mutation startCheckout(\n        $tenantId: ID!\n        $slots: [CourtsiteCheckoutSlotInput!]!\n        $name: String!\n        $phoneNumber: String\n        $email: String!\n        $addOns: [CourtsiteCheckoutAddOnInput!]\n        $promocode: String\n    ) {\n        startCourtsiteCheckout(\n            request: {\n                tenantId: $tenantId\n                slots: $slots\n                guest: { name: $name, phoneNumber: $phoneNumber, email: $email }\n                metadata: "{}"\n                addOns: $addOns\n                promocode: $promocode\n            }\n        ) {\n            __typename\n\n            ... on CourtsiteCheckoutStarted {\n                state {\n                    id\n                    runId\n                    state\n                }\n            }\n\n            ... on CourtsiteCheckoutBookingsUnavailable {\n                message\n            }\n\n            ... on WorkflowCancelled {\n                message\n            }\n\n            ... on WorkflowNotReady {\n                message\n            }\n        }\n    }\n',
): (typeof documents)['\n    mutation startCheckout(\n        $tenantId: ID!\n        $slots: [CourtsiteCheckoutSlotInput!]!\n        $name: String!\n        $phoneNumber: String\n        $email: String!\n        $addOns: [CourtsiteCheckoutAddOnInput!]\n        $promocode: String\n    ) {\n        startCourtsiteCheckout(\n            request: {\n                tenantId: $tenantId\n                slots: $slots\n                guest: { name: $name, phoneNumber: $phoneNumber, email: $email }\n                metadata: "{}"\n                addOns: $addOns\n                promocode: $promocode\n            }\n        ) {\n            __typename\n\n            ... on CourtsiteCheckoutStarted {\n                state {\n                    id\n                    runId\n                    state\n                }\n            }\n\n            ... on CourtsiteCheckoutBookingsUnavailable {\n                message\n            }\n\n            ... on WorkflowCancelled {\n                message\n            }\n\n            ... on WorkflowNotReady {\n                message\n            }\n        }\n    }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
    source: "\n    query publicGames($filter: PublicGamesFilter, $page: PageInput) {\n        publicGames(filter: $filter, page: $page) {\n            edges {\n                node {\n                    uid\n                    name\n                    categoryId\n                    startDt\n                    endDt\n                    maxPlayers\n                    location {\n                        tenant {\n                            uid\n                            name\n                        }\n                    }\n                    organizer {\n                        name\n                    }\n                    players {\n                        displayPicture\n                    }\n                    metadata\n                    reservedPlayers\n                }\n            }\n            pageInfo {\n                startCursor\n                endCursor\n                hasNextPage\n            }\n        }\n        categories {\n            uid\n            name\n        }\n    }\n",
): (typeof documents)["\n    query publicGames($filter: PublicGamesFilter, $page: PageInput) {\n        publicGames(filter: $filter, page: $page) {\n            edges {\n                node {\n                    uid\n                    name\n                    categoryId\n                    startDt\n                    endDt\n                    maxPlayers\n                    location {\n                        tenant {\n                            uid\n                            name\n                        }\n                    }\n                    organizer {\n                        name\n                    }\n                    players {\n                        displayPicture\n                    }\n                    metadata\n                    reservedPlayers\n                }\n            }\n            pageInfo {\n                startCursor\n                endCursor\n                hasNextPage\n            }\n        }\n        categories {\n            uid\n            name\n        }\n    }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
    source: "\n    fragment bookingFields on Booking {\n        uid\n        startDt\n        endDt\n        referenceId\n        tenantId\n        metadata\n        service {\n            uid\n            name\n            serviceMode\n            minRescheduleNotice\n            category {\n                uid\n                name\n                metadata\n            }\n        }\n        resources {\n            uid\n            archived\n            resource {\n                uid\n                name\n            }\n        }\n    }\n",
): (typeof documents)["\n    fragment bookingFields on Booking {\n        uid\n        startDt\n        endDt\n        referenceId\n        tenantId\n        metadata\n        service {\n            uid\n            name\n            serviceMode\n            minRescheduleNotice\n            category {\n                uid\n                name\n                metadata\n            }\n        }\n        resources {\n            uid\n            archived\n            resource {\n                uid\n                name\n            }\n        }\n    }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
    source: "\n    query CourtsiteRescheduleStateById($workflowId: ID!) {\n        courtsiteRescheduleStateById(workflowId: $workflowId) {\n            id\n            runId\n            state\n            completed\n            expired\n            cancelled\n            created\n            priceDifference\n            oldBookingId\n            newBookingId\n            newInvoiceId\n            redirectUrl\n            capturePaymentWorkflowId\n            capturePaymentState {\n                id\n                runId\n                tenantId\n                state\n                amountToCapture\n                paymentSessionId\n                completed\n                expired\n                cancelled\n                created\n                isCancellable\n                attempts\n                ...PaymentHandlerData\n            }\n        }\n    }\n",
): (typeof documents)["\n    query CourtsiteRescheduleStateById($workflowId: ID!) {\n        courtsiteRescheduleStateById(workflowId: $workflowId) {\n            id\n            runId\n            state\n            completed\n            expired\n            cancelled\n            created\n            priceDifference\n            oldBookingId\n            newBookingId\n            newInvoiceId\n            redirectUrl\n            capturePaymentWorkflowId\n            capturePaymentState {\n                id\n                runId\n                tenantId\n                state\n                amountToCapture\n                paymentSessionId\n                completed\n                expired\n                cancelled\n                created\n                isCancellable\n                attempts\n                ...PaymentHandlerData\n            }\n        }\n    }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
    source: "\n    query pelangganUseRescheduleCheckoutWorkflowBookings(\n        $oldBookingId: ID!\n        $newBookingId: ID!\n    ) {\n        oldBooking: myBooking(bookingId: $oldBookingId) {\n            ...bookingFields\n        }\n        newBooking: myBooking(bookingId: $newBookingId) {\n            ...bookingFields\n        }\n    }\n",
): (typeof documents)["\n    query pelangganUseRescheduleCheckoutWorkflowBookings(\n        $oldBookingId: ID!\n        $newBookingId: ID!\n    ) {\n        oldBooking: myBooking(bookingId: $oldBookingId) {\n            ...bookingFields\n        }\n        newBooking: myBooking(bookingId: $newBookingId) {\n            ...bookingFields\n        }\n    }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
    source: "\n    query getUserBookings(\n        $after: String\n        $filter: MyBookingsFilter\n        $limit: Int\n        $order: SortOrderEnum\n    ) {\n        myBookings(\n            after: $after\n            filter: $filter\n            limit: $limit\n            order: $order\n        ) {\n            edges {\n                node {\n                    uid\n                    created\n                    tenantId\n                    referenceId\n                    startDt\n                    endDt\n                    confirmed\n                    cancelled\n                    service {\n                        uid\n                        serviceMode\n                        category {\n                            uid\n                            name\n                        }\n                    }\n                    resources {\n                        uid\n                        archived\n                        resource {\n                            uid\n                            name\n                        }\n                    }\n                    metadata\n                }\n            }\n            pageInfo {\n                startCursor\n                endCursor\n                hasNextPage\n            }\n        }\n    }\n",
): (typeof documents)["\n    query getUserBookings(\n        $after: String\n        $filter: MyBookingsFilter\n        $limit: Int\n        $order: SortOrderEnum\n    ) {\n        myBookings(\n            after: $after\n            filter: $filter\n            limit: $limit\n            order: $order\n        ) {\n            edges {\n                node {\n                    uid\n                    created\n                    tenantId\n                    referenceId\n                    startDt\n                    endDt\n                    confirmed\n                    cancelled\n                    service {\n                        uid\n                        serviceMode\n                        category {\n                            uid\n                            name\n                        }\n                    }\n                    resources {\n                        uid\n                        archived\n                        resource {\n                            uid\n                            name\n                        }\n                    }\n                    metadata\n                }\n            }\n            pageInfo {\n                startCursor\n                endCursor\n                hasNextPage\n            }\n        }\n    }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
    source: "\n    query userGames(\n        $after: String\n        $filter: GameFilter\n        $limit: Int\n        $order: SortOrderEnum\n    ) {\n        myGames(after: $after, filter: $filter, limit: $limit, order: $order) {\n            edges {\n                node {\n                    name\n                    location {\n                        tenantId\n                    }\n                    startDt\n                    endDt\n                    archived\n                    categoryId\n                    metadata\n                    uid\n                    players {\n                        displayPicture\n                    }\n                    maxPlayers\n                    reservedPlayers\n                    organizer {\n                        organizerId\n                    }\n                }\n            }\n            pageInfo {\n                startCursor\n                endCursor\n                hasNextPage\n            }\n        }\n    }\n",
): (typeof documents)["\n    query userGames(\n        $after: String\n        $filter: GameFilter\n        $limit: Int\n        $order: SortOrderEnum\n    ) {\n        myGames(after: $after, filter: $filter, limit: $limit, order: $order) {\n            edges {\n                node {\n                    name\n                    location {\n                        tenantId\n                    }\n                    startDt\n                    endDt\n                    archived\n                    categoryId\n                    metadata\n                    uid\n                    players {\n                        displayPicture\n                    }\n                    maxPlayers\n                    reservedPlayers\n                    organizer {\n                        organizerId\n                    }\n                }\n            }\n            pageInfo {\n                startCursor\n                endCursor\n                hasNextPage\n            }\n        }\n    }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
    source: "\n    query getUserGroupedBookings(\n        $filter: MyGroupedBookingsFilter\n        $after: String\n        $limit: Int\n        $order: SortOrderEnum\n    ) {\n        myGroupedBookings(\n            filter: $filter\n            after: $after\n            limit: $limit\n            order: $order\n        ) {\n            edges {\n                node {\n                    referenceId\n                    startDt\n                    endDt\n                    bookings {\n                        uid\n                        created\n                        tenantId\n                        referenceId\n                        startDt\n                        endDt\n                        confirmed\n                        cancelled\n                        service {\n                            uid\n                            serviceMode\n                            categoryId\n                            categoryName\n                        }\n                        resources {\n                            uid\n                            resourceId\n                            resourceName\n                        }\n                        metadata\n                    }\n                }\n            }\n            pageInfo {\n                startCursor\n                endCursor\n                hasNextPage\n            }\n        }\n    }\n",
): (typeof documents)["\n    query getUserGroupedBookings(\n        $filter: MyGroupedBookingsFilter\n        $after: String\n        $limit: Int\n        $order: SortOrderEnum\n    ) {\n        myGroupedBookings(\n            filter: $filter\n            after: $after\n            limit: $limit\n            order: $order\n        ) {\n            edges {\n                node {\n                    referenceId\n                    startDt\n                    endDt\n                    bookings {\n                        uid\n                        created\n                        tenantId\n                        referenceId\n                        startDt\n                        endDt\n                        confirmed\n                        cancelled\n                        service {\n                            uid\n                            serviceMode\n                            categoryId\n                            categoryName\n                        }\n                        resources {\n                            uid\n                            resourceId\n                            resourceName\n                        }\n                        metadata\n                    }\n                }\n            }\n            pageInfo {\n                startCursor\n                endCursor\n                hasNextPage\n            }\n        }\n    }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
    source: "\n    query myProfile {\n        myProfile {\n            firebaseId\n            name\n            phoneNumber\n            email\n            gender\n            dateOfBirth\n            postCode\n            preferredSports\n            otherPreferredSports\n            sportsFrequency\n        }\n    }\n",
): (typeof documents)["\n    query myProfile {\n        myProfile {\n            firebaseId\n            name\n            phoneNumber\n            email\n            gender\n            dateOfBirth\n            postCode\n            preferredSports\n            otherPreferredSports\n            sportsFrequency\n        }\n    }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
    source: "\n    fragment PaymentHandlerData on CapturePaymentState {\n        invoiceId\n        billplzPaymentUrl\n        ipay88BackendUrl\n        ipay88ResponseUrl\n        ipay88RequestSignature\n        razorpayOrderId\n        razorpayResponseUrl\n        kiplepayReferenceNo\n        kiplepayHashValue\n        kiplepayCallbackUrl\n        kiplepayReturnUrl\n        kiplepayMerchantId\n        spayOrderNo\n        spayReturnUrl\n        spayCallbackUrl\n        spayEncryptedPayload\n        spayQrCode\n    }\n",
): (typeof documents)["\n    fragment PaymentHandlerData on CapturePaymentState {\n        invoiceId\n        billplzPaymentUrl\n        ipay88BackendUrl\n        ipay88ResponseUrl\n        ipay88RequestSignature\n        razorpayOrderId\n        razorpayResponseUrl\n        kiplepayReferenceNo\n        kiplepayHashValue\n        kiplepayCallbackUrl\n        kiplepayReturnUrl\n        kiplepayMerchantId\n        spayOrderNo\n        spayReturnUrl\n        spayCallbackUrl\n        spayEncryptedPayload\n        spayQrCode\n    }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
    source: "\n    query pelangganCentreActivitiesCategories(\n        $startsAfter: DateTime!\n        $tenantId: ID!\n    ) {\n        publicGameCount(startsAfter: $startsAfter) {\n            categoryId\n            gameCount\n            locationTenantIds\n        }\n        categories {\n            uid\n            name\n        }\n        organisation(uid: $tenantId) {\n            uid\n            name\n            services\n            metadata\n            isCourtsitePartner\n            location {\n                longitude\n                latitude\n            }\n        }\n    }\n",
): (typeof documents)["\n    query pelangganCentreActivitiesCategories(\n        $startsAfter: DateTime!\n        $tenantId: ID!\n    ) {\n        publicGameCount(startsAfter: $startsAfter) {\n            categoryId\n            gameCount\n            locationTenantIds\n        }\n        categories {\n            uid\n            name\n        }\n        organisation(uid: $tenantId) {\n            uid\n            name\n            services\n            metadata\n            isCourtsitePartner\n            location {\n                longitude\n                latitude\n            }\n        }\n    }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
    source: "\n    query pelangganCentre($tenantId: ID!, $isLoggedIn: Boolean!) {\n        organisation(uid: $tenantId) {\n            uid\n            name\n            services\n            metadata\n            isCourtsitePartner\n            location {\n                longitude\n                latitude\n            }\n        }\n        onlineServices(tenantId: $tenantId) {\n            uid\n            name\n            serviceMode\n            serviceCategory {\n                uid\n                categoryId\n            }\n            resources {\n                uid\n                resourceId\n                archived\n            }\n            metadata\n        }\n        categories {\n            uid\n            name\n            metadata\n        }\n        serviceTags(tenantId: $tenantId) {\n            serviceId\n        }\n        myMemberServiceIds(tenantId: $tenantId) @include(if: $isLoggedIn)\n    }\n",
): (typeof documents)["\n    query pelangganCentre($tenantId: ID!, $isLoggedIn: Boolean!) {\n        organisation(uid: $tenantId) {\n            uid\n            name\n            services\n            metadata\n            isCourtsitePartner\n            location {\n                longitude\n                latitude\n            }\n        }\n        onlineServices(tenantId: $tenantId) {\n            uid\n            name\n            serviceMode\n            serviceCategory {\n                uid\n                categoryId\n            }\n            resources {\n                uid\n                resourceId\n                archived\n            }\n            metadata\n        }\n        categories {\n            uid\n            name\n            metadata\n        }\n        serviceTags(tenantId: $tenantId) {\n            serviceId\n        }\n        myMemberServiceIds(tenantId: $tenantId) @include(if: $isLoggedIn)\n    }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
    source: "\n    query pelangganCentreNearby($req: CourtsiteSearchRequest!) {\n        courtsiteSearch(request: $req) {\n            uid\n            ...VenueCardOrg\n        }\n    }\n",
): (typeof documents)["\n    query pelangganCentreNearby($req: CourtsiteSearchRequest!) {\n        courtsiteSearch(request: $req) {\n            uid\n            ...VenueCardOrg\n        }\n    }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
    source: "\n    fragment VenueCardOrg on Organisation {\n        uid\n        name\n        services\n        metadata\n    }\n",
): (typeof documents)["\n    fragment VenueCardOrg on Organisation {\n        uid\n        name\n        services\n        metadata\n    }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
    source: "\n    query pelangganCentreCategories {\n        categories {\n            uid\n            name\n            metadata\n        }\n    }\n",
): (typeof documents)["\n    query pelangganCentreCategories {\n        categories {\n            uid\n            name\n            metadata\n        }\n    }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
    source: "\n    query centreBookingSelect($tenantId: ID!) {\n        organisation(uid: $tenantId) {\n            uid\n        }\n        onlineServices(tenantId: $tenantId) {\n            uid\n            name\n            minBookingWindowMinutes\n            bookingWindow\n            disallowBookingGap\n            metadata\n            serviceMode\n            category {\n                uid\n                name\n                metadata\n            }\n            customDurationOptions\n        }\n        addOns(tenantId: $tenantId) {\n            uid\n            name\n            price\n            isInStock\n        }\n        myMemberServiceIds(tenantId: $tenantId)\n        serviceTags(tenantId: $tenantId) {\n            serviceId\n        }\n    }\n",
): (typeof documents)["\n    query centreBookingSelect($tenantId: ID!) {\n        organisation(uid: $tenantId) {\n            uid\n        }\n        onlineServices(tenantId: $tenantId) {\n            uid\n            name\n            minBookingWindowMinutes\n            bookingWindow\n            disallowBookingGap\n            metadata\n            serviceMode\n            category {\n                uid\n                name\n                metadata\n            }\n            customDurationOptions\n        }\n        addOns(tenantId: $tenantId) {\n            uid\n            name\n            price\n            isInStock\n        }\n        myMemberServiceIds(tenantId: $tenantId)\n        serviceTags(tenantId: $tenantId) {\n            serviceId\n        }\n    }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
    source: "\n    query pelangganCheckoutWorkflowPayment($workflowID: ID!) {\n        courtsiteCheckoutStateById(checkoutId: $workflowID) {\n            id\n            created\n            expired\n            completed\n            cancelled\n            tenantId\n            bookingIds\n            promocode\n            discountedAmount\n            addOns {\n                addOnId\n                name\n                price\n                claimDt\n                quantity\n            }\n            capturePaymentState {\n                id\n                state\n                completed\n                expired\n                cancelled\n                ...PaymentHandlerData\n            }\n        }\n    }\n",
): (typeof documents)["\n    query pelangganCheckoutWorkflowPayment($workflowID: ID!) {\n        courtsiteCheckoutStateById(checkoutId: $workflowID) {\n            id\n            created\n            expired\n            completed\n            cancelled\n            tenantId\n            bookingIds\n            promocode\n            discountedAmount\n            addOns {\n                addOnId\n                name\n                price\n                claimDt\n                quantity\n            }\n            capturePaymentState {\n                id\n                state\n                completed\n                expired\n                cancelled\n                ...PaymentHandlerData\n            }\n        }\n    }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
    source: "\n    query courtsiteCheckoutWorkflowPaymentBookings(\n        $tenantId: ID!\n        $bookingIds: [ID!]!\n    ) {\n        bookingsByIds(tenantId: $tenantId, bookingIds: $bookingIds) {\n            uid\n            startDt\n            endDt\n            referenceId\n            metadata\n            cancelled\n            confirmed\n            service {\n                uid\n                name\n                serviceMode\n                minRescheduleNotice\n                serviceCategory {\n                    uid\n                    categoryId\n                }\n            }\n            resources {\n                uid\n                archived\n                resource {\n                    uid\n                    name\n                }\n            }\n        }\n        organisation(uid: $tenantId) {\n            uid\n            name\n            metadata\n            isCourtsitePartner\n        }\n        categories {\n            uid\n            name\n            metadata\n        }\n        onlineServices(tenantId: $tenantId) {\n            uid\n            serviceCategory {\n                uid\n                categoryId\n            }\n        }\n    }\n",
): (typeof documents)["\n    query courtsiteCheckoutWorkflowPaymentBookings(\n        $tenantId: ID!\n        $bookingIds: [ID!]!\n    ) {\n        bookingsByIds(tenantId: $tenantId, bookingIds: $bookingIds) {\n            uid\n            startDt\n            endDt\n            referenceId\n            metadata\n            cancelled\n            confirmed\n            service {\n                uid\n                name\n                serviceMode\n                minRescheduleNotice\n                serviceCategory {\n                    uid\n                    categoryId\n                }\n            }\n            resources {\n                uid\n                archived\n                resource {\n                    uid\n                    name\n                }\n            }\n        }\n        organisation(uid: $tenantId) {\n            uid\n            name\n            metadata\n            isCourtsitePartner\n        }\n        categories {\n            uid\n            name\n            metadata\n        }\n        onlineServices(tenantId: $tenantId) {\n            uid\n            serviceCategory {\n                uid\n                categoryId\n            }\n        }\n    }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
    source: "\n    mutation provideCourtsiteCheckoutPaymentDetails(\n        $checkoutId: ID!\n        $name: String!\n        $email: String!\n        $phoneNumber: String!\n        $paymentProvider: PaymentProvider!\n        $redirectUrl: String\n    ) {\n        provideCourtsiteCheckoutPaymentDetails(\n            request: {\n                checkoutId: $checkoutId\n                name: $name\n                email: $email\n                phoneNumber: $phoneNumber\n                paymentProvider: $paymentProvider\n                redirectUrl: $redirectUrl\n            }\n        ) {\n            __typename\n\n            ... on CourtsiteCheckoutPaymentDetailsProvided {\n                state {\n                    id\n                    runId\n                    state\n                    capturePaymentState {\n                        id\n                        amountToCapture\n                        ...PaymentHandlerData\n                    }\n                }\n            }\n\n            ... on WorkflowNotFound {\n                message\n            }\n\n            ... on CourtsiteCheckoutExpired {\n                message\n            }\n\n            ... on WorkflowCancelled {\n                message\n            }\n\n            ... on WorkflowNotReady {\n                message\n            }\n        }\n    }\n",
): (typeof documents)["\n    mutation provideCourtsiteCheckoutPaymentDetails(\n        $checkoutId: ID!\n        $name: String!\n        $email: String!\n        $phoneNumber: String!\n        $paymentProvider: PaymentProvider!\n        $redirectUrl: String\n    ) {\n        provideCourtsiteCheckoutPaymentDetails(\n            request: {\n                checkoutId: $checkoutId\n                name: $name\n                email: $email\n                phoneNumber: $phoneNumber\n                paymentProvider: $paymentProvider\n                redirectUrl: $redirectUrl\n            }\n        ) {\n            __typename\n\n            ... on CourtsiteCheckoutPaymentDetailsProvided {\n                state {\n                    id\n                    runId\n                    state\n                    capturePaymentState {\n                        id\n                        amountToCapture\n                        ...PaymentHandlerData\n                    }\n                }\n            }\n\n            ... on WorkflowNotFound {\n                message\n            }\n\n            ... on CourtsiteCheckoutExpired {\n                message\n            }\n\n            ... on WorkflowCancelled {\n                message\n            }\n\n            ... on WorkflowNotReady {\n                message\n            }\n        }\n    }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
    source: "\n    mutation forceRetryCapturePayment(\n        $request: ForceRetryCapturePaymentRequest!\n    ) {\n        forceRetryCapturePayment(request: $request) {\n            __typename\n            ... on CapturePaymentForceRetried {\n                state {\n                    id\n                    amountToCapture\n                    ...PaymentHandlerData\n                }\n            }\n\n            ... on WorkflowNotFound {\n                message\n            }\n\n            ... on WorkflowNotReady {\n                message\n            }\n        }\n    }\n",
): (typeof documents)["\n    mutation forceRetryCapturePayment(\n        $request: ForceRetryCapturePaymentRequest!\n    ) {\n        forceRetryCapturePayment(request: $request) {\n            __typename\n            ... on CapturePaymentForceRetried {\n                state {\n                    id\n                    amountToCapture\n                    ...PaymentHandlerData\n                }\n            }\n\n            ... on WorkflowNotFound {\n                message\n            }\n\n            ... on WorkflowNotReady {\n                message\n            }\n        }\n    }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
    source: "\n    query checkoutResultCourtsiteCheckoutStateById($workflowID: ID!) {\n        courtsiteCheckoutStateById(checkoutId: $workflowID) {\n            id\n            runId\n            tenantId\n            completed\n            expired\n            cancelled\n            created\n            referenceId\n            bookingIds\n            invoiceIds\n            capturePaymentState {\n                id\n                completed\n                expired\n                cancelled\n                state\n            }\n        }\n    }\n",
): (typeof documents)["\n    query checkoutResultCourtsiteCheckoutStateById($workflowID: ID!) {\n        courtsiteCheckoutStateById(checkoutId: $workflowID) {\n            id\n            runId\n            tenantId\n            completed\n            expired\n            cancelled\n            created\n            referenceId\n            bookingIds\n            invoiceIds\n            capturePaymentState {\n                id\n                completed\n                expired\n                cancelled\n                state\n            }\n        }\n    }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
    source: "\n    query checkoutResultBookingsCentre(\n        $tenantId: ID!\n        $bookingIds: [ID!]!\n        $invoiceIds: [String]\n        $showInvoice: Boolean!\n    ) {\n        bookingsByIds(tenantId: $tenantId, bookingIds: $bookingIds) {\n            uid\n            startDt\n            endDt\n            referenceId\n            metadata\n            cancelled\n            confirmed\n            service {\n                uid\n                name\n                serviceMode\n                minRescheduleNotice\n                serviceCategory {\n                    uid\n                    categoryId\n                }\n            }\n            resources {\n                uid\n                archived\n                resource {\n                    uid\n                    name\n                }\n            }\n        }\n        organisation(uid: $tenantId) {\n            uid\n            name\n            location {\n                latitude\n                longitude\n            }\n            metadata\n        }\n        myInvoices(filter: { byInvoiceIds: $invoiceIds })\n            @include(if: $showInvoice) {\n            uid\n            total\n            outstandingBalance\n            cancelled\n            items {\n                uid\n                created\n                description\n                perUnitPrice\n                quantity\n                subtotal\n                metadata\n                relatedEntity {\n                    uid\n                    entityId\n                    entityType\n                }\n            }\n        }\n        groupIdEventsActivationStatus(\n            groupIds: $bookingIds\n            tenantId: $tenantId\n        ) {\n            groupId\n            isActivated\n            requiresUserActivation\n            tenantId\n        }\n    }\n",
): (typeof documents)["\n    query checkoutResultBookingsCentre(\n        $tenantId: ID!\n        $bookingIds: [ID!]!\n        $invoiceIds: [String]\n        $showInvoice: Boolean!\n    ) {\n        bookingsByIds(tenantId: $tenantId, bookingIds: $bookingIds) {\n            uid\n            startDt\n            endDt\n            referenceId\n            metadata\n            cancelled\n            confirmed\n            service {\n                uid\n                name\n                serviceMode\n                minRescheduleNotice\n                serviceCategory {\n                    uid\n                    categoryId\n                }\n            }\n            resources {\n                uid\n                archived\n                resource {\n                    uid\n                    name\n                }\n            }\n        }\n        organisation(uid: $tenantId) {\n            uid\n            name\n            location {\n                latitude\n                longitude\n            }\n            metadata\n        }\n        myInvoices(filter: { byInvoiceIds: $invoiceIds })\n            @include(if: $showInvoice) {\n            uid\n            total\n            outstandingBalance\n            cancelled\n            items {\n                uid\n                created\n                description\n                perUnitPrice\n                quantity\n                subtotal\n                metadata\n                relatedEntity {\n                    uid\n                    entityId\n                    entityType\n                }\n            }\n        }\n        groupIdEventsActivationStatus(\n            groupIds: $bookingIds\n            tenantId: $tenantId\n        ) {\n            groupId\n            isActivated\n            requiresUserActivation\n            tenantId\n        }\n    }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
    source: "\n    query pelangganCheckoutWorkflowVerify($workflowID: ID!) {\n        courtsiteCheckoutStateById(checkoutId: $workflowID) {\n            id\n            created\n            expired\n            completed\n            cancelled\n            capturePaymentState {\n                id\n                completed\n                expired\n                cancelled\n                state\n            }\n        }\n    }\n",
): (typeof documents)["\n    query pelangganCheckoutWorkflowVerify($workflowID: ID!) {\n        courtsiteCheckoutStateById(checkoutId: $workflowID) {\n            id\n            created\n            expired\n            completed\n            cancelled\n            capturePaymentState {\n                id\n                completed\n                expired\n                cancelled\n                state\n            }\n        }\n    }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
    source: "\n    mutation confirmCapturePayment(\n        $workflowId: ID!\n        $queryParams: JSONString!\n    ) {\n        confirmCapturePayment(\n            request: { workflowId: $workflowId, queryParams: $queryParams }\n        ) {\n            __typename\n\n            ... on CapturePaymentConfirmed {\n                state {\n                    id\n                }\n            }\n\n            ... on WorkflowNotFound {\n                message\n            }\n\n            ... on WorkflowCancelled {\n                message\n            }\n\n            ... on WorkflowNotReady {\n                message\n            }\n        }\n    }\n",
): (typeof documents)["\n    mutation confirmCapturePayment(\n        $workflowId: ID!\n        $queryParams: JSONString!\n    ) {\n        confirmCapturePayment(\n            request: { workflowId: $workflowId, queryParams: $queryParams }\n        ) {\n            __typename\n\n            ... on CapturePaymentConfirmed {\n                state {\n                    id\n                }\n            }\n\n            ... on WorkflowNotFound {\n                message\n            }\n\n            ... on WorkflowCancelled {\n                message\n            }\n\n            ... on WorkflowNotReady {\n                message\n            }\n        }\n    }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
    source: "\n    query courtsiteCheckoutStateByIdSpay($checkoutId: ID!) {\n        courtsiteCheckoutStateById(checkoutId: $checkoutId) {\n            id\n            created\n            cancelled\n            completed\n            expired\n            capturePaymentState {\n                id\n                created\n                cancelled\n                completed\n                expired\n                amountToCapture\n                spayQrCode\n                paymentSessionCreated\n            }\n        }\n    }\n",
): (typeof documents)["\n    query courtsiteCheckoutStateByIdSpay($checkoutId: ID!) {\n        courtsiteCheckoutStateById(checkoutId: $checkoutId) {\n            id\n            created\n            cancelled\n            completed\n            expired\n            capturePaymentState {\n                id\n                created\n                cancelled\n                completed\n                expired\n                amountToCapture\n                spayQrCode\n                paymentSessionCreated\n            }\n        }\n    }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
    source: "\n    mutation forceRetryCapturePaymentSPayCheckout(\n        $request: ForceRetryCapturePaymentRequest!\n    ) {\n        forceRetryCapturePayment(request: $request) {\n            __typename\n            ... on CapturePaymentForceRetried {\n                state {\n                    tenantId\n                    invoiceId\n                    state\n                    amountToCapture\n                    completed\n                    expired\n                    cancelled\n                    created\n                    ...SPayDataCheckout\n                }\n            }\n\n            ... on WorkflowNotFound {\n                message\n            }\n\n            ... on WorkflowNotReady {\n                message\n            }\n        }\n    }\n",
): (typeof documents)["\n    mutation forceRetryCapturePaymentSPayCheckout(\n        $request: ForceRetryCapturePaymentRequest!\n    ) {\n        forceRetryCapturePayment(request: $request) {\n            __typename\n            ... on CapturePaymentForceRetried {\n                state {\n                    tenantId\n                    invoiceId\n                    state\n                    amountToCapture\n                    completed\n                    expired\n                    cancelled\n                    created\n                    ...SPayDataCheckout\n                }\n            }\n\n            ... on WorkflowNotFound {\n                message\n            }\n\n            ... on WorkflowNotReady {\n                message\n            }\n        }\n    }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
    source: "\n    fragment SPayDataCheckout on CapturePaymentState {\n        id\n        spayQrCode\n        paymentSessionCreated\n    }\n",
): (typeof documents)["\n    fragment SPayDataCheckout on CapturePaymentState {\n        id\n        spayQrCode\n        paymentSessionCreated\n    }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
    source: "\n    fragment ExploreCategoryCentre on Organisation {\n        uid\n        name\n        metadata\n    }\n",
): (typeof documents)["\n    fragment ExploreCategoryCentre on Organisation {\n        uid\n        name\n        metadata\n    }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
    source: "\n    query pelangganExploreByCategoryIdData {\n        locations {\n            uid\n            name\n            coordinates {\n                latitude\n                longitude\n            }\n            city\n            ...LocationAccordionOption\n        }\n        categories {\n            name\n            uid\n            metadata\n        }\n    }\n",
): (typeof documents)["\n    query pelangganExploreByCategoryIdData {\n        locations {\n            uid\n            name\n            coordinates {\n                latitude\n                longitude\n            }\n            city\n            ...LocationAccordionOption\n        }\n        categories {\n            name\n            uid\n            metadata\n        }\n    }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
    source: "\n    query pelangganExploreByCategoryIdSearch(\n        $after: String\n        $filter: ApprovedOrganisationsFilter\n        $sortOrder: OrganisationSortOrder\n        $limit: Int!\n    ) {\n        approvedOrganisations(\n            after: $after\n            filter: $filter\n            sortOrder: $sortOrder\n            limit: $limit\n        ) {\n            edges {\n                node {\n                    uid\n                    name\n                    isCourtsitePartner\n                    metadata\n                    location {\n                        latitude\n                        longitude\n                    }\n                    services\n                    ...ExploreCategoryCentre\n                }\n            }\n            pageInfo {\n                endCursor\n                hasNextPage\n            }\n        }\n    }\n",
): (typeof documents)["\n    query pelangganExploreByCategoryIdSearch(\n        $after: String\n        $filter: ApprovedOrganisationsFilter\n        $sortOrder: OrganisationSortOrder\n        $limit: Int!\n    ) {\n        approvedOrganisations(\n            after: $after\n            filter: $filter\n            sortOrder: $sortOrder\n            limit: $limit\n        ) {\n            edges {\n                node {\n                    uid\n                    name\n                    isCourtsitePartner\n                    metadata\n                    location {\n                        latitude\n                        longitude\n                    }\n                    services\n                    ...ExploreCategoryCentre\n                }\n            }\n            pageInfo {\n                endCursor\n                hasNextPage\n            }\n        }\n    }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
    source: "\n    query exploreCategory {\n        categories {\n            ...CategoryListCategories\n            ...SearchBarCategories\n        }\n        courtsitePartners {\n            ...CategoryListVenues\n            ...SearchBarVenues\n        }\n        locations {\n            ...SearchBarLocations\n        }\n    }\n",
): (typeof documents)["\n    query exploreCategory {\n        categories {\n            ...CategoryListCategories\n            ...SearchBarCategories\n        }\n        courtsitePartners {\n            ...CategoryListVenues\n            ...SearchBarVenues\n        }\n        locations {\n            ...SearchBarLocations\n        }\n    }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
    source: "\n    query exploreGames($startsAfter: DateTime!) {\n        publicGameCount(startsAfter: $startsAfter) {\n            categoryId\n            gameCount\n            locationTenantIds\n        }\n        categories {\n            uid\n            name\n            metadata\n        }\n        courtsitePartners {\n            uid\n            name\n            location {\n                latitude\n                longitude\n            }\n        }\n        locations {\n            uid\n            name\n            coordinates {\n                latitude\n                longitude\n            }\n            city\n        }\n    }\n",
): (typeof documents)["\n    query exploreGames($startsAfter: DateTime!) {\n        publicGameCount(startsAfter: $startsAfter) {\n            categoryId\n            gameCount\n            locationTenantIds\n        }\n        categories {\n            uid\n            name\n            metadata\n        }\n        courtsitePartners {\n            uid\n            name\n            location {\n                latitude\n                longitude\n            }\n        }\n        locations {\n            uid\n            name\n            coordinates {\n                latitude\n                longitude\n            }\n            city\n        }\n    }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
    source: "\n    query exploreGamesCourtsiteSearch(\n        $filter: ApprovedOrganisationsFilter\n        $limit: Int\n        $after: String\n    ) {\n        approvedOrganisations(filter: $filter, limit: $limit, after: $after) {\n            edges {\n                node {\n                    uid\n                }\n            }\n        }\n    }\n",
): (typeof documents)["\n    query exploreGamesCourtsiteSearch(\n        $filter: ApprovedOrganisationsFilter\n        $limit: Int\n        $after: String\n    ) {\n        approvedOrganisations(filter: $filter, limit: $limit, after: $after) {\n            edges {\n                node {\n                    uid\n                }\n            }\n        }\n    }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
    source: "\n    mutation editGame(\n        $uid: ID!\n        $startDt: DateTime!\n        $endDt: DateTime!\n        $description: String!\n        $location: LocationInput!\n        $maxPlayers: Int!\n        $metadata: JSONString!\n        $name: String!\n        $private: Boolean\n        $reservedPlayers: Int\n    ) {\n        editGame(\n            request: {\n                uid: $uid\n                startDt: $startDt\n                endDt: $endDt\n                description: $description\n                location: $location\n                maxPlayers: $maxPlayers\n                metadata: $metadata\n                name: $name\n                private: $private\n                reservedPlayers: $reservedPlayers\n            }\n        ) {\n            __typename\n            ... on GameEdited {\n                game {\n                    uid\n                }\n            }\n            ... on AccessError {\n                message\n            }\n            ... on NotFoundError {\n                message\n            }\n        }\n    }\n",
): (typeof documents)["\n    mutation editGame(\n        $uid: ID!\n        $startDt: DateTime!\n        $endDt: DateTime!\n        $description: String!\n        $location: LocationInput!\n        $maxPlayers: Int!\n        $metadata: JSONString!\n        $name: String!\n        $private: Boolean\n        $reservedPlayers: Int\n    ) {\n        editGame(\n            request: {\n                uid: $uid\n                startDt: $startDt\n                endDt: $endDt\n                description: $description\n                location: $location\n                maxPlayers: $maxPlayers\n                metadata: $metadata\n                name: $name\n                private: $private\n                reservedPlayers: $reservedPlayers\n            }\n        ) {\n            __typename\n            ... on GameEdited {\n                game {\n                    uid\n                }\n            }\n            ... on AccessError {\n                message\n            }\n            ... on NotFoundError {\n                message\n            }\n        }\n    }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
    source: "\n    mutation archiveGame($uid: ID!) {\n        archiveGame(request: { uid: $uid }) {\n            __typename\n            ... on GameArchived {\n                game {\n                    uid\n                }\n            }\n            ... on AccessError {\n                message\n            }\n\n            ... on NotFoundError {\n                message\n            }\n        }\n    }\n",
): (typeof documents)["\n    mutation archiveGame($uid: ID!) {\n        archiveGame(request: { uid: $uid }) {\n            __typename\n            ... on GameArchived {\n                game {\n                    uid\n                }\n            }\n            ... on AccessError {\n                message\n            }\n\n            ... on NotFoundError {\n                message\n            }\n        }\n    }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
    source: "\n    query pelangganGame($gameId: ID!) {\n        game(uid: $gameId) {\n            categoryId\n            archived\n            description\n            endDt\n            location {\n                tenantId\n            }\n            maxPlayers\n            metadata\n            name\n            organizer {\n                displayPicture\n                name\n                organizerId\n            }\n            players {\n                displayPicture\n                name\n                playerId\n            }\n            startDt\n            uid\n            isPrivate\n            reservedPlayers\n        }\n        categories {\n            uid\n            name\n            metadata\n        }\n    }\n",
): (typeof documents)["\n    query pelangganGame($gameId: ID!) {\n        game(uid: $gameId) {\n            categoryId\n            archived\n            description\n            endDt\n            location {\n                tenantId\n            }\n            maxPlayers\n            metadata\n            name\n            organizer {\n                displayPicture\n                name\n                organizerId\n            }\n            players {\n                displayPicture\n                name\n                playerId\n            }\n            startDt\n            uid\n            isPrivate\n            reservedPlayers\n        }\n        categories {\n            uid\n            name\n            metadata\n        }\n    }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
    source: "\n    query getCentre($orgID: ID!) {\n        organisation(uid: $orgID) {\n            uid\n            name\n            services\n            metadata\n            location {\n                latitude\n                longitude\n            }\n        }\n        onlineServices(tenantId: $orgID) {\n            uid\n            name\n            serviceMode\n            serviceCategory {\n                uid\n                categoryId\n            }\n            resources {\n                uid\n                resourceId\n                archived\n            }\n            metadata\n        }\n    }\n",
): (typeof documents)["\n    query getCentre($orgID: ID!) {\n        organisation(uid: $orgID) {\n            uid\n            name\n            services\n            metadata\n            location {\n                latitude\n                longitude\n            }\n        }\n        onlineServices(tenantId: $orgID) {\n            uid\n            name\n            serviceMode\n            serviceCategory {\n                uid\n                categoryId\n            }\n            resources {\n                uid\n                resourceId\n                archived\n            }\n            metadata\n        }\n    }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
    source: "\n    mutation startCourtsiteGameWorkflow(\n        $categoryId: ID!\n        $description: String!\n        $endDt: Time!\n        $location: StartCourtsiteGameWorkflowLocationInput!\n        $maxPlayers: Int!\n        $metadata: JSONString!\n        $name: String!\n        $organizerDetails: StartCourtsiteGameWorkflowPlayerInput!\n        $startDt: Time!\n        $private: Boolean\n        $reservedPlayers: Int\n    ) {\n        startCourtsiteGameWorkflow(\n            request: {\n                categoryId: $categoryId\n                description: $description\n                endDt: $endDt\n                location: $location\n                maxPlayers: $maxPlayers\n                metadata: $metadata\n                name: $name\n                organizerDetails: $organizerDetails\n                startDt: $startDt\n                private: $private\n                reservedPlayers: $reservedPlayers\n            }\n        ) {\n            ... on CourtsiteGameWorkflowStarted {\n                state {\n                    gameId\n                }\n            }\n            ... on WorkflowCancelled {\n                message\n            }\n            ... on WorkflowNotReady {\n                message\n            }\n        }\n    }\n",
): (typeof documents)["\n    mutation startCourtsiteGameWorkflow(\n        $categoryId: ID!\n        $description: String!\n        $endDt: Time!\n        $location: StartCourtsiteGameWorkflowLocationInput!\n        $maxPlayers: Int!\n        $metadata: JSONString!\n        $name: String!\n        $organizerDetails: StartCourtsiteGameWorkflowPlayerInput!\n        $startDt: Time!\n        $private: Boolean\n        $reservedPlayers: Int\n    ) {\n        startCourtsiteGameWorkflow(\n            request: {\n                categoryId: $categoryId\n                description: $description\n                endDt: $endDt\n                location: $location\n                maxPlayers: $maxPlayers\n                metadata: $metadata\n                name: $name\n                organizerDetails: $organizerDetails\n                startDt: $startDt\n                private: $private\n                reservedPlayers: $reservedPlayers\n            }\n        ) {\n            ... on CourtsiteGameWorkflowStarted {\n                state {\n                    gameId\n                }\n            }\n            ... on WorkflowCancelled {\n                message\n            }\n            ... on WorkflowNotReady {\n                message\n            }\n        }\n    }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
    source: "\n    query pelangganHomePage(\n        $now: DateTime!\n        $startsAfter: DateTime!\n        $isLoggedIn: Boolean!\n    ) {\n        upcomingBookings: myGroupedBookings(\n            filter: {\n                byStatus: { confirmed: true, unconfirmed: true }\n                byStartDateAfter: $now\n            }\n            limit: 3\n            order: ASC\n        ) @include(if: $isLoggedIn) {\n            edges {\n                node {\n                    referenceId\n                    startDt\n                    endDt\n                    bookings {\n                        tenant {\n                            uid\n                            name\n                        }\n                        uid\n                        created\n                        tenantId\n                        startDt\n                        endDt\n                        confirmed\n                        cancelled\n                        service {\n                            uid\n                            serviceMode\n                            categoryName\n                        }\n                        resources {\n                            resourceId\n                            resourceName\n                        }\n                        metadata\n                    }\n                }\n            }\n        }\n        publicGameCount(startsAfter: $startsAfter) {\n            categoryId\n            gameCount\n            locationTenantIds\n        }\n        categories {\n            ...FeatureVenueCategories\n            ...SocialGamesCategories\n            ...SearchBarCategories\n        }\n        tenantIdsFromPastBookings(limit: 3) @include(if: $isLoggedIn)\n        courtsitePartners(sortOrder: BY_WEIGHTAGE_DESC) {\n            uid\n            name\n            metadata\n            ...SearchBarVenues\n        }\n        locations {\n            ...SearchBarLocations\n        }\n    }\n",
): (typeof documents)["\n    query pelangganHomePage(\n        $now: DateTime!\n        $startsAfter: DateTime!\n        $isLoggedIn: Boolean!\n    ) {\n        upcomingBookings: myGroupedBookings(\n            filter: {\n                byStatus: { confirmed: true, unconfirmed: true }\n                byStartDateAfter: $now\n            }\n            limit: 3\n            order: ASC\n        ) @include(if: $isLoggedIn) {\n            edges {\n                node {\n                    referenceId\n                    startDt\n                    endDt\n                    bookings {\n                        tenant {\n                            uid\n                            name\n                        }\n                        uid\n                        created\n                        tenantId\n                        startDt\n                        endDt\n                        confirmed\n                        cancelled\n                        service {\n                            uid\n                            serviceMode\n                            categoryName\n                        }\n                        resources {\n                            resourceId\n                            resourceName\n                        }\n                        metadata\n                    }\n                }\n            }\n        }\n        publicGameCount(startsAfter: $startsAfter) {\n            categoryId\n            gameCount\n            locationTenantIds\n        }\n        categories {\n            ...FeatureVenueCategories\n            ...SocialGamesCategories\n            ...SearchBarCategories\n        }\n        tenantIdsFromPastBookings(limit: 3) @include(if: $isLoggedIn)\n        courtsitePartners(sortOrder: BY_WEIGHTAGE_DESC) {\n            uid\n            name\n            metadata\n            ...SearchBarVenues\n        }\n        locations {\n            ...SearchBarLocations\n        }\n    }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
    source: "\n    query homepagePublicGame($filter: PublicGamesFilter, $page: PageInput) {\n        publicGames(filter: $filter, page: $page) {\n            edges {\n                node {\n                    uid\n                    name\n                    categoryId\n                    startDt\n                    endDt\n                    maxPlayers\n                    location {\n                        tenant {\n                            uid\n                            name\n                        }\n                    }\n                    organizer {\n                        name\n                    }\n                    players {\n                        displayPicture\n                    }\n                    metadata\n                    reservedPlayers\n                }\n            }\n        }\n    }\n",
): (typeof documents)["\n    query homepagePublicGame($filter: PublicGamesFilter, $page: PageInput) {\n        publicGames(filter: $filter, page: $page) {\n            edges {\n                node {\n                    uid\n                    name\n                    categoryId\n                    startDt\n                    endDt\n                    maxPlayers\n                    location {\n                        tenant {\n                            uid\n                            name\n                        }\n                    }\n                    organizer {\n                        name\n                    }\n                    players {\n                        displayPicture\n                    }\n                    metadata\n                    reservedPlayers\n                }\n            }\n        }\n    }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
    source: "\n    fragment SocialGamesCategories on Category {\n        uid\n        name\n    }\n",
): (typeof documents)["\n    fragment SocialGamesCategories on Category {\n        uid\n        name\n    }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
    source: "\n    query pelangganInvoice($tenantId: ID!, $uid: ID!) {\n        invoice(uid: $uid, tenantId: $tenantId) {\n            uid\n            total\n            outstandingBalance\n            metadata\n            expiresOn\n            printNumber\n            cancelled\n            created\n            metadata\n            invoiceeName\n            isCourtsiteOwned\n            items {\n                uid\n                description\n                perUnitPrice\n                quantity\n                subtotal\n                relatedEntity {\n                    uid\n                    entityId\n                    entityType\n                }\n                metadata\n                created\n            }\n            payments {\n                uid\n                created\n                amount\n                paymentType\n                paymentMethod {\n                    uid\n                    name\n                }\n            }\n        }\n        organisation(uid: $tenantId) {\n            uid\n            name\n            metadata\n            isCourtsitePartner\n            services\n        }\n        categories {\n            uid\n            name\n        }\n    }\n",
): (typeof documents)["\n    query pelangganInvoice($tenantId: ID!, $uid: ID!) {\n        invoice(uid: $uid, tenantId: $tenantId) {\n            uid\n            total\n            outstandingBalance\n            metadata\n            expiresOn\n            printNumber\n            cancelled\n            created\n            metadata\n            invoiceeName\n            isCourtsiteOwned\n            items {\n                uid\n                description\n                perUnitPrice\n                quantity\n                subtotal\n                relatedEntity {\n                    uid\n                    entityId\n                    entityType\n                }\n                metadata\n                created\n            }\n            payments {\n                uid\n                created\n                amount\n                paymentType\n                paymentMethod {\n                    uid\n                    name\n                }\n            }\n        }\n        organisation(uid: $tenantId) {\n            uid\n            name\n            metadata\n            isCourtsitePartner\n            services\n        }\n        categories {\n            uid\n            name\n        }\n    }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
    source: "\n    query pelangganOnboarding {\n        myProfile {\n            firebaseId\n            name\n            phoneNumber\n            email\n            gender\n            dateOfBirth\n            postCode\n            preferredSports\n            otherPreferredSports\n            sportsFrequency\n        }\n    }\n",
): (typeof documents)["\n    query pelangganOnboarding {\n        myProfile {\n            firebaseId\n            name\n            phoneNumber\n            email\n            gender\n            dateOfBirth\n            postCode\n            preferredSports\n            otherPreferredSports\n            sportsFrequency\n        }\n    }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
    source: "\n    mutation pelangganOnboardingSetUserProfile(\n        $request: SetUserProfileRequest!\n    ) {\n        setUserProfile(request: $request) {\n            __typename\n            ... on UserProfileSet {\n                user {\n                    firebaseId\n                }\n            }\n            ... on AccessError {\n                message\n            }\n\n            ... on InvalidValue {\n                message\n            }\n        }\n    }\n",
): (typeof documents)["\n    mutation pelangganOnboardingSetUserProfile(\n        $request: SetUserProfileRequest!\n    ) {\n        setUserProfile(request: $request) {\n            __typename\n            ... on UserProfileSet {\n                user {\n                    firebaseId\n                }\n            }\n            ... on AccessError {\n                message\n            }\n\n            ... on InvalidValue {\n                message\n            }\n        }\n    }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
    source: "\n    query capturePaymentResult(\n        $capturePaymentWorkflowId: ID!\n        $checkoutId: ID!\n        $hasCheckoutId: Boolean!\n    ) {\n        capturePaymentStateById(workflowId: $capturePaymentWorkflowId) {\n            id\n            invoiceId\n            tenantId\n            completed\n            expired\n            cancelled\n            created\n        }\n        courtsiteCheckoutStateById(checkoutId: $checkoutId)\n            @include(if: $hasCheckoutId) {\n            invoiceId\n        }\n    }\n",
): (typeof documents)["\n    query capturePaymentResult(\n        $capturePaymentWorkflowId: ID!\n        $checkoutId: ID!\n        $hasCheckoutId: Boolean!\n    ) {\n        capturePaymentStateById(workflowId: $capturePaymentWorkflowId) {\n            id\n            invoiceId\n            tenantId\n            completed\n            expired\n            cancelled\n            created\n        }\n        courtsiteCheckoutStateById(checkoutId: $checkoutId)\n            @include(if: $hasCheckoutId) {\n            invoiceId\n        }\n    }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
    source: "\n    query paymentResult(\n        $invoiceIds: [String!]!\n        $tenantId: ID!\n        $showInvoice: Boolean!\n    ) {\n        myInvoices(filter: { byInvoiceIds: $invoiceIds })\n            @include(if: $showInvoice) {\n            uid\n            total\n            outstandingBalance\n            cancelled\n            items {\n                uid\n                description\n                quantity\n                perUnitPrice\n                subtotal\n                metadata\n                created\n                relatedEntity {\n                    uid\n                    entityId\n                    entityType\n                }\n            }\n        }\n        organisation(uid: $tenantId) {\n            uid\n            name\n            location {\n                latitude\n                longitude\n            }\n            metadata\n        }\n    }\n",
): (typeof documents)["\n    query paymentResult(\n        $invoiceIds: [String!]!\n        $tenantId: ID!\n        $showInvoice: Boolean!\n    ) {\n        myInvoices(filter: { byInvoiceIds: $invoiceIds })\n            @include(if: $showInvoice) {\n            uid\n            total\n            outstandingBalance\n            cancelled\n            items {\n                uid\n                description\n                quantity\n                perUnitPrice\n                subtotal\n                metadata\n                created\n                relatedEntity {\n                    uid\n                    entityId\n                    entityType\n                }\n            }\n        }\n        organisation(uid: $tenantId) {\n            uid\n            name\n            location {\n                latitude\n                longitude\n            }\n            metadata\n        }\n    }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
    source: "\n    query paymentResultBookings($bookingIds: [ID!]!) {\n        myBookingsByIds(bookingIds: $bookingIds) {\n            uid\n            startDt\n            endDt\n            metadata\n            referenceId\n            cancelled\n            confirmed\n            service {\n                uid\n                name\n                serviceMode\n                minRescheduleNotice\n                category {\n                    uid\n                }\n            }\n            resources {\n                uid\n                archived\n                resource {\n                    uid\n                    name\n                }\n            }\n        }\n    }\n",
): (typeof documents)["\n    query paymentResultBookings($bookingIds: [ID!]!) {\n        myBookingsByIds(bookingIds: $bookingIds) {\n            uid\n            startDt\n            endDt\n            metadata\n            referenceId\n            cancelled\n            confirmed\n            service {\n                uid\n                name\n                serviceMode\n                minRescheduleNotice\n                category {\n                    uid\n                }\n            }\n            resources {\n                uid\n                archived\n                resource {\n                    uid\n                    name\n                }\n            }\n        }\n    }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
    source: "\n    query paymentInvoice($tenantId: ID!, $invoiceId: ID!) {\n        invoice(tenantId: $tenantId, uid: $invoiceId) {\n            uid\n            invoiceeName\n            invoiceePhoneNumber\n            invoiceeEmail\n            total\n            metadata\n            tenant {\n                uid\n                name\n                services\n                metadata\n                isCourtsitePartner\n            }\n            items {\n                uid\n                description\n                perUnitPrice\n                quantity\n                subtotal\n                metadata\n                relatedEntity {\n                    entityId\n                    entityType\n                }\n                created\n            }\n        }\n    }\n",
): (typeof documents)["\n    query paymentInvoice($tenantId: ID!, $invoiceId: ID!) {\n        invoice(tenantId: $tenantId, uid: $invoiceId) {\n            uid\n            invoiceeName\n            invoiceePhoneNumber\n            invoiceeEmail\n            total\n            metadata\n            tenant {\n                uid\n                name\n                services\n                metadata\n                isCourtsitePartner\n            }\n            items {\n                uid\n                description\n                perUnitPrice\n                quantity\n                subtotal\n                metadata\n                relatedEntity {\n                    entityId\n                    entityType\n                }\n                created\n            }\n        }\n    }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
    source: "\n    query paymentBookings($tenantId: ID!, $bookingIds: [ID!]!) {\n        bookingsByIds(tenantId: $tenantId, bookingIds: $bookingIds) {\n            uid\n            resources {\n                uid\n                archived\n                resource {\n                    uid\n                    name\n                }\n            }\n            service {\n                uid\n                serviceMode\n                category {\n                    uid\n                    name\n                }\n            }\n            startDt\n            endDt\n            referenceId\n        }\n    }\n",
): (typeof documents)["\n    query paymentBookings($tenantId: ID!, $bookingIds: [ID!]!) {\n        bookingsByIds(tenantId: $tenantId, bookingIds: $bookingIds) {\n            uid\n            resources {\n                uid\n                archived\n                resource {\n                    uid\n                    name\n                }\n            }\n            service {\n                uid\n                serviceMode\n                category {\n                    uid\n                    name\n                }\n            }\n            startDt\n            endDt\n            referenceId\n        }\n    }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
    source: "\n    query capturePaymentStateById($workflowId: ID!) {\n        capturePaymentStateById(workflowId: $workflowId) {\n            tenantId\n            state\n            cancelled\n            expired\n            completed\n            invoiceId\n            ...PaymentHandlerData\n        }\n    }\n",
): (typeof documents)["\n    query capturePaymentStateById($workflowId: ID!) {\n        capturePaymentStateById(workflowId: $workflowId) {\n            tenantId\n            state\n            cancelled\n            expired\n            completed\n            invoiceId\n            ...PaymentHandlerData\n        }\n    }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
    source: "\n    mutation provideCapturePaymentProviderDetails(\n        $workflowId: ID!\n        $paymentProvider: PaymentProvider!\n        $redirectUrl: String\n    ) {\n        provideCapturePaymentProviderDetails(\n            request: {\n                workflowId: $workflowId\n                paymentProvider: $paymentProvider\n                redirectUrl: $redirectUrl\n            }\n        ) {\n            __typename\n\n            ... on CapturePaymentProviderDetailsProvided {\n                state {\n                    id\n                    runId\n                    state\n                    ...PaymentHandlerData\n                }\n            }\n\n            ... on WorkflowNotFound {\n                message\n            }\n\n            ... on WorkflowCancelled {\n                message\n            }\n\n            ... on WorkflowNotReady {\n                message\n            }\n        }\n    }\n",
): (typeof documents)["\n    mutation provideCapturePaymentProviderDetails(\n        $workflowId: ID!\n        $paymentProvider: PaymentProvider!\n        $redirectUrl: String\n    ) {\n        provideCapturePaymentProviderDetails(\n            request: {\n                workflowId: $workflowId\n                paymentProvider: $paymentProvider\n                redirectUrl: $redirectUrl\n            }\n        ) {\n            __typename\n\n            ... on CapturePaymentProviderDetailsProvided {\n                state {\n                    id\n                    runId\n                    state\n                    ...PaymentHandlerData\n                }\n            }\n\n            ... on WorkflowNotFound {\n                message\n            }\n\n            ... on WorkflowCancelled {\n                message\n            }\n\n            ... on WorkflowNotReady {\n                message\n            }\n        }\n    }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
    source: "\n    mutation forceRetryCapturePaymentLink(\n        $request: ForceRetryCapturePaymentRequest!\n    ) {\n        forceRetryCapturePayment(request: $request) {\n            __typename\n            ... on CapturePaymentForceRetried {\n                state {\n                    id\n                    runId\n                    state\n                    ...PaymentHandlerData\n                }\n            }\n\n            ... on WorkflowNotFound {\n                message\n            }\n\n            ... on WorkflowNotReady {\n                message\n            }\n        }\n    }\n",
): (typeof documents)["\n    mutation forceRetryCapturePaymentLink(\n        $request: ForceRetryCapturePaymentRequest!\n    ) {\n        forceRetryCapturePayment(request: $request) {\n            __typename\n            ... on CapturePaymentForceRetried {\n                state {\n                    id\n                    runId\n                    state\n                    ...PaymentHandlerData\n                }\n            }\n\n            ... on WorkflowNotFound {\n                message\n            }\n\n            ... on WorkflowNotReady {\n                message\n            }\n        }\n    }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
    source: "\n    query pelangganCapturePaymentVerify($capturePaymentWorkflowId: ID!) {\n        capturePaymentStateById(workflowId: $capturePaymentWorkflowId) {\n            cancelled\n            completed\n            expired\n            id\n        }\n    }\n",
): (typeof documents)["\n    query pelangganCapturePaymentVerify($capturePaymentWorkflowId: ID!) {\n        capturePaymentStateById(workflowId: $capturePaymentWorkflowId) {\n            cancelled\n            completed\n            expired\n            id\n        }\n    }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
    source: "\n    mutation pelangganPaymentCaptureWorkflowVerifyConfirmCapturePayment(\n        $capturePaymentWorkflowId: ID!\n        $queryParams: JSONString!\n    ) {\n        confirmCapturePayment(\n            request: {\n                workflowId: $capturePaymentWorkflowId\n                queryParams: $queryParams\n            }\n        ) {\n            __typename\n\n            ... on CapturePaymentConfirmed {\n                state {\n                    id\n                }\n            }\n\n            ... on WorkflowNotFound {\n                message\n            }\n\n            ... on WorkflowCancelled {\n                message\n            }\n\n            ... on WorkflowNotReady {\n                message\n            }\n        }\n    }\n",
): (typeof documents)["\n    mutation pelangganPaymentCaptureWorkflowVerifyConfirmCapturePayment(\n        $capturePaymentWorkflowId: ID!\n        $queryParams: JSONString!\n    ) {\n        confirmCapturePayment(\n            request: {\n                workflowId: $capturePaymentWorkflowId\n                queryParams: $queryParams\n            }\n        ) {\n            __typename\n\n            ... on CapturePaymentConfirmed {\n                state {\n                    id\n                }\n            }\n\n            ... on WorkflowNotFound {\n                message\n            }\n\n            ... on WorkflowCancelled {\n                message\n            }\n\n            ... on WorkflowNotReady {\n                message\n            }\n        }\n    }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
    source: "\n    query capturePaymentVerificationSPay($workflowId: ID!) {\n        capturePaymentStateById(workflowId: $workflowId) {\n            id\n            created\n            cancelled\n            completed\n            expired\n            amountToCapture\n            spayQrCode\n            paymentSessionCreated\n        }\n    }\n",
): (typeof documents)["\n    query capturePaymentVerificationSPay($workflowId: ID!) {\n        capturePaymentStateById(workflowId: $workflowId) {\n            id\n            created\n            cancelled\n            completed\n            expired\n            amountToCapture\n            spayQrCode\n            paymentSessionCreated\n        }\n    }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
    source: "\n    mutation forceRetryCapturePaymentSPayPayment(\n        $request: ForceRetryCapturePaymentRequest!\n    ) {\n        forceRetryCapturePayment(request: $request) {\n            __typename\n            ... on CapturePaymentForceRetried {\n                state {\n                    tenantId\n                    invoiceId\n                    state\n                    amountToCapture\n                    completed\n                    expired\n                    cancelled\n                    created\n                    ...SPayDataPayment\n                }\n            }\n\n            ... on WorkflowNotFound {\n                message\n            }\n\n            ... on WorkflowNotReady {\n                message\n            }\n        }\n    }\n",
): (typeof documents)["\n    mutation forceRetryCapturePaymentSPayPayment(\n        $request: ForceRetryCapturePaymentRequest!\n    ) {\n        forceRetryCapturePayment(request: $request) {\n            __typename\n            ... on CapturePaymentForceRetried {\n                state {\n                    tenantId\n                    invoiceId\n                    state\n                    amountToCapture\n                    completed\n                    expired\n                    cancelled\n                    created\n                    ...SPayDataPayment\n                }\n            }\n\n            ... on WorkflowNotFound {\n                message\n            }\n\n            ... on WorkflowNotReady {\n                message\n            }\n        }\n    }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
    source: "\n    fragment SPayDataPayment on CapturePaymentState {\n        id\n        spayQrCode\n        paymentSessionCreated\n    }\n",
): (typeof documents)["\n    fragment SPayDataPayment on CapturePaymentState {\n        id\n        spayQrCode\n        paymentSessionCreated\n    }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
    source: "\n    query pelangganRescheduleCategories {\n        categories {\n            uid\n            name\n            metadata\n        }\n    }\n",
): (typeof documents)["\n    query pelangganRescheduleCategories {\n        categories {\n            uid\n            name\n            metadata\n        }\n    }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
    source: "\n    query pelangganRescheduleBookingBookingDetails(\n        $bookingIds: [ID!]!\n        $relatedEntityIds: [String]!\n    ) {\n        myBookingsByIds(bookingIds: $bookingIds) {\n            uid\n            startDt\n            endDt\n            created\n            confirmed\n            cancelled\n            metadata\n            guest {\n                uid\n                name\n                userId\n            }\n            resources {\n                uid\n                archived\n                resource {\n                    uid\n                    name\n                }\n            }\n            referenceId\n            tenant {\n                uid\n                name\n                metadata\n                location {\n                    latitude\n                    longitude\n                }\n            }\n            service {\n                uid\n                name\n                serviceMode\n                minRescheduleNotice\n                category {\n                    uid\n                    name\n                }\n            }\n        }\n        myInvoices(filter: { byRelatedEntityIds: $relatedEntityIds }) {\n            uid\n            associatedUsers {\n                userId\n            }\n            printNumber\n            outstandingBalance\n            metadata\n            cancelled\n            items {\n                uid\n                description\n                perUnitPrice\n                quantity\n                created\n                relatedEntity {\n                    entityId\n                    entityType\n                }\n                metadata\n            }\n        }\n    }\n",
): (typeof documents)["\n    query pelangganRescheduleBookingBookingDetails(\n        $bookingIds: [ID!]!\n        $relatedEntityIds: [String]!\n    ) {\n        myBookingsByIds(bookingIds: $bookingIds) {\n            uid\n            startDt\n            endDt\n            created\n            confirmed\n            cancelled\n            metadata\n            guest {\n                uid\n                name\n                userId\n            }\n            resources {\n                uid\n                archived\n                resource {\n                    uid\n                    name\n                }\n            }\n            referenceId\n            tenant {\n                uid\n                name\n                metadata\n                location {\n                    latitude\n                    longitude\n                }\n            }\n            service {\n                uid\n                name\n                serviceMode\n                minRescheduleNotice\n                category {\n                    uid\n                    name\n                }\n            }\n        }\n        myInvoices(filter: { byRelatedEntityIds: $relatedEntityIds }) {\n            uid\n            associatedUsers {\n                userId\n            }\n            printNumber\n            outstandingBalance\n            metadata\n            cancelled\n            items {\n                uid\n                description\n                perUnitPrice\n                quantity\n                created\n                relatedEntity {\n                    entityId\n                    entityType\n                }\n                metadata\n            }\n        }\n    }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
    source: "\n    query pelangganRescheduleStateByIdResult($workflowId: ID!) {\n        courtsiteRescheduleStateById(workflowId: $workflowId) {\n            id\n            oldBookingId\n            created\n            completed\n            cancelled\n            expired\n            priceDifference\n            capturePaymentState {\n                id\n                completed\n                expired\n                cancelled\n                created\n            }\n        }\n    }\n",
): (typeof documents)["\n    query pelangganRescheduleStateByIdResult($workflowId: ID!) {\n        courtsiteRescheduleStateById(workflowId: $workflowId) {\n            id\n            oldBookingId\n            created\n            completed\n            cancelled\n            expired\n            priceDifference\n            capturePaymentState {\n                id\n                completed\n                expired\n                cancelled\n                created\n            }\n        }\n    }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
    source: "\n    mutation ProvideCourtsiteReschedulePaymentDetails(\n        $request: ProvideCourtsiteReschedulePaymentDetailsRequest!\n    ) {\n        provideCourtsiteReschedulePaymentDetails(request: $request) {\n            __typename\n            ... on CourtsiteReschedulePaymentDetailsProvided {\n                state {\n                    id\n                    runId\n                    state\n                    completed\n                    expired\n                    cancelled\n                    created\n                    priceDifference\n                    newBookingId\n                    newInvoiceId\n                    redirectUrl\n                    capturePaymentWorkflowId\n                    capturePaymentState {\n                        id\n                        runId\n                        state\n                        ...PaymentHandlerData\n                    }\n                }\n            }\n            ... on InvalidCourtsiteReschedulePaymentDetailsRequest {\n                message\n            }\n            ... on CourtsiteRescheduleExpired {\n                message\n            }\n            ... on WorkflowNotFound {\n                message\n            }\n            ... on WorkflowCancelled {\n                message\n            }\n            ... on WorkflowNotReady {\n                message\n            }\n        }\n    }\n",
): (typeof documents)["\n    mutation ProvideCourtsiteReschedulePaymentDetails(\n        $request: ProvideCourtsiteReschedulePaymentDetailsRequest!\n    ) {\n        provideCourtsiteReschedulePaymentDetails(request: $request) {\n            __typename\n            ... on CourtsiteReschedulePaymentDetailsProvided {\n                state {\n                    id\n                    runId\n                    state\n                    completed\n                    expired\n                    cancelled\n                    created\n                    priceDifference\n                    newBookingId\n                    newInvoiceId\n                    redirectUrl\n                    capturePaymentWorkflowId\n                    capturePaymentState {\n                        id\n                        runId\n                        state\n                        ...PaymentHandlerData\n                    }\n                }\n            }\n            ... on InvalidCourtsiteReschedulePaymentDetailsRequest {\n                message\n            }\n            ... on CourtsiteRescheduleExpired {\n                message\n            }\n            ... on WorkflowNotFound {\n                message\n            }\n            ... on WorkflowCancelled {\n                message\n            }\n            ... on WorkflowNotReady {\n                message\n            }\n        }\n    }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
    source: "\n    mutation pelangganRescheduleCheckoutForceRetryPayment(\n        $request: ForceRetryCapturePaymentRequest!\n    ) {\n        forceRetryCapturePayment(request: $request) {\n            __typename\n            ... on CapturePaymentForceRetried {\n                state {\n                    id\n                    runId\n                    state\n                    amountToCapture\n                    ...PaymentHandlerData\n                }\n            }\n\n            ... on WorkflowNotFound {\n                message\n            }\n\n            ... on WorkflowNotReady {\n                message\n            }\n        }\n    }\n",
): (typeof documents)["\n    mutation pelangganRescheduleCheckoutForceRetryPayment(\n        $request: ForceRetryCapturePaymentRequest!\n    ) {\n        forceRetryCapturePayment(request: $request) {\n            __typename\n            ... on CapturePaymentForceRetried {\n                state {\n                    id\n                    runId\n                    state\n                    amountToCapture\n                    ...PaymentHandlerData\n                }\n            }\n\n            ... on WorkflowNotFound {\n                message\n            }\n\n            ... on WorkflowNotReady {\n                message\n            }\n        }\n    }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
    source: "\n    query pelangganRescheduleStateByIdPayment($workflowId: ID!) {\n        courtsiteRescheduleStateById(workflowId: $workflowId) {\n            id\n            oldBookingId\n            newBookingId\n            newInvoiceId\n            created\n            completed\n            cancelled\n            expired\n            priceDifference\n            state\n            capturePaymentState {\n                id\n                state\n                completed\n                expired\n                cancelled\n                created\n                ...PaymentHandlerData\n            }\n        }\n    }\n",
): (typeof documents)["\n    query pelangganRescheduleStateByIdPayment($workflowId: ID!) {\n        courtsiteRescheduleStateById(workflowId: $workflowId) {\n            id\n            oldBookingId\n            newBookingId\n            newInvoiceId\n            created\n            completed\n            cancelled\n            expired\n            priceDifference\n            state\n            capturePaymentState {\n                id\n                state\n                completed\n                expired\n                cancelled\n                created\n                ...PaymentHandlerData\n            }\n        }\n    }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
    source: "\n    fragment bookingFieldsReschedulePayment on Booking {\n        uid\n        startDt\n        endDt\n        referenceId\n        tenantId\n        metadata\n        service {\n            uid\n            name\n            serviceMode\n            minRescheduleNotice\n            category {\n                uid\n                name\n                metadata\n            }\n        }\n        resources {\n            uid\n            archived\n            resource {\n                uid\n                name\n            }\n        }\n    }\n",
): (typeof documents)["\n    fragment bookingFieldsReschedulePayment on Booking {\n        uid\n        startDt\n        endDt\n        referenceId\n        tenantId\n        metadata\n        service {\n            uid\n            name\n            serviceMode\n            minRescheduleNotice\n            category {\n                uid\n                name\n                metadata\n            }\n        }\n        resources {\n            uid\n            archived\n            resource {\n                uid\n                name\n            }\n        }\n    }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
    source: "\n    query pelangganRescheduleCheckoutPaymentBooking(\n        $oldBookingId: ID!\n        $newBookingId: ID!\n    ) {\n        oldBooking: myBooking(bookingId: $oldBookingId) {\n            ...bookingFieldsReschedulePayment\n        }\n        newBooking: myBooking(bookingId: $newBookingId) {\n            ...bookingFieldsReschedulePayment\n        }\n    }\n",
): (typeof documents)["\n    query pelangganRescheduleCheckoutPaymentBooking(\n        $oldBookingId: ID!\n        $newBookingId: ID!\n    ) {\n        oldBooking: myBooking(bookingId: $oldBookingId) {\n            ...bookingFieldsReschedulePayment\n        }\n        newBooking: myBooking(bookingId: $newBookingId) {\n            ...bookingFieldsReschedulePayment\n        }\n    }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
    source: "\n    query rescheduleCheckoutPaymentOrg($tenantId: ID!) {\n        organisation(uid: $tenantId) {\n            uid\n            name\n            metadata\n            isCourtsitePartner\n        }\n        onlineServices(tenantId: $tenantId) {\n            uid\n            serviceCategory {\n                uid\n                categoryId\n            }\n        }\n    }\n",
): (typeof documents)["\n    query rescheduleCheckoutPaymentOrg($tenantId: ID!) {\n        organisation(uid: $tenantId) {\n            uid\n            name\n            metadata\n            isCourtsitePartner\n        }\n        onlineServices(tenantId: $tenantId) {\n            uid\n            serviceCategory {\n                uid\n                categoryId\n            }\n        }\n    }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
    source: "\n    query pelangganRescheduleStateByIdSuccess($workflowId: ID!) {\n        courtsiteRescheduleStateById(workflowId: $workflowId) {\n            id\n            newBookingId\n            newInvoiceId\n            created\n            completed\n            cancelled\n            expired\n            priceDifference\n            capturePaymentState {\n                id\n                tenantId\n                completed\n                expired\n                cancelled\n                created\n            }\n        }\n    }\n",
): (typeof documents)["\n    query pelangganRescheduleStateByIdSuccess($workflowId: ID!) {\n        courtsiteRescheduleStateById(workflowId: $workflowId) {\n            id\n            newBookingId\n            newInvoiceId\n            created\n            completed\n            cancelled\n            expired\n            priceDifference\n            capturePaymentState {\n                id\n                tenantId\n                completed\n                expired\n                cancelled\n                created\n            }\n        }\n    }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
    source: "\n    query pelangganRescheduleCheckoutSuccess($bookingId: ID!, $invoiceId: ID!) {\n        myBooking(bookingId: $bookingId) {\n            uid\n            startDt\n            endDt\n            tenantId\n            referenceId\n            cancelled\n            confirmed\n            metadata\n            service {\n                uid\n                name\n                serviceMode\n                minRescheduleNotice\n                category {\n                    uid\n                    name\n                }\n            }\n            resources {\n                uid\n                archived\n                resource {\n                    uid\n                    name\n                }\n            }\n        }\n        myInvoice(invoiceId: $invoiceId) {\n            uid\n            total\n            outstandingBalance\n            cancelled\n            items {\n                uid\n                created\n                description\n                perUnitPrice\n                quantity\n                subtotal\n                metadata\n                relatedEntity {\n                    uid\n                    entityId\n                    entityType\n                }\n            }\n        }\n    }\n",
): (typeof documents)["\n    query pelangganRescheduleCheckoutSuccess($bookingId: ID!, $invoiceId: ID!) {\n        myBooking(bookingId: $bookingId) {\n            uid\n            startDt\n            endDt\n            tenantId\n            referenceId\n            cancelled\n            confirmed\n            metadata\n            service {\n                uid\n                name\n                serviceMode\n                minRescheduleNotice\n                category {\n                    uid\n                    name\n                }\n            }\n            resources {\n                uid\n                archived\n                resource {\n                    uid\n                    name\n                }\n            }\n        }\n        myInvoice(invoiceId: $invoiceId) {\n            uid\n            total\n            outstandingBalance\n            cancelled\n            items {\n                uid\n                created\n                description\n                perUnitPrice\n                quantity\n                subtotal\n                metadata\n                relatedEntity {\n                    uid\n                    entityId\n                    entityType\n                }\n            }\n        }\n    }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
    source: "\n    query rescheduleCheckoutSuccessOrg($tenantId: ID!) {\n        organisation(uid: $tenantId) {\n            uid\n            name\n            location {\n                latitude\n                longitude\n            }\n            metadata\n        }\n    }\n",
): (typeof documents)["\n    query rescheduleCheckoutSuccessOrg($tenantId: ID!) {\n        organisation(uid: $tenantId) {\n            uid\n            name\n            location {\n                latitude\n                longitude\n            }\n            metadata\n        }\n    }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
    source: "\n    query pelangganRescheduleStateByIdVerify($workflowId: ID!) {\n        courtsiteRescheduleStateById(workflowId: $workflowId) {\n            id\n            completed\n            cancelled\n            priceDifference\n            capturePaymentState {\n                id\n                completed\n                expired\n                cancelled\n            }\n        }\n    }\n",
): (typeof documents)["\n    query pelangganRescheduleStateByIdVerify($workflowId: ID!) {\n        courtsiteRescheduleStateById(workflowId: $workflowId) {\n            id\n            completed\n            cancelled\n            priceDifference\n            capturePaymentState {\n                id\n                completed\n                expired\n                cancelled\n            }\n        }\n    }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
    source: "\n    mutation pelangganRescheduleCaptureWorkflowVerifyConfirmCapturePayment(\n        $workflowId: ID!\n        $queryParams: JSONString!\n    ) {\n        confirmCapturePayment(\n            request: { workflowId: $workflowId, queryParams: $queryParams }\n        ) {\n            __typename\n\n            ... on CapturePaymentConfirmed {\n                state {\n                    id\n                }\n            }\n\n            ... on WorkflowNotFound {\n                message\n            }\n\n            ... on WorkflowCancelled {\n                message\n            }\n\n            ... on WorkflowNotReady {\n                message\n            }\n        }\n    }\n",
): (typeof documents)["\n    mutation pelangganRescheduleCaptureWorkflowVerifyConfirmCapturePayment(\n        $workflowId: ID!\n        $queryParams: JSONString!\n    ) {\n        confirmCapturePayment(\n            request: { workflowId: $workflowId, queryParams: $queryParams }\n        ) {\n            __typename\n\n            ... on CapturePaymentConfirmed {\n                state {\n                    id\n                }\n            }\n\n            ... on WorkflowNotFound {\n                message\n            }\n\n            ... on WorkflowCancelled {\n                message\n            }\n\n            ... on WorkflowNotReady {\n                message\n            }\n        }\n    }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
    source: "\n    query CourtsiteRescheduleStateByIdSpay($workflowId: ID!) {\n        courtsiteRescheduleStateById(workflowId: $workflowId) {\n            id\n            completed\n            expired\n            cancelled\n            created\n            capturePaymentState {\n                id\n                created\n                cancelled\n                completed\n                expired\n                amountToCapture\n                spayQrCode\n                paymentSessionCreated\n            }\n        }\n    }\n",
): (typeof documents)["\n    query CourtsiteRescheduleStateByIdSpay($workflowId: ID!) {\n        courtsiteRescheduleStateById(workflowId: $workflowId) {\n            id\n            completed\n            expired\n            cancelled\n            created\n            capturePaymentState {\n                id\n                created\n                cancelled\n                completed\n                expired\n                amountToCapture\n                spayQrCode\n                paymentSessionCreated\n            }\n        }\n    }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
    source: "\n    mutation forceRetryCapturePaymentSPayReschedule(\n        $request: ForceRetryCapturePaymentRequest!\n    ) {\n        forceRetryCapturePayment(request: $request) {\n            __typename\n            ... on CapturePaymentForceRetried {\n                state {\n                    tenantId\n                    invoiceId\n                    state\n                    amountToCapture\n                    completed\n                    expired\n                    cancelled\n                    created\n                    ...SPayDataReschedule\n                }\n            }\n\n            ... on WorkflowNotFound {\n                message\n            }\n\n            ... on WorkflowNotReady {\n                message\n            }\n        }\n    }\n",
): (typeof documents)["\n    mutation forceRetryCapturePaymentSPayReschedule(\n        $request: ForceRetryCapturePaymentRequest!\n    ) {\n        forceRetryCapturePayment(request: $request) {\n            __typename\n            ... on CapturePaymentForceRetried {\n                state {\n                    tenantId\n                    invoiceId\n                    state\n                    amountToCapture\n                    completed\n                    expired\n                    cancelled\n                    created\n                    ...SPayDataReschedule\n                }\n            }\n\n            ... on WorkflowNotFound {\n                message\n            }\n\n            ... on WorkflowNotReady {\n                message\n            }\n        }\n    }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
    source: "\n    fragment SPayDataReschedule on CapturePaymentState {\n        id\n        spayQrCode\n        paymentSessionCreated\n    }\n",
): (typeof documents)["\n    fragment SPayDataReschedule on CapturePaymentState {\n        id\n        spayQrCode\n        paymentSessionCreated\n    }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
    source: "\n    query pelangganRescheduleBookingInvoiceDetails(\n        $bookingIds: [ID!]!\n        $relatedEntityIds: [String]!\n    ) {\n        myBookingsByIds(bookingIds: $bookingIds) {\n            uid\n            startDt\n            endDt\n            created\n            confirmed\n            cancelled\n            metadata\n            resources {\n                uid\n                archived\n                resource {\n                    uid\n                    name\n                }\n            }\n            referenceId\n            tenant {\n                uid\n                name\n                metadata\n                location {\n                    latitude\n                    longitude\n                }\n            }\n            service {\n                uid\n                name\n                serviceMode\n                minRescheduleNotice\n                category {\n                    uid\n                    name\n                }\n            }\n        }\n        myInvoices(filter: { byRelatedEntityIds: $relatedEntityIds }) {\n            uid\n            associatedUsers {\n                userId\n            }\n            printNumber\n            outstandingBalance\n            metadata\n            cancelled\n            items {\n                uid\n                subtotal\n                description\n                perUnitPrice\n                quantity\n                created\n                relatedEntity {\n                    entityId\n                    entityType\n                }\n                metadata\n            }\n        }\n    }\n",
): (typeof documents)["\n    query pelangganRescheduleBookingInvoiceDetails(\n        $bookingIds: [ID!]!\n        $relatedEntityIds: [String]!\n    ) {\n        myBookingsByIds(bookingIds: $bookingIds) {\n            uid\n            startDt\n            endDt\n            created\n            confirmed\n            cancelled\n            metadata\n            resources {\n                uid\n                archived\n                resource {\n                    uid\n                    name\n                }\n            }\n            referenceId\n            tenant {\n                uid\n                name\n                metadata\n                location {\n                    latitude\n                    longitude\n                }\n            }\n            service {\n                uid\n                name\n                serviceMode\n                minRescheduleNotice\n                category {\n                    uid\n                    name\n                }\n            }\n        }\n        myInvoices(filter: { byRelatedEntityIds: $relatedEntityIds }) {\n            uid\n            associatedUsers {\n                userId\n            }\n            printNumber\n            outstandingBalance\n            metadata\n            cancelled\n            items {\n                uid\n                subtotal\n                description\n                perUnitPrice\n                quantity\n                created\n                relatedEntity {\n                    entityId\n                    entityType\n                }\n                metadata\n            }\n        }\n    }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
    source: "\n    query pelangganSearchPage {\n        categories {\n            uid\n            name\n            ...FeatureVenueCategories\n            ...CategoryListCategories\n            ...SearchBarCategories\n        }\n        locations {\n            uid\n            name\n            city\n            coordinates {\n                latitude\n                longitude\n            }\n            ...SearchBarLocations\n        }\n        courtsitePartners {\n            uid\n            name\n            ...CategoryListVenues\n            ...SearchBarVenues\n        }\n    }\n",
): (typeof documents)["\n    query pelangganSearchPage {\n        categories {\n            uid\n            name\n            ...FeatureVenueCategories\n            ...CategoryListCategories\n            ...SearchBarCategories\n        }\n        locations {\n            uid\n            name\n            city\n            coordinates {\n                latitude\n                longitude\n            }\n            ...SearchBarLocations\n        }\n        courtsitePartners {\n            uid\n            name\n            ...CategoryListVenues\n            ...SearchBarVenues\n        }\n    }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
    source: "\n    query pelangganSearchResultPage {\n        categories {\n            ...SearchBarCategories\n            ...SearchMobileCategories\n        }\n        locations {\n            ...SearchBarLocations\n            ...SearchMobileLocations\n        }\n        courtsitePartners {\n            ...SearchBarVenues\n            ...SearchMobileVenues\n        }\n    }\n",
): (typeof documents)["\n    query pelangganSearchResultPage {\n        categories {\n            ...SearchBarCategories\n            ...SearchMobileCategories\n        }\n        locations {\n            ...SearchBarLocations\n            ...SearchMobileLocations\n        }\n        courtsitePartners {\n            ...SearchBarVenues\n            ...SearchMobileVenues\n        }\n    }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
    source: "\n    query pelangganShareInvoicePageData($tenantId: ID!) {\n        organisation(uid: $tenantId) {\n            uid\n            name\n            metadata\n            isCourtsitePartner\n            location {\n                latitude\n                longitude\n            }\n        }\n        categories {\n            uid\n            name\n            metadata\n        }\n        myProfile {\n            firebaseId\n            name\n            phoneNumber\n            email\n        }\n    }\n",
): (typeof documents)["\n    query pelangganShareInvoicePageData($tenantId: ID!) {\n        organisation(uid: $tenantId) {\n            uid\n            name\n            metadata\n            isCourtsitePartner\n            location {\n                latitude\n                longitude\n            }\n        }\n        categories {\n            uid\n            name\n            metadata\n        }\n        myProfile {\n            firebaseId\n            name\n            phoneNumber\n            email\n        }\n    }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
    source: "\n    query invoiceSharePage($tenantId: ID!, $uid: ID!) {\n        invoice(uid: $uid, tenantId: $tenantId) {\n            uid\n            associatedUsers {\n                userId\n            }\n            invoiceeName\n            metadata\n            outstandingBalance\n            cancelled\n            items {\n                uid\n                description\n                quantity\n                perUnitPrice\n                subtotal\n                metadata\n                relatedEntity {\n                    uid\n                    entityId\n                    entityType\n                }\n            }\n        }\n    }\n",
): (typeof documents)["\n    query invoiceSharePage($tenantId: ID!, $uid: ID!) {\n        invoice(uid: $uid, tenantId: $tenantId) {\n            uid\n            associatedUsers {\n                userId\n            }\n            invoiceeName\n            metadata\n            outstandingBalance\n            cancelled\n            items {\n                uid\n                description\n                quantity\n                perUnitPrice\n                subtotal\n                metadata\n                relatedEntity {\n                    uid\n                    entityId\n                    entityType\n                }\n            }\n        }\n    }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
    source: "\n    mutation AddBookingGuestToBookingsDetails(\n        $tenantId: ID!\n        $bookingIds: [ID!]!\n        $guestName: String!\n        $phoneNumber: PhoneNumber\n        $email: EmailAddress\n        $userId: ID!\n    ) {\n        addBookingGuestToBookings(\n            request: {\n                tenantId: $tenantId\n                bookingIds: $bookingIds\n                guestName: $guestName\n                phoneNumber: $phoneNumber\n                email: $email\n                userId: $userId\n            }\n        ) {\n            __typename\n            ... on BookingGuestAddedToBookings {\n                bookingIds\n            }\n            ... on AccessError {\n                message\n            }\n            ... on NotFoundError {\n                message\n            }\n        }\n    }\n",
): (typeof documents)["\n    mutation AddBookingGuestToBookingsDetails(\n        $tenantId: ID!\n        $bookingIds: [ID!]!\n        $guestName: String!\n        $phoneNumber: PhoneNumber\n        $email: EmailAddress\n        $userId: ID!\n    ) {\n        addBookingGuestToBookings(\n            request: {\n                tenantId: $tenantId\n                bookingIds: $bookingIds\n                guestName: $guestName\n                phoneNumber: $phoneNumber\n                email: $email\n                userId: $userId\n            }\n        ) {\n            __typename\n            ... on BookingGuestAddedToBookings {\n                bookingIds\n            }\n            ... on AccessError {\n                message\n            }\n            ... on NotFoundError {\n                message\n            }\n        }\n    }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
    source: "\n    mutation RemoveBookingGuestToBookingDetails(\n        $tenantId: ID!\n        $bookingIds: [ID!]!\n        $userId: ID!\n    ) {\n        removeBookingGuest(\n            request: {\n                tenantId: $tenantId\n                bookingIds: $bookingIds\n                userId: $userId\n            }\n        ) {\n            __typename\n            ... on BookingGuestRemoved {\n                bookingIds\n            }\n            ... on AccessError {\n                message\n            }\n            ... on NotFoundError {\n                message\n            }\n        }\n    }\n",
): (typeof documents)["\n    mutation RemoveBookingGuestToBookingDetails(\n        $tenantId: ID!\n        $bookingIds: [ID!]!\n        $userId: ID!\n    ) {\n        removeBookingGuest(\n            request: {\n                tenantId: $tenantId\n                bookingIds: $bookingIds\n                userId: $userId\n            }\n        ) {\n            __typename\n            ... on BookingGuestRemoved {\n                bookingIds\n            }\n            ... on AccessError {\n                message\n            }\n            ... on NotFoundError {\n                message\n            }\n        }\n    }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
    source: "\n    query userBookingsCount {\n        myBookingFilterCount {\n            categoryId\n            bookingCount\n        }\n        categories {\n            uid\n            name\n        }\n    }\n",
): (typeof documents)["\n    query userBookingsCount {\n        myBookingFilterCount {\n            categoryId\n            bookingCount\n        }\n        categories {\n            uid\n            name\n        }\n    }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
    source: "\n    query pelangganUserBookingsDetailsNew($bookingIds: [ID!]!) {\n        myBookingsByIds(bookingIds: $bookingIds) {\n            uid\n            tenantId\n            referenceId\n            startDt\n            endDt\n            confirmed\n            cancelled\n            cancelledReason\n            metadata\n            bookingSource\n            service {\n                uid\n                serviceMode\n                minRescheduleNotice\n                category {\n                    uid\n                    name\n                }\n            }\n            resources {\n                uid\n                archived\n                resource {\n                    uid\n                    name\n                }\n            }\n            guests {\n                userId\n            }\n        }\n        myInvoicesByRelatedEntityIds(relatedEntityIds: $bookingIds) {\n            uid\n            tenantId\n            printNumber\n            total\n            outstandingBalance\n            cancelled\n            created\n        }\n    }\n",
): (typeof documents)["\n    query pelangganUserBookingsDetailsNew($bookingIds: [ID!]!) {\n        myBookingsByIds(bookingIds: $bookingIds) {\n            uid\n            tenantId\n            referenceId\n            startDt\n            endDt\n            confirmed\n            cancelled\n            cancelledReason\n            metadata\n            bookingSource\n            service {\n                uid\n                serviceMode\n                minRescheduleNotice\n                category {\n                    uid\n                    name\n                }\n            }\n            resources {\n                uid\n                archived\n                resource {\n                    uid\n                    name\n                }\n            }\n            guests {\n                userId\n            }\n        }\n        myInvoicesByRelatedEntityIds(relatedEntityIds: $bookingIds) {\n            uid\n            tenantId\n            printNumber\n            total\n            outstandingBalance\n            cancelled\n            created\n        }\n    }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
    source: "\n    query pelangganUserBookingsDetailsOrg($tenantId: ID!) {\n        organisation(uid: $tenantId) {\n            uid\n            name\n            location {\n                latitude\n                longitude\n            }\n            metadata\n        }\n    }\n",
): (typeof documents)["\n    query pelangganUserBookingsDetailsOrg($tenantId: ID!) {\n        organisation(uid: $tenantId) {\n            uid\n            name\n            location {\n                latitude\n                longitude\n            }\n            metadata\n        }\n    }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
    source: "\n    query pelangganUserBookingsDetailsWebCheckout($workflowId: ID!) {\n        courtsiteCheckoutStateById(checkoutId: $workflowId) {\n            id\n            cancelled\n            expired\n            created\n            state\n        }\n    }\n",
): (typeof documents)["\n    query pelangganUserBookingsDetailsWebCheckout($workflowId: ID!) {\n        courtsiteCheckoutStateById(checkoutId: $workflowId) {\n            id\n            cancelled\n            expired\n            created\n            state\n        }\n    }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
    source: "\n    query pelangganUserBookingsDetailsWebCheckoutReschedule($workflowId: ID!) {\n        courtsiteRescheduleStateById(workflowId: $workflowId) {\n            id\n            created\n            cancelled\n            expired\n            state\n        }\n    }\n",
): (typeof documents)["\n    query pelangganUserBookingsDetailsWebCheckoutReschedule($workflowId: ID!) {\n        courtsiteRescheduleStateById(workflowId: $workflowId) {\n            id\n            created\n            cancelled\n            expired\n            state\n        }\n    }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
    source: "\n    query userGamesCount {\n        myGameFilterCount {\n            categoryId\n            gameCount\n        }\n        categories {\n            uid\n            name\n        }\n    }\n",
): (typeof documents)["\n    query userGamesCount {\n        myGameFilterCount {\n            categoryId\n            gameCount\n        }\n        categories {\n            uid\n            name\n        }\n    }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
    source: "\n    query getUserProfile($startOfYear: DateTime) {\n        myProfile {\n            firebaseId\n            name\n            phoneNumber\n            email\n            gender\n            dateOfBirth\n            postCode\n            joined\n            preferredSports\n            otherPreferredSports\n            sportsFrequency\n        }\n        categoryTags {\n            uid\n            name\n            categories {\n                uid\n                name\n            }\n        }\n        myInvoiceStatistics(filter: { byCreatedAfter: $startOfYear }) {\n            invoicePerYear\n        }\n        myGameStatistics {\n            gamesMade\n        }\n        myBookingStatistics {\n            bookingsHours\n            bookingsMade\n        }\n    }\n",
): (typeof documents)["\n    query getUserProfile($startOfYear: DateTime) {\n        myProfile {\n            firebaseId\n            name\n            phoneNumber\n            email\n            gender\n            dateOfBirth\n            postCode\n            joined\n            preferredSports\n            otherPreferredSports\n            sportsFrequency\n        }\n        categoryTags {\n            uid\n            name\n            categories {\n                uid\n                name\n            }\n        }\n        myInvoiceStatistics(filter: { byCreatedAfter: $startOfYear }) {\n            invoicePerYear\n        }\n        myGameStatistics {\n            gamesMade\n        }\n        myBookingStatistics {\n            bookingsHours\n            bookingsMade\n        }\n    }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
    source: "\n    query getMyDetailsUpcomingBooking($now: DateTime) {\n        myGroupedBookings(\n            filter: {\n                byStatus: { confirmed: true, unconfirmed: true }\n                byStartDateAfter: $now\n            }\n            limit: 1\n            order: ASC\n        ) {\n            edges {\n                node {\n                    referenceId\n                    startDt\n                    bookings {\n                        uid\n                        created\n                        tenantId\n                        startDt\n                        endDt\n                        confirmed\n                        cancelled\n                        service {\n                            uid\n                            serviceMode\n                            categoryName\n                        }\n                        resources {\n                            resourceId\n                            resourceName\n                        }\n                        metadata\n                    }\n                }\n            }\n        }\n        myGames(filter: { startDateAfter: $now }, limit: 1, order: ASC) {\n            edges {\n                node {\n                    name\n                    location {\n                        tenantId\n                    }\n                    startDt\n                    endDt\n                    archived\n                    categoryId\n                    metadata\n                    uid\n                    players {\n                        displayPicture\n                    }\n                    maxPlayers\n                    reservedPlayers\n                    organizer {\n                        organizerId\n                    }\n                }\n            }\n        }\n    }\n",
): (typeof documents)["\n    query getMyDetailsUpcomingBooking($now: DateTime) {\n        myGroupedBookings(\n            filter: {\n                byStatus: { confirmed: true, unconfirmed: true }\n                byStartDateAfter: $now\n            }\n            limit: 1\n            order: ASC\n        ) {\n            edges {\n                node {\n                    referenceId\n                    startDt\n                    bookings {\n                        uid\n                        created\n                        tenantId\n                        startDt\n                        endDt\n                        confirmed\n                        cancelled\n                        service {\n                            uid\n                            serviceMode\n                            categoryName\n                        }\n                        resources {\n                            resourceId\n                            resourceName\n                        }\n                        metadata\n                    }\n                }\n            }\n        }\n        myGames(filter: { startDateAfter: $now }, limit: 1, order: ASC) {\n            edges {\n                node {\n                    name\n                    location {\n                        tenantId\n                    }\n                    startDt\n                    endDt\n                    archived\n                    categoryId\n                    metadata\n                    uid\n                    players {\n                        displayPicture\n                    }\n                    maxPlayers\n                    reservedPlayers\n                    organizer {\n                        organizerId\n                    }\n                }\n            }\n        }\n    }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
    source: "\n    query getMyDetailsLastBooking {\n        myGroupedBookings(\n            filter: { byStatus: { confirmed: true, unconfirmed: true } }\n            limit: 1\n            order: DESC\n        ) {\n            edges {\n                node {\n                    referenceId\n                    startDt\n                    bookings {\n                        uid\n                        created\n                        tenantId\n                        startDt\n                        endDt\n                        confirmed\n                        cancelled\n                        service {\n                            uid\n                            serviceMode\n                            categoryName\n                        }\n                        resources {\n                            resourceId\n                            resourceName\n                        }\n                        metadata\n                    }\n                }\n            }\n        }\n    }\n",
): (typeof documents)["\n    query getMyDetailsLastBooking {\n        myGroupedBookings(\n            filter: { byStatus: { confirmed: true, unconfirmed: true } }\n            limit: 1\n            order: DESC\n        ) {\n            edges {\n                node {\n                    referenceId\n                    startDt\n                    bookings {\n                        uid\n                        created\n                        tenantId\n                        startDt\n                        endDt\n                        confirmed\n                        cancelled\n                        service {\n                            uid\n                            serviceMode\n                            categoryName\n                        }\n                        resources {\n                            resourceId\n                            resourceName\n                        }\n                        metadata\n                    }\n                }\n            }\n        }\n    }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
    source: "\n    query getMyDetailsLastGame {\n        myGames(limit: 1, order: DESC) {\n            edges {\n                node {\n                    name\n                    location {\n                        tenantId\n                    }\n                    startDt\n                    endDt\n                    archived\n                    categoryId\n                    metadata\n                    uid\n                    players {\n                        displayPicture\n                    }\n                    maxPlayers\n                    reservedPlayers\n                    organizer {\n                        organizerId\n                    }\n                }\n            }\n        }\n    }\n",
): (typeof documents)["\n    query getMyDetailsLastGame {\n        myGames(limit: 1, order: DESC) {\n            edges {\n                node {\n                    name\n                    location {\n                        tenantId\n                    }\n                    startDt\n                    endDt\n                    archived\n                    categoryId\n                    metadata\n                    uid\n                    players {\n                        displayPicture\n                    }\n                    maxPlayers\n                    reservedPlayers\n                    organizer {\n                        organizerId\n                    }\n                }\n            }\n        }\n    }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
    source: "\n    query pelangganUserInvoice($invoiceId: ID!) {\n        myInvoice(invoiceId: $invoiceId) {\n            uid\n            total\n            outstandingBalance\n            amountPaid\n            cancelled\n            created\n            metadata\n            invoiceeName\n            tenantId\n            printNumber\n            isCourtsiteOwned\n            tenant {\n                uid\n                name\n                location {\n                    latitude\n                    longitude\n                }\n                metadata\n            }\n            items {\n                uid\n                description\n                perUnitPrice\n                quantity\n                subtotal\n                relatedEntity {\n                    uid\n                    entityId\n                    entityType\n                }\n                metadata\n                created\n            }\n            payments {\n                uid\n                created\n                cancelled\n                amount\n                paymentType\n                paymentMethod {\n                    uid\n                    name\n                }\n            }\n        }\n    }\n",
): (typeof documents)["\n    query pelangganUserInvoice($invoiceId: ID!) {\n        myInvoice(invoiceId: $invoiceId) {\n            uid\n            total\n            outstandingBalance\n            amountPaid\n            cancelled\n            created\n            metadata\n            invoiceeName\n            tenantId\n            printNumber\n            isCourtsiteOwned\n            tenant {\n                uid\n                name\n                location {\n                    latitude\n                    longitude\n                }\n                metadata\n            }\n            items {\n                uid\n                description\n                perUnitPrice\n                quantity\n                subtotal\n                relatedEntity {\n                    uid\n                    entityId\n                    entityType\n                }\n                metadata\n                created\n            }\n            payments {\n                uid\n                created\n                cancelled\n                amount\n                paymentType\n                paymentMethod {\n                    uid\n                    name\n                }\n            }\n        }\n    }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
    source: "\n    query centreDetailsAndCategories($tenantId: ID!) {\n        organisation(uid: $tenantId) {\n            uid\n            name\n            isCourtsitePartner\n            metadata\n            services\n        }\n        categories {\n            uid\n            name\n        }\n    }\n",
): (typeof documents)["\n    query centreDetailsAndCategories($tenantId: ID!) {\n        organisation(uid: $tenantId) {\n            uid\n            name\n            isCourtsitePartner\n            metadata\n            services\n        }\n        categories {\n            uid\n            name\n        }\n    }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
    source: "\n    query ValidateTaxpayerTin(\n        $tin: String!\n        $idType: IdType!\n        $idValue: String!\n    ) {\n        validateTaxpayerTin(tin: $tin, idType: $idType, idValue: $idValue) {\n            __typename\n            ... on TaxPayerTINValidated {\n                tin\n                idType\n                idValue\n            }\n            ... on AccessError {\n                message\n            }\n            ... on InternalError {\n                message\n            }\n        }\n    }\n",
): (typeof documents)["\n    query ValidateTaxpayerTin(\n        $tin: String!\n        $idType: IdType!\n        $idValue: String!\n    ) {\n        validateTaxpayerTin(tin: $tin, idType: $idType, idValue: $idValue) {\n            __typename\n            ... on TaxPayerTINValidated {\n                tin\n                idType\n                idValue\n            }\n            ... on AccessError {\n                message\n            }\n            ... on InternalError {\n                message\n            }\n        }\n    }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
    source: "\n    query invoiceLHDNRequest($invoiceId: ID!) {\n        myInvoice(invoiceId: $invoiceId) {\n            uid\n            total\n            outstandingBalance\n            payments {\n                uid\n                created\n                cancelled\n                paymentType\n                paymentMethod {\n                    uid\n                    name\n                }\n            }\n        }\n    }\n",
): (typeof documents)["\n    query invoiceLHDNRequest($invoiceId: ID!) {\n        myInvoice(invoiceId: $invoiceId) {\n            uid\n            total\n            outstandingBalance\n            payments {\n                uid\n                created\n                cancelled\n                paymentType\n                paymentMethod {\n                    uid\n                    name\n                }\n            }\n        }\n    }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
    source: "\n    query pelangganUserInvoices($status: InvoiceStatus, $cursor: String) {\n        myInvoicesPaged(\n            filter: { byStatus: $status }\n            limit: 10\n            after: $cursor\n            order: DESC\n        ) {\n            edges {\n                node {\n                    uid\n                    created\n                    ...UserInvoicesDetails\n                }\n            }\n            pageInfo {\n                hasNextPage\n                endCursor\n            }\n        }\n    }\n",
): (typeof documents)["\n    query pelangganUserInvoices($status: InvoiceStatus, $cursor: String) {\n        myInvoicesPaged(\n            filter: { byStatus: $status }\n            limit: 10\n            after: $cursor\n            order: DESC\n        ) {\n            edges {\n                node {\n                    uid\n                    created\n                    ...UserInvoicesDetails\n                }\n            }\n            pageInfo {\n                hasNextPage\n                endCursor\n            }\n        }\n    }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
    source: "\n    query pelangganUserInvoiceStatistics(\n        $startOfYear: DateTime\n        $pastStartOfYear: DateTime\n        $pastEndOfYear: DateTime\n    ) {\n        currentYear: myInvoiceStatistics(\n            filter: { byCreatedAfter: $startOfYear }\n        ) {\n            invoicePerYear\n        }\n        pastYear: myInvoiceStatistics(\n            filter: {\n                byCreatedAfter: $pastStartOfYear\n                byCreatedBefore: $pastEndOfYear\n            }\n        ) {\n            invoicePerYear\n        }\n    }\n",
): (typeof documents)["\n    query pelangganUserInvoiceStatistics(\n        $startOfYear: DateTime\n        $pastStartOfYear: DateTime\n        $pastEndOfYear: DateTime\n    ) {\n        currentYear: myInvoiceStatistics(\n            filter: { byCreatedAfter: $startOfYear }\n        ) {\n            invoicePerYear\n        }\n        pastYear: myInvoiceStatistics(\n            filter: {\n                byCreatedAfter: $pastStartOfYear\n                byCreatedBefore: $pastEndOfYear\n            }\n        ) {\n            invoicePerYear\n        }\n    }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
    source: "\n    fragment UserInvoicesDetails on Invoice {\n        uid\n        outstandingBalance\n        total\n        created\n        tenant {\n            uid\n            name\n        }\n    }\n",
): (typeof documents)["\n    fragment UserInvoicesDetails on Invoice {\n        uid\n        outstandingBalance\n        total\n        created\n        tenant {\n            uid\n            name\n        }\n    }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
    source: "\n    mutation pelangganUserProfileSetUserProfile(\n        $request: SetUserProfileRequest!\n    ) {\n        setUserProfile(request: $request) {\n            __typename\n            ... on UserProfileSet {\n                user {\n                    firebaseId\n                }\n            }\n            ... on AccessError {\n                message\n            }\n\n            ... on InvalidValue {\n                message\n            }\n        }\n    }\n",
): (typeof documents)["\n    mutation pelangganUserProfileSetUserProfile(\n        $request: SetUserProfileRequest!\n    ) {\n        setUserProfile(request: $request) {\n            __typename\n            ... on UserProfileSet {\n                user {\n                    firebaseId\n                }\n            }\n            ... on AccessError {\n                message\n            }\n\n            ... on InvalidValue {\n                message\n            }\n        }\n    }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
    source: "\n    query pelangganUserProfile {\n        myProfile {\n            firebaseId\n            name\n            phoneNumber\n            email\n            gender\n            dateOfBirth\n            postCode\n            preferredSports\n            otherPreferredSports\n            sportsFrequency\n        }\n        categories {\n            uid\n            name\n        }\n    }\n",
): (typeof documents)["\n    query pelangganUserProfile {\n        myProfile {\n            firebaseId\n            name\n            phoneNumber\n            email\n            gender\n            dateOfBirth\n            postCode\n            preferredSports\n            otherPreferredSports\n            sportsFrequency\n        }\n        categories {\n            uid\n            name\n        }\n    }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
    source: "\n    query pelangganUserUpcomingCategories {\n        categories {\n            uid\n            name\n        }\n    }\n",
): (typeof documents)["\n    query pelangganUserUpcomingCategories {\n        categories {\n            uid\n            name\n        }\n    }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
    source: "\n    query bookAgainSectionUpcomingQuery($limit: Int) {\n        tenantIdsFromPastBookings(limit: $limit)\n        courtsitePartners {\n            uid\n            name\n            metadata\n        }\n    }\n",
): (typeof documents)["\n    query bookAgainSectionUpcomingQuery($limit: Int) {\n        tenantIdsFromPastBookings(limit: $limit)\n        courtsitePartners {\n            uid\n            name\n            metadata\n        }\n    }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
    source: "\n    query userUpcomingBookingsInvoices($filter: MyInvoicesFilter) {\n        myInvoices(filter: $filter) {\n            uid\n            items {\n                uid\n                relatedEntity {\n                    uid\n                    entityId\n                }\n            }\n        }\n    }\n",
): (typeof documents)["\n    query userUpcomingBookingsInvoices($filter: MyInvoicesFilter) {\n        myInvoices(filter: $filter) {\n            uid\n            items {\n                uid\n                relatedEntity {\n                    uid\n                    entityId\n                }\n            }\n        }\n    }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
    source: "\n    query userUpcomingBookingCount($startsAfter: DateTime) {\n        myBookingFilterCount(startsAfter: $startsAfter) {\n            bookingCount\n            categoryId\n        }\n    }\n",
): (typeof documents)["\n    query userUpcomingBookingCount($startsAfter: DateTime) {\n        myBookingFilterCount(startsAfter: $startsAfter) {\n            bookingCount\n            categoryId\n        }\n    }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
    source: "\n    query userUpcomingGameCount($startsAfter: DateTime) {\n        myGameFilterCount(startsAfter: $startsAfter) {\n            gameCount\n            categoryId\n        }\n    }\n",
): (typeof documents)["\n    query userUpcomingGameCount($startsAfter: DateTime) {\n        myGameFilterCount(startsAfter: $startsAfter) {\n            gameCount\n            categoryId\n        }\n    }\n"];

export function graphql(source: string) {
    return (documents as any)[source] ?? {};
}

export type DocumentType<TDocumentNode extends DocumentNode<any, any>> =
    TDocumentNode extends DocumentNode<infer TType, any> ? TType : never;
