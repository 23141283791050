import { useState, useEffect, useMemo } from "react";
import { GetStaticProps } from "next";
import Head from "next/head";
import Link from "next/link";
import { cx } from "class-variance-authority";
import { useRouter } from "next/router";
import { useTranslation } from "react-i18next";

import { Button } from "@/components/Button";
import { LanguagePopup } from "@/components/LanguageSelector";
import { DesktopFooter } from "@/components/DesktopFooter";
import {
    HomepageUpcomingCard,
    VenueOptionsProps,
    VenueSheet,
} from "@/components/HomePage/v2";
import { Section } from "@/components/Section";
import { ScrollArea } from "@/components/ScrollArea";
import { TFunction } from "i18next";

import { PendingPayments } from "@/components/HomePage";
import BottomTabBar from "@/components/BottomTabBar";
import {
    Carousel,
    CarouselContent,
    CarouselItem,
    CarouselDots,
} from "@/components/Carousel";
import Autoplay from "embla-carousel-autoplay";
import { ArrowRightUnfilled } from "@/components/Icons";
import { FilterOptions, FilterRadioGroup } from "@/components/Filter";
import {
    SearchBarForm,
    FormValues,
    FormQueryParams,
} from "@/components/SearchPage";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { GameCard } from "@/components/Cards/GameCard";
import { DesktopHeader } from "@/components/DesktopHeader";
import {
    FeatureVenues,
    FeatureVenuesSlice,
    featureVenueCategoriesFragment,
} from "@/components/FeatureVenues";

import dayjs from "@/lib/dayjs";
import { Image } from "@/lib/imgproxy";
import { RichText, RichTextBlock } from "prismic-reactjs";
import { LogomarkWithNameWhite } from "@/assets/vectors/logo";
import { FragmentType, getFragment, graphql } from "@/lib/gql";
import { useQuery } from "@apollo/client";
import { unique } from "@/utils/index";
import { useAuth } from "@/lib/firebase/hooks";
import { BlankLayoutV2 } from "@/layouts/BlankLayout";
import { CategoryListContext } from "@/components/CategoryList";
import { useBreakpoint } from "@/utils/breakpoints";
import {
    HomepagePublicGameQuery,
    PelangganHomePageQuery,
} from "@/lib/gql/graphql";
import { PrismicDocument } from "@prismicio/client";
import { getCentreImage } from "@/utils/centre";
import {
    getCategoryListDoc,
    getFeatureVenueDoc,
    getHomePageDoc,
} from "@/lib/prismic";

const query = graphql(`
    query pelangganHomePage(
        $now: DateTime!
        $startsAfter: DateTime!
        $isLoggedIn: Boolean!
    ) {
        upcomingBookings: myGroupedBookings(
            filter: {
                byStatus: { confirmed: true, unconfirmed: true }
                byStartDateAfter: $now
            }
            limit: 3
            order: ASC
        ) @include(if: $isLoggedIn) {
            edges {
                node {
                    referenceId
                    startDt
                    endDt
                    bookings {
                        tenant {
                            uid
                            name
                        }
                        uid
                        created
                        tenantId
                        startDt
                        endDt
                        confirmed
                        cancelled
                        service {
                            uid
                            serviceMode
                            categoryName
                        }
                        resources {
                            resourceId
                            resourceName
                        }
                        metadata
                    }
                }
            }
        }
        publicGameCount(startsAfter: $startsAfter) {
            categoryId
            gameCount
            locationTenantIds
        }
        categories {
            ...FeatureVenueCategories
            ...SocialGamesCategories
            ...SearchBarCategories
        }
        tenantIdsFromPastBookings(limit: 3) @include(if: $isLoggedIn)
        courtsitePartners(sortOrder: BY_WEIGHTAGE_DESC) {
            uid
            name
            metadata
            ...SearchBarVenues
        }
        locations {
            ...SearchBarLocations
        }
    }
`);

const publicGamesQuery = graphql(`
    query homepagePublicGame($filter: PublicGamesFilter, $page: PageInput) {
        publicGames(filter: $filter, page: $page) {
            edges {
                node {
                    uid
                    name
                    categoryId
                    startDt
                    endDt
                    maxPlayers
                    location {
                        tenant {
                            uid
                            name
                        }
                    }
                    organizer {
                        name
                    }
                    players {
                        displayPicture
                    }
                    metadata
                    reservedPlayers
                }
            }
        }
    }
`);

const socialGamesCategoriesFragment = graphql(`
    fragment SocialGamesCategories on Category {
        uid
        name
    }
`);

type PageDocProps = {
    homePageDoc: PrismicDocument;
    featureVenueDoc: PrismicDocument;
    categoryList: PrismicDocument;
};
type HomePageProps = { pageDoc: PageDocProps };
type PrismicLink = {
    link_type?: "Web" | "Document" | "Media" | "Any";
    url?: string;
    target?: string;
    id?: string;
    uid?: string;
    isBroken?: boolean;
    lang?: string;
    slug?: string;
    tags?: string[];
    type?: string;
    height?: string;
    kind?: string;
    name?: string;
    size?: string;
    width?: string;
};
type DealsSlice = {
    slice_type: "deals";
    primary: {
        id: RichTextBlock[];
    };
    items: {
        title_en: RichTextBlock[];
        title_my: RichTextBlock[];
        title_zh: RichTextBlock[];
        description_en: RichTextBlock[];
        description_my: RichTextBlock[];
        description_zh: RichTextBlock[];
        img: RichTextBlock;
        link: PrismicLink;
    }[];
};
type InfoHubSlice = {
    slice_type: "info_hub";
    primary: {
        id: RichTextBlock[];
        title_en: RichTextBlock[];
        title_my: RichTextBlock[];
        title_zh: RichTextBlock[];
    };
    items: {
        link: PrismicLink;
        description_en: RichTextBlock[];
        description_my: RichTextBlock[];
        description_zh: RichTextBlock[];
        img: RichTextBlock;
    }[];
};
type HomePagePrismicData = {
    structure: { id: RichTextBlock[] }[];
    body: (DealsSlice | InfoHubSlice)[];
};
type TFunc = TFunction<["pages/index", "common"]>;
type SocialGamesProp = {
    filterGame?: string;
    setFilterGame: (v?: string) => void;
    publicGameCount: PelangganHomePageQuery["publicGameCount"];
    categories: FragmentType<typeof socialGamesCategoriesFragment>[];
    publicGames?: HomepagePublicGameQuery;
    t: TFunc;
};
type Centre = {
    name: string;
    uid: string;
    cityAndState: string;
    image?: string;
};
type HomePageSectionProps = {
    title: string;
    description?: string;
    className?: string;
    children?: React.ReactNode;
    show: boolean;
};
type Lang = { en: string; my: string; zh: string };
type DealsCarouselProp = {
    title: Lang;
    description: Lang;
    imageUrl: string;
    link: string;
};

const HomePage = ({ pageDoc }: HomePageProps): JSX.Element => {
    const { user } = useAuth();
    const router = useRouter();
    const { lg: isDesktop } = useBreakpoint();
    const { homePageDoc, featureVenueDoc, categoryList } = pageDoc;
    const { i18n, t } = useTranslation(["pages/index", "common"]);
    const lang = i18n.language || "en";
    const form = useForm<FormValues>({ mode: "onChange" });
    const [currentTime] = useState(new Date());
    const [filterGame, setFilterGame] = useState<string | undefined>("all");
    const { data, loading } = useQuery(query, {
        fetchPolicy: "cache-and-network",
        variables: {
            now: currentTime.toISOString(),
            startsAfter: currentTime.toISOString(),
            isLoggedIn: !!user,
        },
    });
    const { data: publicGames } = useQuery(publicGamesQuery, {
        variables: {
            filter: {
                categoryId: filterGame == "all" ? undefined : filterGame,
            },
            page: { limit: 3 },
        },
        fetchPolicy: "cache-and-network",
    });

    const prismicData = homePageDoc.data as HomePagePrismicData;
    const featureVenue = featureVenueDoc.data as FeatureVenuesSlice;

    const upcomingBookingsData = data?.upcomingBookings;
    const categories = data?.categories ?? [];
    const socialGames = {
        filterGame,
        setFilterGame: (v?: string) => setFilterGame(v),
        publicGameCount: data?.publicGameCount ?? [],
        categories,
        publicGames,
        t: t,
    };

    const pastBookedCentres = useMemo(() => {
        if (!data?.tenantIdsFromPastBookings) return [];
        const pastBookingsTenantIds = data.tenantIdsFromPastBookings ?? [];
        return data.courtsitePartners
            .filter((c) => pastBookingsTenantIds.includes(c.uid))
            .map((c) => ({
                ...c,
                cityAndState: JSON.parse(c.metadata).cityAndState ?? "",
                image: getCentreImage(c.metadata),
            }));
    }, [data]);

    const venueOptions: VenueOptionsProps[] | undefined = useMemo(
        () =>
            data?.courtsitePartners.map((v) => ({
                id: v.uid,
                text: v.name,
                link: `/centre/${v.name}/${v.uid}`,
            })),
        [data?.courtsitePartners],
    );

    const onSubmit: SubmitHandler<FormValues> = async (v) => {
        if (!v.categoryId && !v.locationId) {
            router.push({ pathname: "/search" });
            return;
        }
        const query: FormQueryParams = {};
        if (v.locationId) query.locationId = v.locationId;
        if (v.categoryId) query.categoryId = v.categoryId;
        if (v.dtrange?.date) query.date = v.dtrange.date;
        if (v.dtrange?.time) query.time = v.dtrange.time;
        if (v.dtrange?.meridiem) query.meridiem = v.dtrange.meridiem;
        if (v.dtrange?.duration) query.duration = v.dtrange.duration;
        router.push({ pathname: "/search/result", query });
    };

    return (
        <div>
            <DesktopHeader />
            <Head>
                <title>Courtsite</title>
            </Head>
            <PendingPayments isDesktop={isDesktop} />
            <div className="flex w-full flex-col items-center bg-white pb-[100px] md:p-0 lg:bg-inherit">
                <div className="relative mb-10 hidden w-full justify-center lg:flex">
                    <div className="relative h-[500px] w-full bg-cover bg-center">
                        <Image
                            alt="homepage_cover_image"
                            src="/images/cover.jpg"
                            unoptimized
                            layout="fill"
                            objectFit="cover"
                        />
                        <div className="absolute h-full w-full bg-gradient-to-t from-[#00000080] to-[#00000080]" />
                    </div>
                    <div className="absolute top-[235px] mx-auto flex w-full flex-col items-center gap-8">
                        <div className="flex flex-col items-center gap-3">
                            <div className="typography-display-sm font-bold text-white">
                                {t(
                                    "headerTitle",
                                    "Get Active, Book Your Games Now",
                                )}
                            </div>
                            <div className="typography-h3 text-center text-white">
                                {t(
                                    "headerDescription",
                                    "From favorites like badminton and futsal to trendy pickleball and frisbee, play all kinds of sports nationwide!",
                                )}
                            </div>
                        </div>
                        <div className="w-full lg:max-w-[1056px]">
                            <FormProvider {...form}>
                                <CategoryListContext.Provider
                                    value={categoryList}
                                >
                                    <SearchBarForm
                                        onSubmit={onSubmit}
                                        categoriesFragment={data?.categories}
                                        locationsFragment={data?.locations}
                                        venuesFragement={
                                            data?.courtsitePartners
                                        }
                                    />
                                </CategoryListContext.Provider>
                            </FormProvider>
                        </div>
                    </div>
                </div>
                <div className="w-screen lg:max-w-[1056px]">
                    <div className="relative lg:hidden">
                        <div className="relative inset-0 aspect-video w-full bg-cover bg-center">
                            <Image
                                alt="homepage_cover_image"
                                src="/images/cover.jpg"
                                unoptimized
                                layout="fill"
                                objectFit="cover"
                            />
                            <div className="absolute h-full w-full bg-gradient-to-t from-[rgba(0,0,0,0)] to-[rgba(0,0,0,0.5)]" />
                        </div>
                        <div className="absolute inset-0 z-10">
                            <div className="flex h-[75px] w-full justify-between bg-transparent p-4 pt-7 lg:hidden">
                                <LogomarkWithNameWhite />
                                <LanguagePopup />
                            </div>
                        </div>
                    </div>
                    <div className="relative z-10 mt-[-30px] px-4 lg:hidden">
                        <VenueSheet venueOptions={venueOptions} />
                    </div>
                    <HomeFeatureSection t={t} />
                    <Section className="flex flex-col gap-2 pt-0 lg:gap-5 lg:py-8">
                        <UpcomingBookingsSection
                            upcomingBookingsData={upcomingBookingsData}
                            loading={loading}
                            t={t}
                        />
                        <BookAgainSection
                            pastBookedCentres={pastBookedCentres}
                            loading={loading}
                            t={t}
                        />
                        <PrismicStructure
                            homepageData={prismicData}
                            featureVenueData={featureVenue}
                            categories={categories}
                            loading={loading}
                            lang={lang}
                            t={t}
                        />
                        <div className="hidden lg:contents">
                            <SocialGames {...socialGames} />
                        </div>
                    </Section>
                </div>
            </div>
            <BottomTabBar activeTab="home" />
            <DesktopFooter />
        </div>
    );
};
HomePage.Layout = BlankLayoutV2;
export default HomePage;

const HomeFeatureSection = ({ t }: { t: TFunc }): JSX.Element => {
    const homeFeatureButtons = [
        {
            alt: "Sport",
            src: "/images/homeIcons/play.jpg",
            text: t("common:sport", "Sport"),
            href: "/explore/category",
        },
        {
            alt: "Book",
            src: "/images/homeIcons/book.jpg",
            text: t("common:book", "Book"),
            href: "/search",
        },
        {
            alt: "Join",
            src: "/images/homeIcons/join.jpg",
            text: t("common:join", "Join"),
            href: "/explore/games",
        },
        {
            alt: "Deals",
            src: "/images/homeIcons/deals.jpg",
            text: t("common:deals", "Deals"),
            href: "/deals",
        },
    ];

    return (
        <div className="flex w-full justify-center lg:hidden">
            <div className="grid grid-cols-4 gap-[21px] px-4 pb-4 pt-3">
                {homeFeatureButtons.map((b) => (
                    <div key={b.alt}>
                        <Link href={b.href}>
                            <a className="remove-styles-a flex flex-col items-center gap-2">
                                <div className="flex justify-center rounded-lg bg-white p-4 shadow-sm">
                                    <div className="relative size-10">
                                        <Image
                                            alt={b.alt}
                                            src={b.src}
                                            unoptimized
                                            layout="fill"
                                            objectFit="cover"
                                        />
                                    </div>
                                </div>
                                <span className="typography-sub mx-auto mt-auto capitalize text-blue-grey-800 hover:text-blue-grey-800">
                                    {b.text}
                                </span>
                            </a>
                        </Link>
                    </div>
                ))}
            </div>
        </div>
    );
};

const UpcomingBookingsSection = ({
    upcomingBookingsData,
    loading,
    t,
}: {
    upcomingBookingsData?: PelangganHomePageQuery["upcomingBookings"];
    loading: boolean;
    t: TFunc;
}): JSX.Element => {
    const upcomingBookings =
        upcomingBookingsData?.edges.map((e) => {
            const { bookings, startDt, endDt, referenceId } = e.node;
            const firstBooking = bookings[0];
            if (!firstBooking) {
                throw new Error("error with grouped bookings");
            }
            const resources = unique(
                bookings.flatMap((b) =>
                    b.resources.map((r) => ({
                        uid: r.resourceId,
                        name: r.resourceName,
                    })),
                ),
            );
            const bookingIds = bookings.map((b) => b.uid);
            const bookingMetadata = JSON.parse(firstBooking.metadata);
            return {
                key: `${referenceId}-${startDt}-${endDt}`,
                startDt,
                endDt,
                categoryName: firstBooking.service.categoryName,
                centreName: firstBooking.tenant.name,
                resources: resources,
                confirmed: firstBooking.confirmed,
                isAllCancelled: bookings.every((b) => !!b.cancelled),
                rescheduledBooking:
                    bookingMetadata.workflowType ===
                    "CourtsiteRescheduleWorkflow",
                workflowId: bookingMetadata.workflowId as string | undefined,
                href: {
                    pathname: "/user/bookings",
                    query: {
                        bookingIds: bookingIds,
                    },
                },
            };
        }) ?? [];
    return (
        <HomePageSection
            title={t("upcomingBookingTitle", "Your upcoming bookings")}
            show={upcomingBookings.length > 0 && !loading}
        >
            <ScrollArea orientation="horizontal">
                <div className="grid min-w-0 auto-rows-[182px] grid-cols-[repeat(3,minmax(250px,1fr))] gap-4">
                    {upcomingBookings.map((b) => (
                        <HomepageUpcomingCard
                            {...b}
                            key={b.key}
                            href={b.href}
                            loading={loading}
                        />
                    ))}
                </div>
            </ScrollArea>
        </HomePageSection>
    );
};

const BookAgainSection = ({
    pastBookedCentres,
    loading,
    t,
}: {
    pastBookedCentres: Centre[];
    loading: boolean;
    t: TFunc;
}): JSX.Element => (
    <HomePageSection
        title={t("bookAgainTitle", "Why Not Book Again?")}
        show={pastBookedCentres.length > 0 && !loading}
    >
        <ScrollArea orientation="horizontal">
            <div className="grid min-w-0 flex-1 auto-rows-[228px] grid-cols-[repeat(5,minmax(214px,1fr))] gap-4">
                {pastBookedCentres.map((c) => (
                    <li
                        key={`bookAgain_${c.uid}`}
                        className="flex cursor-pointer flex-col gap-2 rounded-xl border-[0.5px] border-solid border-blue-grey-50 bg-white pb-4 transition-all lg:group-data-[filter=hide]:w-[332px] lg:group-data-[filter=show]:w-[320px]"
                    >
                        <div className="relative aspect-video w-full">
                            <Image
                                alt="Default centre image"
                                src={c.image || "/images/default_centre.jpg"}
                                layout="fill"
                                objectFit="cover"
                                unoptimized={!c.image}
                                className="rounded-t-xl"
                                sizes="(max-width: 992px) 230px, 300px"
                            />
                        </div>
                        <section className="mx-2 flex flex-1 flex-col justify-between gap-5">
                            <div>
                                <span className="typography-h4 line-clamp-1 font-bold text-blue-grey-900">
                                    {c.name}
                                </span>
                                <span className="typography-tiny line-clamp-1 text-blue-grey-500">
                                    {c.cityAndState}
                                </span>
                            </div>
                            <nav className="mt-auto">
                                <Link
                                    href={{
                                        pathname:
                                            "/centre/[orgName]/[orgID]/select",
                                        query: {
                                            orgID: c.uid,
                                            orgName: c.name,
                                            noInit: true,
                                        },
                                    }}
                                    passHref
                                >
                                    <Button
                                        variant="secondary"
                                        size="sm"
                                        className="w-full"
                                    >
                                        {t("common:book_now", "Book now")}
                                    </Button>
                                </Link>
                            </nav>
                        </section>
                    </li>
                ))}
            </div>
        </ScrollArea>
    </HomePageSection>
);

const HomePageSection = ({
    title,
    description,
    className,
    children,
    show,
}: HomePageSectionProps): JSX.Element => (
    <div
        data-show={show ? "true" : "false"}
        className={cx(
            "hidden flex-col gap-3 pt-2.5 data-[show=true]:flex lg:gap-6",
            className,
        )}
    >
        <div>
            <div className="typography-h4 lg:typography-h2 font-semibold capitalize text-blue-grey-900 lg:font-semibold">
                {title}
            </div>
            <div
                data-show={description ? "true" : "false"}
                className="typography-main mt-1 hidden text-blue-grey data-[show=false]:hidden lg:contents"
            >
                {description}
            </div>
        </div>
        {children}
    </div>
);

const PrismicStructure = ({
    homepageData,
    featureVenueData,
    categories,
    loading,
    lang,
    t,
}: {
    homepageData: HomePagePrismicData;
    featureVenueData: FeatureVenuesSlice;
    categories: PelangganHomePageQuery["categories"];
    loading: boolean;
    lang: string;
    t: TFunc;
}): JSX.Element => {
    const [isClient, setIsClient] = useState(false);
    useEffect(() => {
        // Forces re-render on client.
        // Otherwise, styles may not show up correctly because of Tailwind JIT.
        // This also ensures that `window` can be used.
        setIsClient(true);
    }, []);
    if (!isClient) return <></>;

    return (
        <>
            {homepageData.structure.map((st) => {
                const id = getText(st.id);
                const slice = homepageData.body.find(
                    (s) => getText(s.primary.id) === id,
                );
                if (slice?.slice_type === "deals")
                    return (
                        <DealsCarousel
                            key={`highlights_${id}`}
                            pageDoc={slice}
                            lang={lang}
                            t={t}
                        />
                    );
                else if (slice?.slice_type === "info_hub")
                    return (
                        <InfoHub
                            key={`info_hub_${id}`}
                            pageDoc={slice}
                            lang={lang}
                        />
                    );
                else if ("explore_more" === id)
                    return (
                        <FeatureVenueCarousel
                            key={`${id}-${loading}`}
                            featureVenue={featureVenueData}
                            categories={categories}
                            loading={loading}
                            t={t}
                        />
                    );
            })}
        </>
    );
};

const InfoHub = ({
    pageDoc,
    lang,
}: {
    pageDoc: InfoHubSlice;
    lang: string;
}): JSX.Element => {
    const titleEn = getText(pageDoc.primary.title_en);
    const title = {
        en: titleEn,
        my: defaultEn(titleEn, getText(pageDoc.primary.title_my)),
        zh: defaultEn(titleEn, getText(pageDoc.primary.title_zh)),
    };

    const items = pageDoc.items
        .map((i) => {
            const descEn = getText(i.description_en);
            const text = {
                en: descEn,
                my: defaultEn(descEn, getText(i.description_my)),
                zh: defaultEn(descEn, getText(i.description_zh)),
            };

            const link = new URL(i.link.url ?? "", window.location.origin);
            const isSameSite = link.host === window.location.host;
            return {
                text,
                imageUrl: i.img.url ?? "",
                link: i.link.url ?? "",
                isSameSite,
            };
        })
        .filter((o) => o.link !== "");

    return (
        <HomePageSection title={title[lang]} show={items.length > 0}>
            <ScrollArea orientation="horizontal">
                <div className="grid auto-cols-[120px] grid-flow-col grid-rows-1 gap-4 lg:auto-cols-[192px]">
                    {items.map((i) => (
                        <div key={i.imageUrl} className="flex flex-col gap-2">
                            <ImgLink {...i} />
                            <div
                                data-show={i.text[lang] ? "true" : "false"}
                                className="typography-sub lg:typography-main text-wrap text-blue-grey-600 data-[show=false]:hidden"
                            >
                                {i.text[lang]}
                            </div>
                        </div>
                    ))}
                </div>
            </ScrollArea>
        </HomePageSection>
    );
};

const ImgLink = ({
    imageUrl,
    link,
    isSameSite,
}: {
    imageUrl: string;
    link: string;
    isSameSite: boolean;
}): JSX.Element => {
    const child = (
        <div className="relative aspect-square size-[120px] cursor-pointer rounded-xl shadow-md lg:size-[192px]">
            <Image
                alt={link}
                src={imageUrl}
                layout="fill"
                objectFit="contain"
                className="rounded-xl"
                sizes="(max-width: 992px) 150px, 220px"
            />
        </div>
    );
    if (isSameSite) return <Link href={link}>{child}</Link>;
    return (
        <a href={link} target="_blank">
            {child}
        </a>
    );
};

const DealsCarousel = ({
    pageDoc,
    lang,
    t,
}: {
    pageDoc: DealsSlice;
    lang: string;
    t: TFunc;
}): JSX.Element => {
    const options: DealsCarouselProp[] = pageDoc.items
        .map((i) => {
            const titleEn = getText(i.title_en);
            const descEn = getText(i.description_en);
            return {
                title: {
                    en: titleEn,
                    my: defaultEn(titleEn, getText(i.title_my)),
                    zh: defaultEn(titleEn, getText(i.title_zh)),
                },
                description: {
                    en: descEn,
                    my: defaultEn(descEn, getText(i.description_my)),
                    zh: defaultEn(descEn, getText(i.description_zh)),
                },
                link: i.link.url ?? "",
                imageUrl: i.img.url ?? "",
            };
        })
        .filter((i) => i.imageUrl.trim() && i.link.trim());
    if (options.length < 1) return <></>;

    return (
        <HomePageSection
            title={t("highlightsTitle", "Highlights")}
            className="pb-2.5"
            show
        >
            <div className="flex justify-center">
                <Carousel
                    opts={{
                        align: "start",
                        loop: true,
                        watchDrag: false,
                    }}
                    plugins={[Autoplay({ delay: 3000 })]}
                    className="h-fit w-full flex-col gap-5"
                >
                    <CarouselContent className="ml-0 mr-0">
                        {options.map((i) => (
                            <CarouselItem key={i.imageUrl} className="pr-4">
                                <Link href={i.link}>
                                    <div>
                                        <div className="relative flex aspect-video w-[300px] cursor-pointer flex-col justify-between rounded-xl bg-cover bg-center p-4 shadow-md lg:w-[400px] lg:px-4 lg:py-5">
                                            <Image
                                                alt={i.title[lang]}
                                                src={i.imageUrl}
                                                layout="fill"
                                                objectFit="cover"
                                                className="rounded-xl"
                                                sizes="(max-width: 992px) 330px, 430px"
                                            />
                                        </div>
                                        <div className="typography-sub lg:typography-main mt-2 font-bold text-blue-grey-600 lg:font-bold">
                                            {i.title[lang]}
                                        </div>
                                        <div className="typography-tiny lg:typography-sub text-blue-grey-300">
                                            {i.description[lang]}
                                        </div>
                                    </div>
                                </Link>
                            </CarouselItem>
                        ))}
                    </CarouselContent>
                    <CarouselDots position="center" />
                </Carousel>
            </div>
        </HomePageSection>
    );
};

const FeatureVenueCarousel = ({
    featureVenue,
    categories,
    loading,
    t,
}: {
    featureVenue: FeatureVenuesSlice;
    categories: FragmentType<typeof featureVenueCategoriesFragment>[];
    loading?: boolean;
    t: TFunc;
}): JSX.Element => (
    <HomePageSection
        title={t("exploreMoreTitle", "Explore more")}
        show={!loading}
    >
        <FeatureVenues
            key={`featureVenue_${featureVenue.category.length}`}
            featureVenue={featureVenue}
            categories={categories}
            categoryLoading={loading}
        />
    </HomePageSection>
);

const SocialGames = ({
    filterGame,
    setFilterGame,
    publicGameCount,
    categories,
    publicGames,
    t,
}: SocialGamesProp): JSX.Element => {
    const categoryFragment = getFragment(
        socialGamesCategoriesFragment,
        categories,
    );
    const gameIds = publicGameCount.map((b) => b.categoryId);
    const gameCategories = categoryFragment.filter((c) =>
        gameIds.includes(c.uid),
    );

    const getCategoryCount = (categoryId: string): number => {
        const category = publicGameCount.find(
            (b) => b.categoryId === categoryId,
        );
        return category?.gameCount || 0;
    };
    const optionsCategory: FilterOptions<string>[] = [
        { label: "All", value: "all" },
        ...gameCategories
            .sort((a, b) => getCategoryCount(b.uid) - getCategoryCount(a.uid))
            .map((c) => ({ label: c.name, value: c.uid })),
    ];

    const publicGamesEdge = publicGames?.publicGames.edges || [];
    const publicGameNodes = publicGamesEdge.map((g) => g.node);
    const games = publicGameNodes.map((g) => {
        type PublicGameMetadaata = {
            details: string;
            playersOrTeams: string;
        };
        const metadata: PublicGameMetadaata = JSON.parse(g.metadata);
        const categoryName: string =
            categoryFragment.find((sc) => sc.uid === g.categoryId)?.name ?? "";

        const players = g.players?.map((p) => ({
            displayPicture: p.displayPicture ?? undefined,
        }));
        return {
            uid: g.uid,
            name: g.name,
            categoryId: g.categoryId,
            categoryName,
            location: g.location.tenant,
            startDt: dayjs(g.startDt).tz(),
            endDt: dayjs(g.endDt).tz(),
            organizerName: g.organizer.name,
            players: players || [],
            maxPlayers: g.maxPlayers,
            details: metadata.details,
            playersOrTeams: metadata.playersOrTeams || "player",
            reservedPlayers: g.reservedPlayers || 0,
        };
    });

    return (
        <HomePageSection
            title={t("socialGamesTitle", "Top social games")}
            description={t(
                "socialGamesDescription",
                "Playing sports is the best way to socialize. Go for our social games to make friends or pick up a new hobby!",
            )}
            className="pb-2.5"
            show
        >
            <div className="flex flex-col gap-6">
                <FilterRadioGroup
                    scrollable
                    options={optionsCategory}
                    selected={filterGame}
                    onChange={(v) => setFilterGame(v)}
                    className="max-w-[1056px]"
                />
                <div className="grid min-w-0 auto-rows-[342px] grid-cols-[repeat(3,minmax(303.5px,1fr))_77px] gap-3">
                    {games.map((game) => (
                        <GameCard
                            key={game.uid}
                            game={game}
                            showFilter={false}
                        />
                    ))}
                    <Link href="explore/games">
                        <a className="remove-styles-a flex cursor-pointer flex-col items-end justify-center gap-2">
                            <div className="typography-h5 text-wrap font-semibold text-blue-grey-600">
                                {t("moreGames", "More games")}
                            </div>
                            <div className="flex size-6 items-center justify-center rounded-full bg-blue-grey-50">
                                <ArrowRightUnfilled className="size-3 text-blue-grey-600" />
                            </div>
                        </a>
                    </Link>
                </div>
            </div>
        </HomePageSection>
    );
};

const getText = (richText: RichTextBlock[]): string =>
    RichText.asText(richText);
const defaultEn = (en: string, lng?: string): string =>
    !!lng && lng.trim() !== "" ? lng : en;

export const getStaticProps: GetStaticProps<HomePageProps> = async () => ({
    props: {
        pageDoc: {
            homePageDoc: await getHomePageDoc(),
            featureVenueDoc: await getFeatureVenueDoc(),
            categoryList: await getCategoryListDoc(),
        },
    },
});
