import { useState } from "react";
import Link from "next/link";
import { Image } from "@/lib/imgproxy";
import { RichText, RichTextBlock } from "prismic-reactjs";
import { FilterIconsGroup } from "@/components/SearchPage/FilterWithIcons";
import { FilterRadioGroup } from "@/components/Filter";
import { ArrowRightUnfilled, EyeUnfilled } from "@/components/Icons";
import { Button } from "@/components/Button";
import { FragmentType, getFragment, graphql } from "@/lib/gql";
import { useTranslation } from "react-i18next";
import { TFunction } from "i18next";
import { useQuery } from "@apollo/client";
import { getCentreImage } from "@/utils/centre";
import { cx } from "class-variance-authority";
import { Empty } from "./Empty";
import { ScrollArea } from "./ScrollArea";

export const featureVenueCategoriesFragment = graphql(`
    fragment FeatureVenueCategories on Category {
        uid
        name
    }
`);

export type FeatureVenuesSlice = {
    category: {
        title_en: RichTextBlock[];
        title_my: RichTextBlock[];
        title_zh: RichTextBlock[];
    }[];
};

const centreQuery = graphql(`
    query featureVenueOrganisations(
        $filter: ApprovedOrganisationsFilter
        $cursor: String
    ) {
        approvedOrganisations(
            sortOrder: BY_WEIGHTAGE_DESC
            limit: 6
            after: $cursor
            filter: $filter
        ) {
            edges {
                node {
                    uid
                    name
                    metadata
                    services
                }
            }
        }
    }
`);
type TFunc = TFunction<["components/FeatureVenues", "common"]>;
export type FeatureVenuesProp = {
    categoryId: string;
    categoryName: { en: string; my: string; zh: string };
};
export const FeatureVenues = ({
    featureVenue,
    categories,
    categoryLoading,
}: {
    featureVenue: FeatureVenuesSlice;
    categories: FragmentType<typeof featureVenueCategoriesFragment>[];
    categoryLoading?: boolean;
}): JSX.Element => {
    const { i18n, t } = useTranslation(["components/FeatureVenues", "common"]);
    const lang = i18n.language || "en";
    const [categoryFilter, setCategoryFilter] = useState<string>();
    const categoryFragment = getFragment(
        featureVenueCategoriesFragment,
        categories,
    );
    const { data, loading: centreLoading } = useQuery(centreQuery, {
        skip: !categoryFilter,
        fetchPolicy: "cache-and-network",
        notifyOnNetworkStatusChange: true,
        variables: {
            filter: { byServices: [categoryFilter ?? ""] },
            cursor: null,
        },
    });

    const centresData = data?.approvedOrganisations?.edges.map((c) => ({
        ...c.node,
        categoryNames: categoryFragment
            .filter((cat) => c.node.services.includes(cat.uid))
            .map((cat) => cat.name)
            .join(", "),
    }));

    const options: FeatureVenuesProp[] = featureVenue.category.map((doc) => {
        const categoryName = getText(doc.title_en);
        const categoryId = categoryFragment.find(
            (c) => c.name.toLowerCase() === categoryName.toLowerCase(),
        )?.uid;
        return {
            categoryId: categoryId ?? categoryName.toLowerCase(),
            categoryName: {
                en: categoryName,
                my: defaultEn(categoryName, getText(doc.title_my)),
                zh: defaultEn(categoryName, getText(doc.title_zh)),
            },
        };
    });

    if (options[0]?.categoryId && !categoryFilter)
        setCategoryFilter(options[0].categoryId);

    const filterCategoriesOptions = options.map((o) => ({
        icon: o.categoryName.en,
        label: o.categoryName[lang],
        value: o.categoryId,
    }));

    const filterCategory = filterCategoriesOptions.find(
        (option) => option.value === categoryFilter,
    );

    const filterCategoryName = filterCategory?.icon;
    const loading = categoryLoading || centreLoading;
    const centreLength = centresData?.length ?? 0;
    const showEmpty = centreLength < 1 && !loading;
    const showVenues = centreLength === 6 && !loading;
    const isOther = categoryFilter === "other" && !loading;

    if (featureVenue.category.length < 1 || categoryFragment.length < 1)
        return <></>;
    return (
        <div>
            <div className="flex flex-col gap-5 lg:hidden">
                <FilterRadioGroup
                    scrollable
                    options={filterCategoriesOptions}
                    selected={categoryFilter}
                    onChange={(v) => setCategoryFilter(v)}
                />
                <ScrollArea orientation="horizontal">
                    <div className="grid min-w-0 auto-rows-[281px] grid-cols-[repeat(6,minmax(240px,1fr))_1fr] gap-4">
                        {loading && <CentreCardSkeleton />}
                        {!loading &&
                            centresData?.map((c) => (
                                <CentreCard
                                    key={`mobile_${categoryFilter}${c.uid}`}
                                    {...c}
                                    t={t}
                                    categoryId={categoryFilter}
                                />
                            ))}
                        <div
                            key={`seemoreMobile_${centreLength}`}
                            data-show={showVenues ? "true" : "false"}
                            className="hidden items-center data-[show=true]:flex"
                        >
                            <Link
                                href={{
                                    pathname:
                                        "/explore/category/[categoryName]/[categoryId]",
                                    query: {
                                        categoryId: categoryFilter,
                                        categoryName: filterCategoryName,
                                    },
                                }}
                            >
                                <span className="flex size-12 items-center justify-center rounded-full bg-blue-grey-50 p-1.5">
                                    <ArrowRightUnfilled className="size-5 stroke-blue-grey-900" />
                                </span>
                            </Link>
                        </div>
                    </div>
                </ScrollArea>
                <EmptyCategory show={!isOther && showEmpty} t={t} />
                <OtherCategory show={isOther} t={t} />
            </div>
            <div className="hidden w-full flex-col gap-10 lg:flex">
                <FilterIconsGroup
                    scrollable={true}
                    options={filterCategoriesOptions}
                    selected={categoryFilter}
                    onChange={(v) => setCategoryFilter(v)}
                />
                <div className="grid auto-cols-[332px] grid-cols-3 gap-x-3 gap-y-5">
                    {loading && (
                        <>
                            <CentreCardSkeleton />
                            <CentreCardSkeleton />
                            <CentreCardSkeleton />
                            <CentreCardSkeleton />
                            <CentreCardSkeleton />
                            <CentreCardSkeleton />
                        </>
                    )}
                    {!loading &&
                        centresData?.map((c) => (
                            <CentreCard
                                key={`desktop_${categoryFilter}${c.uid}`}
                                {...c}
                                t={t}
                                categoryId={categoryFilter}
                            />
                        ))}
                </div>
                <EmptyCategory show={!isOther && showEmpty} t={t} />
                <OtherCategory show={isOther} t={t} />
                <div
                    key={`seemoreDesktop_${centreLength}`}
                    data-show={showVenues ? "true" : "false"}
                    className="hidden justify-center data-[show=true]:flex"
                >
                    <Link
                        href={{
                            pathname:
                                "/explore/category/[categoryName]/[categoryId]",
                            query: {
                                categoryId: categoryFilter,
                                categoryName: filterCategoryName,
                            },
                        }}
                        passHref
                    >
                        <Button
                            variant="tertiary"
                            className="rounded-3xl px-5"
                            asChild
                        >
                            <a className="remove-styles-a">
                                {t("seeMoreVenues", "See more venues")}
                            </a>
                        </Button>
                    </Link>
                </div>
            </div>
        </div>
    );
};

const getText = (richText: RichTextBlock[]): string =>
    RichText.asText(richText);

const defaultEn = (en: string, lng?: string): string =>
    !!lng && lng.trim() !== "" ? lng : en;

const EmptyCategory = ({
    show,
    t,
}: {
    show: boolean;
    t: TFunc;
}): JSX.Element => (
    <>
        {show && (
            <Empty
                title={t("emptyTitle", "No venues available")}
                description={t(
                    "emptyDescription",
                    "Currently, no venues offer this sport. Explore other sports activities available on Courtsite!",
                )}
                icon={
                    <Image
                        alt="equipment_icon"
                        src="/images/no_results.png"
                        unoptimized
                        width="70px"
                        height="70px"
                    />
                }
            />
        )}
    </>
);

const OtherCategory = ({
    show,
    t,
}: {
    show: boolean;
    t: TFunc;
}): JSX.Element => (
    <>
        {show && (
            <Empty
                title={t("otherTitle", "Can't Find Your Sport?")}
                description={t(
                    "otherDescription",
                    "No worries! We have plenty of other sports available. Click below to explore more.",
                )}
                link="/explore/category"
                ctaText={t("otherButton", "Explore more sports")}
                icon={
                    <Image
                        alt="equipment_icon"
                        src="/images/homeIcons/play.jpg"
                        unoptimized
                        width="70px"
                        height="70px"
                    />
                }
            />
        )}
    </>
);

type CentreCardProps = {
    uid: string;
    name: string;
    categoryId?: string;
    categoryNames?: string;
    metadata: string;
    t: TFunc;
};

const CentreCard = (centre: CentreCardProps): JSX.Element => {
    const query = {
        orgName: centre.name,
        orgID: centre.uid,
        categoryId: centre.categoryId,
    };
    const pathname = "/centre/[orgName]/[orgID]";
    const image = getCentreImage(centre.metadata, centre.categoryId);
    const cityState = JSON.parse(centre.metadata).cityAndState;
    return (
        <Link href={{ pathname, query }} passHref>
            <li className="flex cursor-pointer flex-col gap-2 rounded-xl border-[0.5px] border-solid border-blue-grey-50 bg-white pb-4 lg:w-[332px]">
                <div className="relative aspect-video w-full">
                    <Image
                        alt={`${centre.name} cover image`}
                        src={image || "/images/default_centre.jpg"}
                        layout="fill"
                        objectFit="cover"
                        unoptimized={!image}
                        className="rounded-t-xl"
                        sizes="(max-width: 992px) 250px, 400px"
                    />
                </div>
                <section className="mx-2 flex flex-1 flex-col justify-between gap-5 lg:mx-4">
                    <div>
                        <p className="typography-micro line-clamp-1 uppercase text-blue-grey">
                            {centre.categoryNames}
                        </p>
                        <h4 className="typography-h4 line-clamp-1 font-semibold text-blue-grey-900">
                            {centre.name}
                        </h4>
                        <p className="typography-tiny line-clamp-2 text-blue-grey-500">
                            {cityState}
                        </p>
                    </div>
                    <nav className="hidden grid-cols-2 gap-2 lg:grid">
                        <Link href={{ pathname, query }} passHref>
                            <Button variant="tertiary" asChild>
                                <a className="flex gap-2 no-underline hover:no-underline">
                                    <EyeUnfilled />
                                    {centre.t("common:buttonView", "View")}
                                </a>
                            </Button>
                        </Link>
                        <Link
                            href={{ pathname: `${pathname}/select`, query }}
                            passHref
                        >
                            <Button variant="secondary" asChild>
                                <a className="no-underline hover:no-underline">
                                    {centre.t(
                                        "common:buttonBookNow",
                                        "Book Now",
                                    )}
                                </a>
                            </Button>
                        </Link>
                    </nav>
                    <nav className="grid grid-cols-2 gap-2 lg:hidden">
                        <Link href={{ pathname, query }} passHref>
                            <Button variant="tertiary" size="sm" asChild>
                                <a className="flex gap-2 no-underline hover:no-underline">
                                    <EyeUnfilled />
                                    {centre.t("common:buttonView", "View")}
                                </a>
                            </Button>
                        </Link>
                        <Link
                            href={{ pathname: `${pathname}/select`, query }}
                            passHref
                        >
                            <Button variant="secondary" size="sm" asChild>
                                <a className="no-underline hover:no-underline">
                                    {centre.t(
                                        "common:buttonBookNow",
                                        "Book Now",
                                    )}
                                </a>
                            </Button>
                        </Link>
                    </nav>
                </section>
            </li>
        </Link>
    );
};

const CentreCardSkeleton = ({
    className,
}: {
    className?: string;
}): JSX.Element => (
    <li
        className={cx(
            "flex animate-pulse flex-col justify-between gap-4 rounded-lg border-[0.5px] border-solid border-blue-grey-100 bg-white p-4",
            className,
        )}
    >
        <div className="flex flex-col gap-2">
            <div className="h-5 rounded bg-blue-grey-50" />
            <div className="h-5 rounded bg-blue-grey-50" />
        </div>
        <div className="flex flex-col gap-2">
            <div className="h-5 rounded bg-blue-grey-50" />
            <div className="h-5 rounded bg-blue-grey-50" />
            <div className="h-5 rounded bg-blue-grey-50" />
        </div>
        <div className="flex flex-col gap-2">
            <div className="h-5 rounded bg-blue-grey-50" />
        </div>
    </li>
);
