import type { SVGProps } from "./types";

export const SearchUnfilled = (props: SVGProps): JSX.Element => (
    <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M17 17L12.3333 12.3333M13.8889 8.44444C13.8889 11.4513 11.4513 13.8889 8.44444 13.8889C5.43756 13.8889 3 11.4513 3 8.44444C3 5.43756 5.43756 3 8.44444 3C11.4513 3 13.8889 5.43756 13.8889 8.44444Z"
            stroke="currentColor"
            strokeWidth={1.67}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

export const SearchFilled = (props: SVGProps): JSX.Element => (
    <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8.25 4.75C6.317 4.75 4.75 6.317 4.75 8.25C4.75 10.183 6.317 11.75 8.25 11.75C10.183 11.75 11.75 10.183 11.75 8.25C11.75 6.317 10.183 4.75 8.25 4.75ZM3 8.25C3 5.35051 5.35051 3 8.25 3C11.1495 3 13.5 5.35051 13.5 8.25C13.5 9.38385 13.1406 10.4338 12.5294 11.292L16.7437 15.5063C17.0854 15.848 17.0854 16.402 16.7437 16.7437C16.402 17.0854 15.848 17.0854 15.5063 16.7437L11.292 12.5294C10.4338 13.1406 9.38385 13.5 8.25 13.5C5.35051 13.5 3 11.1495 3 8.25Z"
            fill="currentColor"
        />
    </svg>
);
