import { useTranslation } from "react-i18next";
import Link from "next/link";

import { Button } from "@/components/Button";
import { Skeleton } from "@/components/Skeleton";
import { Badge, type BadgeProps } from "@/components/Badge";

import dayjs from "@/lib/dayjs";
import { graphql } from "@/lib/gql";
import { useQuery } from "@apollo/client";

const reattemptBookingCardQuery = graphql(`
    query reattemptBookingCard($tenantId: ID!) {
        organisation(uid: $tenantId) {
            uid
            name
        }
    }
`);

type BookingStatus =
    | "CANCELLED"
    | "PAST"
    | "ONGOING"
    | "UNCONFIRMED"
    | "FUTURE";
type BookingBadge = { color: BadgeProps["color"]; label: string };

type Booking = {
    tenantId: string;
    categoryName?: string;
    start: string;
    end: string;
    cancelled?: string | null;
    confirmed?: string | null;
    courtNames: string;
    isDailyService: boolean;
};
type ReattemptBookingCardProps = {
    booking: Booking;
    href?: string;
};
const ReattemptBookingCard = ({
    booking,
    href,
}: ReattemptBookingCardProps): JSX.Element => {
    const { data, loading } = useQuery(reattemptBookingCardQuery, {
        variables: { tenantId: booking.tenantId },
    });
    const { t, ready } = useTranslation(["components/BookingCard", "common"]);

    const tagContent: Record<BookingStatus, BookingBadge> = {
        CANCELLED: {
            color: "red",
            label: t("common:tagsv2_cancelled", "CANCELLED"),
        },
        PAST: {
            color: "light",
            label: t("tags.past", {
                defaultValue: "PAST BOOKING",
            }),
        },
        ONGOING: {
            color: "success",
            label: t("tags.ongoing", {
                defaultValue: "ACTIVE BOOKING",
            }),
        },
        FUTURE: {
            color: "dark",
            label: t("tags.future", {
                defaultValue: "UPCOMING BOOKING",
            }),
        },
        UNCONFIRMED: {
            color: "red",
            label: t("tags.unconfirmed", {
                defaultValue: "PENDING PAYMENT",
            }),
        },
    };

    if (loading || !data || !ready) {
        return (
            <div className="relative flex w-full flex-col gap-1 rounded-md border border-blue-grey-50 bg-white p-3 shadow-md">
                <div className="absolute end-0 top-0 flex size-5 -translate-y-2 translate-x-2 items-center justify-center rounded-full bg-destructive text-white ring-1 ring-white">
                    <span className="typography-tiny font-bold">!</span>
                </div>
                <Skeleton paragraph={{ rows: 2 }} />
            </div>
        );
    }

    const now = dayjs().unix();
    const start = dayjs(booking.start).tz();
    const end = dayjs(booking.end).tz();

    const bookingStatus = getBookingStatus(booking, end, now, start);

    let startFmt = "DD/M/YY hh:mma";
    let endFmt = "hh:mma";
    if (booking.isDailyService) {
        startFmt = "DD/MM/YY";
        endFmt = "DD/MM/YY";
    }
    const timeTxt = `${start.format(startFmt)} - ${end.format(endFmt)}`;
    const { color, label } = tagContent[bookingStatus];

    return (
        <div className="relative flex w-full flex-col gap-1 rounded-md bg-white p-3 shadow-md">
            <div className="absolute end-0 top-0 flex size-5 -translate-y-2 translate-x-2 items-center justify-center rounded-full bg-destructive text-white ring-1 ring-white">
                <span className="typography-tiny font-bold">!</span>
            </div>
            <Badge className="w-fit" color={color}>
                <span className="typography-tiny">{label}</span>
            </Badge>
            <span className="typography-tiny font-bold uppercase text-primary-600">
                {booking.categoryName}
            </span>
            <span className="font-bold text-blue-grey-900">
                {data.organisation.name}
            </span>
            <span className="typography-sub text-blue-grey-900">
                {timeTxt}, {booking.courtNames}
            </span>
            {href && (
                <Link href={href} passHref>
                    <Button
                        className="w-full"
                        data-attr="continue-checkout-payment"
                    >
                        {t("proceed_payment", "Proceed to Payment")}
                    </Button>
                </Link>
            )}
        </div>
    );
};

export default ReattemptBookingCard;

function getBookingStatus(
    booking: Booking,
    end: dayjs.Dayjs,
    now: number,
    start: dayjs.Dayjs,
): "CANCELLED" | "PAST" | "ONGOING" | "UNCONFIRMED" | "FUTURE" {
    if (booking.cancelled) return "CANCELLED";
    if (!booking.confirmed) return "UNCONFIRMED";
    if (end.unix() < now) return "PAST";
    if (start.unix() < now) return "ONGOING";
    return "FUTURE";
}
