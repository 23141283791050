import {
    ApolloClient,
    HttpLink,
    ApolloLink,
    Observable,
    InMemoryCache,
    defaultDataIdFromObject,
} from "@apollo/client";
import { createPersistedQueryLink } from "apollo-link-persisted-queries";
import firebase from "@/lib/firebase/client";
import { CachePersistor, LocalStorageWrapper } from "apollo3-cache-persist";
import { relayStylePagination } from "@apollo/client/utilities";

export const cache = new InMemoryCache({
    // Apollo looks for `id`, and `_id` in responses when creating its normalised cache.
    // However, We use `uid` in most cases instead of `id` resulting in no caching.
    // This overrides the default behaviour for getting the cache key,
    // and fallsback to Apollo's behaviour if there is no `__typename` or `uid`.
    // Technically, this is deprecated in favour of `keyFields`, but there is
    // no other way of setting the default behaviour for all types.
    dataIdFromObject(res) {
        if (res?.__typename && res?.uid) {
            return `${res.__typename}:${JSON.stringify(res.uid)}`;
        }
        return defaultDataIdFromObject(res);
    },
    typePolicies: {
        Query: {
            fields: {
                myBookings: relayStylePagination(["filter"]),
                myGames: relayStylePagination(["filter", "order"]),
                publicGames: relayStylePagination(["filter"]),
                approvedOrganisations: relayStylePagination(["filter"]),
                publicCompetitions: relayStylePagination(["filter"]),
                myInvoicesPaged: relayStylePagination(["filter"]),
                myGroupedBookings: relayStylePagination(["filter"]),
            },
        },
        UserProfile: { keyFields: ["firebaseId"] },
    },
});

export const persistor =
    typeof window !== "undefined" && !!window.localStorage
        ? new CachePersistor({
              cache,
              storage: new LocalStorageWrapper(window.localStorage),
          })
        : undefined;

export const createClient = (cache) => {
    const apqLink = createPersistedQueryLink();

    const httpLink = new HttpLink({
        uri: process.env.NEXT_PUBLIC_ENJIN_PELANGGAN_PROKSI_API_URL,
    });

    const request = async (operation) => {
        const { currentUser } = firebase.auth();
        if (currentUser) {
            const token = await currentUser.getIdToken();
            operation.setContext({
                headers: {
                    Authorization: `Firebase ${token}`,
                },
            });
        }
    };

    const requestHandler = new ApolloLink(
        (operation, forward) =>
            new Observable((observer) => {
                let handle;
                // eslint-disable-next-line
                Promise.resolve(operation)
                    .then((oper) => request(oper))
                    .then(() => {
                        handle = forward(operation).subscribe({
                            next: observer.next.bind(observer),
                            error: observer.error.bind(observer),
                            complete: observer.complete.bind(observer),
                        });
                    })
                    .catch(observer.error.bind(observer));

                return () => {
                    // eslint-disable-next-line
                    if (handle) {
                        // eslint-disable-next-line
                        handle.unsubscribe;
                    }
                };
            }),
    );

    const link = ApolloLink.from([requestHandler, apqLink, httpLink]);

    const client = new ApolloClient({
        cache: cache,
        link: link,
    });

    return client;
};
