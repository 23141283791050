import type { SVGProps } from "./types";

export const SmViewGridAddUnfilled = (props: SVGProps): JSX.Element => (
    <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M14.375 11.75V17M11.75 14.375H17M4.75 8.25H6.5C7.4665 8.25 8.25 7.4665 8.25 6.5V4.75C8.25 3.7835 7.4665 3 6.5 3H4.75C3.7835 3 3 3.7835 3 4.75V6.5C3 7.4665 3.7835 8.25 4.75 8.25ZM13.5 8.25H15.25C16.2165 8.25 17 7.4665 17 6.5V4.75C17 3.7835 16.2165 3 15.25 3H13.5C12.5335 3 11.75 3.7835 11.75 4.75V6.5C11.75 7.4665 12.5335 8.25 13.5 8.25ZM4.75 17H6.5C7.4665 17 8.25 16.2165 8.25 15.25V13.5C8.25 12.5335 7.4665 11.75 6.5 11.75H4.75C3.7835 11.75 3 12.5335 3 13.5V15.25C3 16.2165 3.7835 17 4.75 17Z"
            stroke="currentColor"
            strokeWidth={1.67}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

export const SmViewGridAddFilled = (props: SVGProps): JSX.Element => (
    <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M5 3C3.89543 3 3 3.89543 3 5V7C3 8.10457 3.89543 9 5 9H7C8.10457 9 9 8.10457 9 7V5C9 3.89543 8.10457 3 7 3H5Z"
            fill="currentColor"
        />
        <path
            d="M5 11C3.89543 11 3 11.8954 3 13V15C3 16.1046 3.89543 17 5 17H7C8.10457 17 9 16.1046 9 15V13C9 11.8954 8.10457 11 7 11H5Z"
            fill="currentColor"
        />
        <path
            d="M11 5C11 3.89543 11.8954 3 13 3H15C16.1046 3 17 3.89543 17 5V7C17 8.10457 16.1046 9 15 9H13C11.8954 9 11 8.10457 11 7V5Z"
            fill="currentColor"
        />
        <path
            d="M14 11C14.5523 11 15 11.4477 15 12V13H16C16.5523 13 17 13.4477 17 14C17 14.5523 16.5523 15 16 15H15V16C15 16.5523 14.5523 17 14 17C13.4477 17 13 16.5523 13 16V15H12C11.4477 15 11 14.5523 11 14C11 13.4477 11.4477 13 12 13H13V12C13 11.4477 13.4477 11 14 11Z"
            fill="currentColor"
        />
    </svg>
);
