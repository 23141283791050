import type { SVGProps } from "./types";
import { cx } from "class-variance-authority";

export const CheveronLeftUnfilled = (props: SVGProps): JSX.Element => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        fill="none"
        viewBox="0 0 20 20"
        {...props}
    >
        <path
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.67"
            d="m12 15-5-5 5-5"
        />
    </svg>
);

export const CheveronRightUnfilled = (props: SVGProps): JSX.Element => (
    <CheveronLeftUnfilled
        {...props}
        className={cx("rotate-180", props.className)}
    />
);
export const CheveronUpUnfilled = (props: SVGProps): JSX.Element => (
    <CheveronLeftUnfilled
        {...props}
        className={cx("rotate-90", props.className)}
    />
);
export const CheveronDownUnfilled = (props: SVGProps): JSX.Element => (
    <CheveronLeftUnfilled
        {...props}
        className={cx("-rotate-90", props.className)}
    />
);
