import { cx } from "class-variance-authority";
import { ReactNode } from "react";
import {
    Carousel,
    CarouselContent,
    CarouselItem,
    CarouselNext,
    CarouselPrevious,
} from "@/components/Carousel";

export type FilterProps<T> = FilterOptions<T> & {
    selected: T;
    loading?: boolean;
    onChange: (f: T) => void;
};

export type FilterOptions<T> = {
    label: ReactNode;
    value: T;
};

export type FilterRadioGroupProps<T> = {
    options: FilterOptions<T>[];
    selected: string;
    loading?: boolean;
    onChange: (f: T) => void;
};

export const FilterRadio = <T,>({
    label,
    value,
    ...props
}: FilterProps<T>): JSX.Element => (
    <button
        type="button"
        className={cx(
            "flex h-7 w-max cursor-pointer items-center rounded-lg border border-solid px-3 py-1 transition-all",
            !props.loading && value === props.selected
                ? "border-primary bg-primary-50 text-primary-700"
                : "border-transparent bg-blue-grey-50 text-blue-grey-500",
            props.loading
                ? "animate-pulse cursor-not-allowed text-transparent"
                : "cursor-pointer",
        )}
        onClick={() => !props.loading && props.onChange(value)}
    >
        {label}
    </button>
);

export const FilterRadioGroup = <T,>({
    scrollable,
    options,
    selected,
    loading = false,
    onChange,
    className,
}: {
    scrollable?: boolean;
    options: FilterOptions<T>[];
    selected: T;
    loading?: boolean;
    onChange: (f: T) => void;
    className?: string;
}): JSX.Element => {
    if (!scrollable)
        return (
            <div className="flex h-7 gap-2">
                {options.map((prop, i) => (
                    <FilterRadio
                        key={i}
                        label={prop.label}
                        value={prop.value}
                        selected={selected}
                        loading={loading}
                        onChange={onChange}
                    />
                ))}
            </div>
        );
    return (
        <div
            className={cx(
                "flex flex-col items-center",
                className,
                !className && "max-w-[720px]",
            )}
        >
            <Carousel
                opts={{
                    align: "start",
                    dragFree: true,
                }}
                className="h-7 w-[calc(100%-80px)] lg:w-[calc(100%-100px)]"
            >
                <CarouselContent>
                    {options.map((prop, i) => (
                        <CarouselItem key={i}>
                            <FilterRadio
                                key={i}
                                label={prop.label}
                                value={prop.value}
                                selected={selected}
                                loading={loading}
                                onChange={onChange}
                            />
                        </CarouselItem>
                    ))}
                </CarouselContent>
                <CarouselPrevious />
                <CarouselNext />
            </Carousel>
        </div>
    );
};
