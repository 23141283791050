import type { SVGProps } from "./types";

export const LocationUnfilled = (props: SVGProps): JSX.Element => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        fill="none"
        viewBox="0 0 20 20"
        {...props}
    >
        <path
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.67"
            d="m14.1 12.9-2.686 2.686a1.999 1.999 0 0 1-2.828 0L5.9 12.9a5.799 5.799 0 1 1 8.2 0Z"
        />
        <path
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.67"
            d="M12.175 8.799a2.175 2.175 0 1 1-4.35 0 2.175 2.175 0 0 1 4.35 0Z"
        />
    </svg>
);
